<template>
  <a
    v-if="to"
    :href="`/#${to}`"
    :class="classes"
    @click.exact="handleClickItem($event, false)"
    @click.ctrl="handleClickItem($event, true)"
    @click.meta="handleClickItem($event, true)"
    :style="itemStyle"
  >
    <slot></slot>
  </a>
  <li v-else :class="classes" @click.stop="handleClickItem" :style="itemStyle">
    <slot></slot>
  </li>
</template>
<script>
const prefixCls = "ivu-menu";
import eventHub from "../../../utils/event";

export default {
  name: "lu-menu-item",
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    classes() {
      return [
        `${prefixCls}-item`,
        {
          [`${prefixCls}-item-active`]: this.active,
          [`${prefixCls}-item-selected`]: this.active,
          [`${prefixCls}-item-disabled`]: this.disabled,
        },
      ];
    },
    itemStyle() {
      return this.hasParentSubmenu && this.mode !== "horizontal"
        ? {
            paddingLeft: 43 + (this.parentSubmenuNum - 1) * 24 + "px",
          }
        : {};
    },
  },
  methods: {
    handleClickItem() {
      if (this.disabled) return;
    },
  },
  mounted() {
    eventHub.$on("on-update-active-name", (name) => {
      if (this.name === name) {
        this.active = true;
      } else {
        this.active = false;
      }
    });
  },
};
</script>
