<template>
  <div>
    <el-collapse v-model="activeNames" class="elementary">
      <el-collapse-item
        v-for="(item, index) in basics.data"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <p class="eleHead">
            <span class="hdate">{{ item.title + item.time }}</span>
          </p>
        </template>
        <div class="elementaryCon">
          <template>
            <el-table :data="item.nutrientName" style="width: 100%">
              <el-table-column
                prop="nutrientName"
                label="名称"
              ></el-table-column>
              <el-table-column
                prop="humanClassifyName"
                label="针对领域"
              ></el-table-column>
            </el-table>
          </template>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      activeNames: 0,
      territory: [],
      basics: "",
      userId:"",
    };
  },
  created() {
    this.userId = this.$route.query.userId;
    // this.territorys();
    this.compocsition();
  },
  methods: {
    // territorys() {
    //   //领域
    //   var that = this;
    //   api.territory().then((response) => {
    //     if (response.data.code == 0) {
    //       that.territory = response.data.data;
    //     }
    //   });
    // },
    // stateterritory(row) {
    //   //领域
    //   var territory = [];
    //   var that = this;
    //   row.serviceIds.forEach((itme) => {
    //     that.territory.forEach((ele) => {
    //       if (itme == ele.id) {
    //         territory.push(ele.name);
    //       }
    //     });
    //   });
    //   return territory.join("；");
    // },
    compocsition() {
      var that = this;
      var parameter = {
        userId: that.userId,
      };
      api.jkdanutrient(parameter).then((response) => {
        if (response.data.code == 0) {
          that.basics = response.data.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .eleHead {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
    font-weight: bold;
    .hdate {
      color: #185ad6;
    }
    .hunfold {
      float: right;
    }
  }
  .elementaryCon {
    display: flex;
  }
}
</style>
