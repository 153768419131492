<template>
  <div>
    <el-collapse v-model="activeNames">
      <template v-for="(item, index) in basics">
        <el-collapse-item
          v-if="item.formal == 1"
          class="elementary"
          :title="'评测时间：' + item.createTime"
          :name="index"
          :key="index"
        >
          <div class="demo-image__preview">
            <el-image
              style="width: 100px; height: 100px"
              :src="item.url"
              :preview-src-list="item.url.split(',')"
            >
            </el-image>
          </div>
        </el-collapse-item>
        <p
          class="elementary eleHead"
          v-if="item.formal == 2"
          @click="open(item.url)"
          :key="index"
        >
          <span class="hdate">评测时间：{{ item.createTime }}</span>
          <span class="bgmc">体检机构：{{ item.orgName }}</span>
          <span class="bgmc cP">{{ item.fileName }}</span>
        </p>
      </template>
    </el-collapse>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNames: 0,
      basics: [],
      userId:""
    };
  },
  created() {
    var that=this;
    that.userId = that.$route.query.userId;
    console.log('$route',that.userId);
    if(that.userId)
    {
      that.compocsition();
    }
    else{
      that.basics=[];
    }
    
  },
  methods: {
    open(itme) {
      window.open(itme);
    },
    compocsition() {
      var that = this;
      var parameter = {
        userId: that.userId,
        type: 2,
      };
      that.api.jkdamedical(parameter).then((response) => {
        if (response.data.code == 0) {
          that.basics = response.data.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 0 20px;
}
.eleHead {
  height: 56px;
  line-height: 56px;
  font-weight: bold;
  .hdate {
    color: #185ad6;
  }
  .hunfold {
    float: right;
  }
  .jgmc,
  .bgmc {
    margin-left: 40px;
    color: rgba(0, 0, 0, 0.85);
  }
}
img {
  width: 170px;
  height: 240px;
  margin-right: 20px;
}
</style>
