<template>
  <div class="zsfa">
    <el-collapse v-model="activeNames" class="elementary">
      <el-collapse-item :name="0">
        <template slot="title">
          <p class="eleHead">
            <span class="hdate">{{ basics.title }}</span>
          </p>
        </template>
        <ul
          class="conbox"
          v-for="(item, index) in basics.object"
          :key="index"
          :name="index"
        >
          <li class="conli" v-if="item.title == '营养膳食'">
            <h3>
              {{ item.title }} {{ item.object.kcal }}Kcal
              <Button
                type="primary"
                v-if="roleType == '健康管理师'||roleType == '系统管理员'"
                style="float: right"
                @click="revamp = true"
                >修改</Button
              >
            </h3>
            <ul class="mb20">
              <li style="height:30px;line-height:30px;padding-top:10px;">碳水化合物：{{ item.object.carbohydrateRate }}</li>
              <li style="height:30px;line-height:30px;padding-top:10px;">蛋白质：{{ item.object.highProteinRate }}</li>
              <li style="height:30px;line-height:30px;padding-top:10px;">脂肪： {{ item.object.fatRate }}</li>
            </ul>
            <div
              class="foods"
              v-for="(citem, index) in item.foods"
              :key="index"
            >
              <p>{{ citem.name }}</p>
              <span v-for="(ditem, index) in citem.detail" :key="index">{{
                ditem.name + ":" + ditem.weight
              }}</span>
            </div>
            <h3>饮食建议</h3>
            <div class="ysjy" v-html="item.object.suggest"></div>
          </li>
          <li class="conli" v-if="item.title == '运动康复'">
            <h3>
            运动康复
            <Button
                type="primary"
                v-if="roleType == '健康管理师'||roleType == '系统管理员'"
                style="float: right"
                @click="sportDialogVisible = true"
                >修改</Button
              >
            </h3>
            <ul>
              <li>{{ item.object.baseSport }}</li>
            </ul>
            <div class="ydjy">
              <p class="name" v-html="item.object.notice"/>
              <div class="ydlx">
                <div v-for="(citem, index) in item.object.items" :key="index">
                  <img :src="citem.photo" alt />
                  <p>{{ citem.name }}</p>
                </div>
              </div>
            </div>
          </li>
          <li class="conli dnxl" v-if="item.title == '大脑训练'">
            <h3>大脑训练</h3>
            <ul>
              <li v-for="(citem, index) in item.object" :key="index">
                {{ citem.title + "：" + citem.value }}
              </li>
            </ul>
          </li>
          <!-- <li class="conli" v-if="item.title == '睡眠改善'">
            <h3 class="mb20">睡眠改善</h3>
            <h3 class="mb20">建议改善的习惯</h3>
            <div class="ydlx">
              <div v-for="(citem, index) in item.sleep.habit" :key="index">
                <img :src="citem.img" alt="" />
                <p>{{ citem.name }}</p>
              </div>
            </div>
            <h3 class="mt38">建议的训练</h3>
            <ul>
              <li v-for="(citem, index) in item.sleep.drill" :key="index">
                {{ citem }}
              </li>
            </ul>
          </li>-->
          <li class="conli" v-if="item.title == '中医方案'">
            <h3 class="mb20">{{ item.title }}</h3>
            <h3 class="mb20">您的健康状态辨识结果：{{ item.object.title }}</h3>
            <div class="zytl">
              <div v-for="(citem, index) in item.object.items" :key="index">
                <img :src="citem.photo" alt />
                <p>{{ citem.name }}</p>
              </div>
            </div>
            <div class="ysfl" v-for="(citem, index) in item.object.baseInfo" :key="index">
              <h3>{{ citem.title }}</h3>
              <div
                v-if="
                  citem.dietRehabilitation &&
                  citem.dietRehabilitation.length > 2
                "
              >
                <div
                  class="ysjy"
                  v-for="(ditem, index) in citem.dietRehabilitation.splice(2)"
                  :key="index"
                >
                  <p v-html="ditem.message"></p>
                </div>
              </div>
              <div v-else>
                <div
                  class="ysjy"
                  v-for="(ditem, index) in citem.dietRehabilitation"
                  :key="index"
                >
                  <p v-html="ditem.message"></p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </el-collapse-item>
    </el-collapse>
    <el-dialog append-to-body title="专属方案修改" :visible.sync="revamp" width="1200px">
      <el-form style="padding:30px 20px;" :model="form" :inline="true" class="formCss" size="small">
        <h3>
          营养膳食
        </h3>
        <el-form-item>
            <el-input v-model="form.tUserFoodsInfo.kcal"></el-input>Kcal
          </el-form-item>
        <div>
          <el-form-item label="碳水化合物：">
            <el-input v-model="form.tUserFoodsInfo.carbohydrateRate"></el-input
            >%
          </el-form-item>
          <el-form-item label="蛋白质：">
            <el-input v-model="form.tUserFoodsInfo.highProteinRate"></el-input>%
          </el-form-item>
          <el-form-item label="脂肪：">
            <el-input v-model="form.tUserFoodsInfo.fatRate"></el-input>%
          </el-form-item>
        </div>
        <h3>碳水化合物</h3>
        <div>
          <el-form-item label="细粮，全谷类，杂豆类：">
            <el-input v-model="form.tUserFoodDayInfo.mainFood"></el-input>g
          </el-form-item>
          <el-form-item label="薯类：">
            <el-input v-model="form.tUserFoodDayInfo.potato"></el-input>g
          </el-form-item>
          <el-form-item label="水果：">
            <el-input v-model="form.tUserFoodDayInfo.fruits"></el-input>g
          </el-form-item>
          <el-form-item label="蔬菜：">
            <el-input v-model="form.tUserFoodDayInfo.vegetables"></el-input>g
          </el-form-item>
        </div>
        <h3>蛋白质</h3>
        <div>
          <el-form-item label="鸡蛋：">
            <el-input v-model="form.tUserFoodDayInfo.egg"></el-input>g
          </el-form-item>
          <el-form-item label="牛奶：">
            <el-input v-model="form.tUserFoodDayInfo.milk"></el-input>g
          </el-form-item>
          <el-form-item label="肉类：">
            <el-input v-model="form.tUserFoodDayInfo.meat"></el-input>g
          </el-form-item>
          <el-form-item label="鱼类/周：">
            <el-input v-model="form.tUserFoodDayInfo.fish"></el-input>g
          </el-form-item>
        </div>
        <h3>脂肪</h3>
        <div>
          <el-form-item label="动物油：">
            <el-input v-model="form.tUserFoodDayInfo.animalOil"></el-input>g
          </el-form-item>
          <el-form-item label="坚果：">
            <el-input v-model="form.tUserFoodDayInfo.nut"></el-input>g
          </el-form-item>
          <el-form-item label="植物油：">
            <el-input v-model="form.tUserFoodDayInfo.vegetableOil"></el-input>g
          </el-form-item>
          <el-form-item label="内脏/周：">
            <el-input v-model="form.tUserFoodDayInfo.internalOrgans"></el-input
            >g
          </el-form-item>
          
        </div>
        <h3>饮食建议</h3>
        <div>
         <el-form-item>
            <el-input
              type="textarea"
              v-model="form.tUserFoodsInfo.suggest"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <Button @click="revamp = false" style="margin-right:10px;">取 消</Button>
        <Button type="primary" @click="jkdaupdate(form)">确 定</Button>
      </div>
    </el-dialog>
    <el-dialog append-to-body title="运动康复修改" :visible.sync="sportDialogVisible" width="1200px">
      <el-form style="padding:30px 20px;" :model="sportForm" ref="sportForm">
        <el-form-item label="每日基础步数，目标为:" prop="baseSport">
          <el-input
            v-model="sportForm.baseSport"
          ></el-input>
          <span>步</span>
        </el-form-item>
        <el-form-item label="建议运动:" prop="sports">
          <el-checkbox-group v-model="sportForm.sports">
            <el-checkbox v-for="(item, index)  in sports" :key="index" :label="item"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <Button @click="sportDialogVisible = false" style="margin-right:10px;">取 消</Button>
          <Button type="primary" @click="jkdaupdate(form)">确 定</Button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      activeNames: 0,
      roleType: "",
      revamp: false,
      sportDialogVisible:false,
      basics: "",
      userId:"",
      form: {
        tUserFoodDayInfo: {},
        tUserFoodsInfo: {},
      },
      sports:[
        '跑步机',
        '篮球',
        '乒乓球',
        '羽毛球',
        '慢跑',
        '太极',
        '广场舞',
        '瑜伽'
      ],
      sportForm:{
        baseSport:'',
        sports:[],
      },
      sportFormRules: { // 表单验证规则
        baseSport: [
          { required: true, message: '请输入每日基础步数目标',trigger: 'blur'}
        ]
      }
    };
  },
  created() {
    this.userId = this.$route.query.userId;
    this.roleType = this.user.roleType;
    console.log('roleType',this.roleType);
    this.exclusive();
  },
  methods: {
    exclusive() {
      var that = this;
      var parameter = {
        userId: that.userId,
      };
      api.jkdaexclusive(parameter).then((response) => {
        if (response.data.code == 0) {

          that.basics = response.data.data;
          console.log(1234);
          if(that.basics&&that.basics.object&&that.basics.object.length)
          {
            var meal=that.basics.object[0];
            if(meal)
            {
              var tUserFoodDayInfo=that.form.tUserFoodDayInfo;
              tUserFoodDayInfo.id = meal.foods[0].id;

              var tUserFoodsInfo=that.form.tUserFoodsInfo;
              tUserFoodsInfo.id = meal.object.id;

              if(meal.object.kcal)
              {
                 tUserFoodsInfo.kcal=parseFloat(meal.object.kcal);
              }
              if(meal.object.highProteinRate)
              {
                 tUserFoodsInfo.highProteinRate=parseFloat(meal.object.highProteinRate);
              }
              if(meal.object.fatRate)
              {
                 tUserFoodsInfo.fatRate=parseFloat(meal.object.fatRate);
              }
              if(meal.object.carbohydrateRate)
              {
                 tUserFoodsInfo.carbohydrateRate=parseFloat(meal.object.carbohydrateRate);
              }

              tUserFoodsInfo.suggest=meal.object.suggest;
              for(var i=0;i<meal.foods.length;i++)
              {
                  var food=meal.foods[i];
                  for(var ii=0;ii<food.detail.length>0;ii++){
                      var  detail=food.detail[ii];
                      var enName=detail.enName;
                      if(enName)
                      {
                        if(detail.weight)
                        {
                           tUserFoodDayInfo[enName]=parseFloat(detail.weight);
                        }
                      }

                  }
              }
              that.form=JSON.parse(JSON.stringify(that.form));//解决数据回显无法编辑的问题
            }

          }

        }
      });
    },
    jkdaupdate(form) {
      var that = this;
      var parameter = form;
      parameter.tUserFoodDayInfo.userId = that.userId;
      parameter.tUserFoodsInfo.userId = that.userId;
      console.log(form);
      api.jkdaupdate(parameter).then((response) => {
        if (response.data.code == 0) {
          that.exclusive();
          that.revamp = false;
        }
      });
    },
    sportUpdate(){

    }
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  .eleHead {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
    font-weight: bold;
    .hdate {
      color: #185ad6;
      margin-left: 20px;
    }
    .hunfold {
      float: right;
    }
  }
  
  .conbox {
    padding: 0 20px;
    font-size: 14px;
    .conli {
      padding: 20px 0;
      margin-bottom: 20px;
      background: rgba(6, 107, 168, 0.04);
      font-size: 14px;
      h3 {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
      }
      .foods {
        padding-left: 20px;
        margin-bottom: 20px;
        p {
          font-weight: bold;
          margin-bottom: 5px;
        }
        span {
          margin-right: 15px;
        }
      }
      ul {
        display: flex;
        li {
          &:last-child {
            border-right: none;
          }
          width: 340px;
          padding-left: 20px;
          padding-right: 20px;
          border-right: 1px solid #e3e3e3;
          margin-top: 10px;
        }
      }
      .ysjy {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-bottom: 0;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      .ydjy {
        padding-left: 20px;
        .name {
          font-size: 11px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          font-weight: bold;
          line-height: 20px;
          margin-bottom:10px;
        }
      }
      .ydlx {
        display: flex;
        text-align: center;
        div {
          margin-right: 12px;
          max-width: 140px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 21px;
        }
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }
    .dnxl {
      ul {
        display: block;
        margin-bottom: 0;
        li {
          display: inline-block;
          margin-bottom: 14px;
        }
      }
    }
    .zytl {
      display: flex;
      text-align: center;
      div {
        margin-right: 12px;
        width: 80px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 21px;
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .ysfl
    {
      margin-top:20px;
    }
  }
}

/deep/.el-input {
    width: 160px;
    margin-right: 5px;
}
/deep/.formCss .el-form-item__label {
    line-height: 32px;
    margin-left: 10px;
}
/deep/.el-textarea__inner {
    width: 1032px;
    height: 172px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

/deep/.el-form-item__content
{
  display: flex;
  align-items: center;
  line-height:unset;
}
/deep/.el-form-item__label
{
    height: 32px;
    display: flex;
    align-items: center;
    line-height:unset;
}
/deep/.el-checkbox
{
  margin-right:12px;
}
/deep/.el-checkbox__label
{
  padding-left:4px;
}
</style>
