<template>
<el-dialog
title='修改密码'
fullscreen
:visible.sync="visible"
:before-close="handleClose"
>
  <div class="content-wrapper">
    <div class="mid-content">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item  label="用户名:">
          <el-input
            v-model="ruleForm.userName"
            disabled
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item  label="请输入原密码:" prop="password">
          <el-input
            v-model="ruleForm.password"
            maxlength="12"
            minlength="6"
            placeholder="请输入原密码"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item  label="请输入新密码:" prop="newPassword">
          <el-input
            v-model="ruleForm.newPassword"
            maxlength="12"
            minlength="6"
            placeholder="请输入新密码"
            type="password"
            autocomplete="off"
          ></el-input>
          <div class="form-item-tip">{{rules.newPassword[0].tip}}</div>
        </el-form-item>
        <el-form-item  label="再次输入新密码:" prop="confirmPassword">
          <el-input
            v-model="ruleForm.confirmPassword"
            maxlength="12"
            minlength="6"
            placeholder="请再次输入新密码"
            type="password"
            autocomplete="off"
          ></el-input>
          <div class="form-item-tip">{{rules.confirmPassword[0].tip}}</div>
        </el-form-item>
      </el-form>
      <el-button @click="submit" class="submitButton">确定</el-button>

    </div>
  </div>
  <sucessDialog content="密码修改成功" :dialogVisible="sucessDialogVisible" @confirm="sucessDialogConfirm" @close="sucessDialogClose"/>
</el-dialog>
</template>
<script>
import sucessDialog from "../sucessDialog.vue";
export default {
  name: "passModify-page",
  props: ["visible"],
  components: {
    sucessDialog
  },
  data() {
    var checkPwd2=((rule,value,callback)=>{
      if(value.trim().length==0){
        callback(new Error("请确认密码不能为空"));
      }else if(value !=this.ruleForm.newPassword){
        callback(new Error("两次输入的密码不一致，请重新输入"));
      }else{
        callback();
      }
    })
    return {
      checked:false,
      sucessDialogVisible:false,
      ruleForm:{
        userName: "",
        password:"",
        newPassword:"",
        confirmPassword:""
      },
      activeName: '',
      rules: { // 表单验证规则

        password: [
          { required: true, message: '请输入原密码', trigger: 'blur'},
        ],
        newPassword: [
          { required: true, message: '请设置新密码',tip:'密码为英文字母+数字6-12个字符，不支持符号', trigger: 'blur'},
          {
            pattern: /^[a-zA-Z0-9]{6,12}$/,
            message: "密码设置不符合要求",
            trigger: "blur",
          }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入新密码', trigger: 'blur'},
          {validator:checkPwd2,trigger:"blur"}
        ]
      }
    };
  },
  mounted(){


  },
  created() {
  var that=this;
    that.$watch('visible', function() {
      console.log(that.user);
        if(that.visible)
        {
          that.ruleForm.userName=that.user.userName;
        }
    });
  },
  methods: {
     handleClose: function () {
      this.$emit('close');
     },
     sucessDialogConfirm(){
      var that=this;
      that.sucessDialogVisible=false;
     },
     sucessDialogClose(){
      var that=this;
      that.sucessDialogVisible=false;
     },
     submit(){
       var that=this;
       that.$refs['ruleForm'].validate((valid) => {
       if (valid) {
         that.api.userModifyPassword(that.ruleForm).then((response) => {
          if(response.data.code==0)
          {
            that.sucessDialogVisible=true;
          }
          else{
          that.$Message.error(response.data.msg);
          }
        });
       } else {
         console.log('error submit!!');
         return false;
       }
     });


     }
  },
};
</script>
<style lang="less" scoped="scoped">
.content-wrapper
{
  display: flex;
  align-items: center;
  flex-direction: column;
  background:#fff;

  .mid-content{
    width:302px;
  }
  .form-item-tip
  {
    position: absolute;
    top:0;
    left:100%;
    width: 400px;
    font-size: 11px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.25);
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
  .submitButton{
    width: 100%;
    height: 40px;
    background: #185AD6;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
/deep/.el-input__inner
{
  border: 0;
  height: 40px;
  text-align: left;
  background: transparent;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  font-size: 12px;
  &:-webkit-autofill {
    transition: background-color 99999s ease-in-out 0s !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.85) !important;
  }
  // 自动填充文本样式
  &:-webkit-autofill::first-line {
    font-size: 12px;
  }
}
/deep/.el-form-item
{
display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom:30px
}
/deep/.el-form-item__label
{
position: absolute;
right: 100%;
top: 0;
width: 200px;
height: 100%;
display: flex;
justify-content: flex-end;
align-items: center;
line-height:unset;
}
/deep/.el-input.is-disabled .el-input__inner
{
background: rgba(0, 0, 0, 0.04);
border-radius: 2px;
border: 1px solid rgba(0, 0, 0, 0.15);
}
/deep/.el-form-item__content {
  border-bottom: 1px #F1F1F2 solid;
  display: flex;
  align-items: center;
  width:100%;
  position:relative;
}
/deep/.el-form-item__error
{
  font-size:10px;
  padding-top:8px;
  height: 50px;
}
/deep/.el-form
{
margin-top:20px;
}
/deep/.el-checkbox__input
{
line-height:unset;
display: flex;
}
</style>
