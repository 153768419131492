const routeslistAdmin = [
  {
    path: "/orderManagement",
    name: "订单管理",
    icon: "el-icon-notebook-2",
  },
  {
    path: "/healthRecords",
    name: "健康档案",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/nutritionManagement",
    name: "营养素管理",
    icon: "el-icon-document",
  },
  {
    path: "/offlineServiceManagement",
    name: "线下服务管理",
    icon: "el-icon-s-shop",
  },

  {
    path: "/earlyWarningManagement",
    name: "预警管理",
    icon: "el-icon-sunrise-1",
  },
  {
    path: "/teamContact",
    name: "联系管理",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/teamMessage",
    name: "消息管理",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/offlineAgencies",
    name: "线下机构管理",
    icon: "el-icon-office-building",
  },
  // {
  //   path: "/role",
  //   name: "角色管理",
  //   icon: "el-icon-bank-card",
  // },
  {
    path: "/communityManagement",
    name: "社区管理",
    icon: "el-icon-s-home",
  },

];
const routeslistDoctor = [
  {
    path: "/healthRecords",
    name: "健康档案",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/nutritionApplyPlan",
    name: "营养素方案申请",
    icon: "el-icon-document",
  },
  {
    path: "/message",
    name: "消息管理",
    icon: "el-icon-chat-line-round",
  },
  {
    path: "/earlyWarningManagement",
    name: "预警管理",
    icon: "el-icon-sunrise-1",
  },
  {
    path: "/dietInterpret",
    name: "饮食解读",
    icon: "el-icon-fork-spoon",
  },
  {
    path: "/recipe",
    name: "精准餐单任务",
    icon: "el-icon-reading",
  },
  {
    path: "/workbench",
    name: "工作台",
    icon: "el-icon-menu",
  },
];
const routeslistHelper = [
  {
    path: "/healthRecords",
    name: "健康档案",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/message",
    name: "消息管理",
    icon: "el-icon-chat-line-round",
  },
  {
    path: "/dietInterpret",
    name: "饮食解读",
    icon: "el-icon-fork-spoon",
  },
  {
    path: "/workbench",
    name: "工作台",
    icon: "el-icon-menu",
  },
  
];
const routeslistHelperAdmin = [
  {
    path: "/team",
    name: "团队成员管理",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/teamMessage",
    name: "消息管理",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/teamDiet",
    name: "饮食解读",
    icon: "el-icon-folder-opened",
  }
  
];
const routeslistDoctorAdmin = [
  {
    path: "/team",
    name: "团队成员管理",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/teamNurtrientApplyPlan",
    name: "营养素方案申请",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/teamMessage",
    name: "消息管理",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/teamWarning",
    name: "预警管理",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/teamDiet",
    name: "饮食解读",
    icon: "el-icon-folder-opened",
  },
  {
    path: "/teamPrecise",
    name: "精准餐单",
    icon: "el-icon-folder-opened",
  }
  
];

const routeslistJGAdmin = [
  {
    path: "/orgTeam",
    name: "机构团队管理",
    icon: "el-icon-folder-opened",
  }
];

// 根据角色获取导航列表
export const getMenuList = (role,roleE) => {
  console.log('role',role);
  if (role === roleE.GLY) {
    return routeslistAdmin;
  } else if (role === roleE.JGS) {
    return routeslistDoctor;
  } else if (role === roleE.ZXS) {
    return routeslistHelper;
  } else if (role === roleE.ZXSGLY) {
    return routeslistHelperAdmin;
  } else if (role === roleE.TDGLY) {
    return routeslistDoctorAdmin;
  } else if (role === roleE.JGGLY) {
    return routeslistJGAdmin;
  } else {
    return [];
  }
};
