import { render, staticRenderFns } from "./yyss.vue?vue&type=template&id=4b6a63e5&scoped=true"
import script from "./yyss.vue?vue&type=script&lang=js"
export * from "./yyss.vue?vue&type=script&lang=js"
import style0 from "./yyss.vue?vue&type=style&index=0&id=4b6a63e5&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6a63e5",
  null
  
)

export default component.exports