<template>
<div>
  <div class="content-wrapper">
    <div class="title">重置密码</div>
    <div class="mid-content">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item  label="手机号:" prop="mobile">
          <el-input
            v-model="ruleForm.mobile"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item  label="请输入验证码:" prop="verifyCode">
          <el-input
            v-model="ruleForm.verifyCode"
            placeholder="请输入验证码"
            autocomplete="off"
          ></el-input>
          <div @click="getVeriCode" :class="checkCode.class">{{checkCode.title}}</div>
        </el-form-item>
        <el-form-item  label="请输入新密码:" prop="password">
          <el-input
            v-model="ruleForm.password"
            maxlength="12"
            minlength="6"
            placeholder="请输入新密码"
            type="password"
            autocomplete="off"
          ></el-input>
          <div class="form-item-tip">{{rules.password[0].tip}}</div>
        </el-form-item>
        <el-form-item  label="再次输入新密码:" prop="confirmPassword">
          <el-input
            v-model="ruleForm.confirmPassword"
            maxlength="12"
            minlength="6"
            placeholder="请再次输入新密码"
            type="password"
            autocomplete="off"
          ></el-input>
          <div class="form-item-tip">{{rules.confirmPassword[0].tip}}</div>
        </el-form-item>
      </el-form>
      <el-button @click="submit" class="submitButton">确定</el-button>

    </div>
  </div>
  <sucessDialog content="密码重置成功" :dialogVisible="sucessDialogVisible" @confirm="sucessDialogConfirm" @close="sucessDialogClose"/>
</div>
</template>
<script>
import sucessDialog from "../sucessDialog.vue";
export default {
  name: "passModify-page",
  components: {
    sucessDialog
  },
  data() {
    var checkPwd2=((rule,value,callback)=>{
      if(value.trim().length==0){
        callback(new Error("请确认密码不能为空"));
      }else if(value !=this.ruleForm.password){
        callback(new Error("两次输入的密码不一致，请重新输入"));
      }else{
        callback();
      }
    })
    return {
      checked:false,
      sucessDialogVisible:false,
      ruleForm:{
        mobile: "",
        verifyCode:"",
        password:"",
        confirmPassword:""
      },
      checkCode:{
        disable:false,
        class:'veriCodeButton',
        count:120,
        title:'获取验证码'
      },
      activeName: '',
      rules: { // 表单验证规则
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur'},
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          }
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        password: [
          { required: true, message: '请设置新密码',tip:'密码为英文字母+数字6-12个字符，不支持符号', trigger: 'blur'},
          {
            pattern: /^[a-zA-Z0-9]{6,12}$/,
            message: "密码设置不符合要求",
            trigger: "blur",
          }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入新密码', trigger: 'blur'},
          {validator:checkPwd2,trigger:"blur"}
        ]
      }
    };
  },
  mounted(){

  },
  created() {

  },
  methods: {
    sucessDialogConfirm(){
     var that=this;
     that.sucessDialogVisible=false;
     that.$router.push({ path: "/login" });
    },
    sucessDialogClose(){
     var that=this;
     that.sucessDialogVisible=false;
     that.$router.push({ path: "/login" });
    },
    getVeriCode(){
      var that=this;
      if(that.checkCode.disable)return;

      that.$refs['ruleForm'].validateField('mobile', mobileError => {
          if (!mobileError) {
            that.api.getRetrieveSMS({mobile:that.ruleForm.mobile}).then((response) => {
              var data=response.data;
              if(data.code==0)
              {
                that.checkCode.disable=true;
                var count=that.checkCode.count;
                var title=that.checkCode.title;
                that.checkCode.title=(count--)+'秒后重新获取';
                that.checkCode.class='veriCodeDisableButton';
                var interval=setInterval(function() {
                   that.checkCode.title=(count--)+'秒后重新获取';
                   if(count<=0)
                   {
                    that.checkCode.title=title;
                    that.checkCode.disable=false;
                    that.checkCode.class='veriCodeButton';
                    window.clearInterval(interval);
                   }
                }, 1000);
              }
              else
              {
                that.$Message.error(data.msg);
              }
            });
          } else {
              console.log('手机号验证失败');
              return false;
          }
      });
    },
    submit(){
      var that=this;
      that.$refs['ruleForm'].validate((valid) => {
      if (valid) {
        that.api.userRetrievePassword(that.ruleForm).then((response) => {
         if(response.data.code==0)
         {
           that.sucessDialogVisible=true;

         }
         else{
         that.$Message.error(response.data.msg);
         }
       });
      } else {
        console.log('error submit!!');
        return false;
      }
    });
   }
  },
};
</script>
<style lang="less" scoped="scoped">
.content-wrapper
{
  display: flex;
  align-items: center;
  flex-direction: column;
  background:#fff;
  .title{
    margin-top:67px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  .mid-content{
    width:302px;
  }
  .form-item-tip
  {
    position: absolute;
    top:0;
    left:100%;
    width: 400px;
    font-size: 11px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.25);
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
  .veriCodeButton{
    margin-left: 8px;
    background: #185AD6;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    width: 90px;
    height: 32px;
    border-radius: 2px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  .submitButton{
    width: 302px;
    height: 40px;
    background: #185AD6;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    margin-top: 30px;
  }
}
/deep/.el-input
{
  height: 32px;
  flex:1;
  display: flex;
  align-items: center;
}
/deep/.el-input__inner
{
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 32px;
  text-align: left;
  -webkit-appearance: none;
  border-radius: 0px;
  font-size: 12px;
  &:-webkit-autofill {
    transition: background-color 99999s ease-in-out 0s !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.85) !important;
  }
  // 自动填充文本样式
  &:-webkit-autofill::first-line {
    font-size: 12px;
  }
}
/deep/.el-form-item
{
display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom:30px
}
/deep/.el-form-item__label
{
position: absolute;
right: 100%;
top: 0;
width: 200px;
height: 100%;
display: flex;
justify-content: flex-end;
align-items: center;
line-height:unset;
}
/deep/.el-input.is-disabled .el-input__inner
{
background: rgba(0, 0, 0, 0.04);
border-radius: 2px;
border: 1px solid rgba(0, 0, 0, 0.15);
}
/deep/.el-form-item__content {
  display: flex;
  align-items: center;
  width:100%;
  position:relative;
  line-height:reset;
}
/deep/.el-form-item__error
{
  font-size:10px;
  padding-top:8px;
  height: 50px;
}
/deep/.el-form
{
margin-top:20px;
}
/deep/.el-checkbox__input
{
line-height:unset;
display: flex;
}
</style>
