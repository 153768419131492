Array.prototype.findWithKey = function(key,value)
{
  var scxx;
  for (var i=0;i< this.length;i++) {
   var item = this[i];
   if (item[key]==value) {
      scxx= item;
      break;
   }
  }
  return scxx;
};
Array.prototype.deleteWithKeyValue=function(key,value)
{
  for (var i=0;i< this.length;i++) {
   var item = this[i];
   if (item[key]==value) {
      this.splice(i, 1);
      break;
   }
  }
};
Array.prototype.replaceWithKeyValue=function(key,value,item)
{
  for (var i=0;i< this.length;i++) {
   var citem = this[i];
   if (citem[key]==value) {
      this.splice(i, 1,item);
      break;
   }
  }
};
Array.prototype.insertBeforeKeyValue=function(key,value,item)
{
  for (var i=0;i< this.length;i++) {
   var nitem = this[i];
   if (nitem[key]==value) {
      this.splice(i, 0,item);
      break;
   }
  }
};
Array.prototype.insertAfterKeyValue=function(key,value,item)
{
  for (var i=0;i< this.length;i++) {
   var nitem = this[i];
   if (nitem[key]==value) {
      this.splice(i+1, 0,item);
      break;
   }
  }
};
Array.prototype.insertAtIndex=function(index,item)
{
  for (var i=0;i< this.length;i++) {
   if (i==index) {
      this.splice(i, 0,item);
      break;
   }
  }
};
Array.prototype.pops = function(num)
{
  var obj;
  if (num<=0) {
    num=1;
  }
  for (var i=0;i< num;i++) {
   if (i==num-1) {
     obj=this.pop();
   }
   else {
     this.pop();
   }
  }
  return obj;
};
Array.prototype.unique = function () {
    return this.sort().join(",,").replace(/(,|^)([^,]+)(,,\2)+(,|$)/g,"$1$2$4").replace(/,,+/g,",").replace(/,$/,"").split(",");
}
