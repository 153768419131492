<template>
  <div class="ddgl">
    <Layout>
      <ul class="reservationList">
        <li>
          <span>今日核销</span>
          <span>￥{{ zhx.todayVerifyAmount }}</span>
        </li>
        <li>
          <span>本月核销</span>
          <span>￥{{ zhx.monthVerifyAmount }}</span>
        </li>
      </ul>
      <Card>
        <p slot="title">订单管理</p>
        <div class="search-area">
          机构：
          <Select
            v-model="zygValue"
            size="small"
            clearable
            placeholder="请选择"
            class="search-input"
          >
            <Option
              v-for="item in zyg"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></Option> </Select
          >服务状态：
          <Select
            v-model="yyztValue"
            size="small"
            clearable
            placeholder="请选择"
            class="search-input"
          >
            <Option
              v-for="item in yuyzt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></Option> </Select
          >购买时间：
          <DatePicker
            v-model="sjValue"
            size="small"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></DatePicker>
          <Button type="primary" class="search-button" size="small" @click="search()">搜索</Button>
          <Button type="primary" style="margin-left:10px;" size="small" @click="verificationClick()">核销</Button>
        </div>
        <div class="content">
        <el-table :data="tableData">
          <el-table-column
            type="index"
            label="序号"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="id"
            label="订单ID"
            minWidth="140"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="goodsName"
            label="服务项目"
            minWidth="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="userName"
            label="下单人"
            minWidth="80"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="mobile"
            label="手机"
            minWidth="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="orderAmount"
            label="实收金额"
            minWidth="110"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="payTime"
            label="购买日期"
            minWidth="180"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="status"
            :formatter="tonum"
            label="服务状态"
            minWidth="110"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="verifyTime"
            label="核销日期"
            minWidth="180"
            align="center"
          ></el-table-column>
          
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="pagenum"
          @current-change="handleCurrentChange"
          :total="total"
        ></el-pagination>
      </div>
      </Card>
      
      
      
      <el-dialog title="核销" minWidth="30%" :visible.sync="dialogVisible">
        <p class="mb20">输入用户订单号码进行核销</p>
        <el-input v-model="checkCodeInput" placeholder="请输入"></el-input>
        <span slot="footer" class="dialog-footer">
          <Button @click="dialogVisible = false">取 消</Button>
          <Button type="primary" class="verify-button" @click="verify"
            >去 核 销</Button
          >
        </span>
      </el-dialog>
    </Layout>
  </div>
</template>
<style lang="less" scoped="scoped">
.search-area {
  display: flex;
  justify-content: flex-start;
  .search-date {
    width: 200px;
    margin-right: 20px;
  }
  .search-input {
    width: 150px;
    margin-right: 20px;
  }
}
.button-wrapper
{
  margin-bottom:10px;
}
.search-button
{
margin-left:10px;
}
.verify-button
{
  margin-left:10px;
}
.reservationList {
  display: flex;
  li {
    width: 628px;
    height: 150px;
    line-height: 150px;
    background: #58b9ca;
    background-size: 100% 100%;
    border-radius: 8px;
    color: #ffffff;
    font-weight: bold;
    margin: 0px 20px 10px 0px;
    text-align: center;
    span {
      padding: 0 15px;
    }
  }
}
.conmain {
  padding: 20px;
  margin: 20px 0;
  background-color: #fff;
  .demo-input-suffix {
    margin: 20px 0;
  }
}
</style>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      total: 10,
      pagenum: 1,
      pagesize: 10,
      zhx: {},
      tableData: [],
      dialogVisible: false,
      checkCodeInput: "",
      zyg: [],
      jzzx: [],
      yuyzt: [
        {
          value: "1",
          label: "待支付",
        },
        {
          value: "2",
          label: "已支付",
        },
        {
          value: "3",
          label: "已使用",
        },
        {
          value: "4",
          label: "已退款",
        }
      ],
      zygValue: "",
      yyztValue: "",
      sjValue: "",
    };
  },
  created() {
    this.zyglist();
    this.ddglzkx();
    this.ddglInquire();
  },
  methods: {
    tonum(row) {
      var num = parseInt(row.status);
      switch (num) {
        case 1:
          return "待支付";
        case 2:
          return "已支付";
        case 3:
          return "已使用";
        case 4:
          return "已退款";
      }
    },
    zyglist() {
      var that = this;
      var parameter = {
        page: 1,
        pagesize: 100,
      };
      api.ddglofflineAgencies(parameter).then((response) => {
        response = response.data;
        console.log(response)
        if (response.length > 0) {
          response.forEach((element) => {
            that.zyg.push({
              //中医馆列表
              value: element.id,
              label: element.categoryName,
            });
          });
          console.log(that.zyg)
        }
      });
    },
    ddglInquire() {
      //列表
      var that = this;
      var parameter = {
        tcmid: that.zygValue,
        status: that.yyztValue,
        start: Date.parse(that.sjValue[0]),
        end: Date.parse(that.sjValue[1]),
        page: that.pagenum,
        pageSize: that.pagesize,
      };
      api.ddglInquire(parameter).then((response) => {
        response = response.data;
        if (response.code == 0) {
          var page=response.page?response.page:response.data;
          that.total = page.totalCount;
          that.tableData = page.list;
        }
      });
    },
    ddglzkx() {
      //核销总数
      var that = this;
      api.ddglzkx().then((response) => {
        response = response.data;
        if (response.code == 0) {
          that.zhx = response.data;
        }
      });
    },
    search() {
      //搜索
      this.pagenum = 1;
      this.ddglInquire();
    },
    handleCurrentChange(cpage) {
      //页数
      this.pagenum = cpage;
      this.ddglInquire();
    },
    verificationClick(){
       var that=this;
       that.dialogVisible=true;
    },
    verify(){
     var that = this;
     api.ddglOrderCheck({'checkCode':that.checkCodeInput}).then((response) => {
            console.log(response);
            response = response.data;
            if (response.code == 0) {
              that.$message({
                type: "success",
                message: "核销成功!",
              });
              that.checkCode='';
              that.dialogVisible=true;
            } else {
              that.$message({
                type: "success",
                message: response.msg,
              });
            }
          });
    },
    refundClick(item) {
      //退款
      var that = this;
      var parameter = item;
      that
        .$confirm("您确定要退款吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          api.ddglrefundy(parameter).then((response) => {
            console.log(response);
            response = response.data;
            if (response.code == 0) {
              that.$message({
                type: "success",
                message: "退款成功!",
              });
              that.ddglInquire();
            } else {
              that.$message({
                type: "success",
                message: response.msg,
              });
            }
          });
          that.ddglInquire();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
