<template>
  <Layout>
    <Card>
      <p v-if="!hideTitle" slot="title">消息管理</p>
      <div class="search-area">
        <Input class="search-input" v-model="keyword" placeholder="按手机号查询" />
        <Button type="primary" @click="handleSearch">查询</Button>
      </div>
      <div class="content">
      <Table class="lu-table" :columns="columns" :data="list">
        <template slot-scope="{ row }" slot="avatar">
          <img :src="row.avatar" class="avatar" />
        </template>
        <template slot-scope="{ row }" slot="action">
          <div v-if="editAble">
            <div v-if="row.isSend==1" class="dot"/>
            <Button v-if="user.isGLY()" @click="openNews(row)">
              {{ statusMsgMap[row.isSend] }}
            </Button>
            <Button v-else @click="handleOpenDialog(row)">
              {{ statusMsgMap[row.isSend] }}
            </Button>
          </div>
          <div v-else>

            <Button @click="handleOpenDialog(row)">
              查看
            </Button>
          </div>


        </template>
      </Table>
      <div class="lu-footer">
        <Page transfer :current="page" :total="total" @on-change="changePage" />
      </div>
    </div>
    </Card>
    
    <el-dialog append-to-body :visible.sync="dialogVisible" title="消息">
      <div class="dialog-content">
        <div class="message-list">
          <div class="message-item" v-for="item in messageList" :key="item.id">
            <img :src="item.avatar" class="lu-avatar" />
            <div class="right">
              <div class="nick-name">{{ item.userName }}</div>
              <div class="text">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div v-if="editAble">
          <Input v-model="reply" type="textarea" :rows="4" placeholder="请输入回复" autofocus />
          <div class="lu-footer">
            <Button class="send-btn" @click="close">关闭</Button>
            <Button type="primary" class="send-btn" @click="submit">发送</Button>
          </div>
        </div>   
        <div v-else>
          <div class="lu-footer">
            <Button class="send-btn" @click="close">关闭</Button>
          </div>
        </div>
      </div>
    </el-dialog>
    <newsDialog @close="newsClose" :visible="news.newsDialogVisible" :receiverId="news.receiverId" :relatedUserId="news.relatedUserId"/>
  </Layout>
</template>
<script>
import Axios from "axios";
import { handleFail } from "../utils/login";
import newsDialog from "./common/dialog/newsDialog.vue";
const PAGE_SIZE = 10;

const statusMsgMap = {
  1: "处理",
  2: "查看",
  3: "处理",
};

// 表头
export const columns = [
  {
    title: "姓名",
    key: "userName",
    minWidth: 150,
    align: "center",
  },
  {
    title: "手机号",
    key: "mobile",
    minWidth: 150,
    align: "center",
  },
  {
    title: "性别",
    key: "gender",
    minWidth: 120,
    align: "center",
  },
  {
    title: "微信昵称",
    key: "nickName",
    minWidth: 150,
    align: "center",
  },
  {
    title: "出生日期",
    key: "birth",
    minWidth: 150,
    align: "center",
  },

  {
    title: "微信头像",
    minWidth: 120,
    slot: "avatar",
    align: "center",
  },
  {
    title: "消息",
    slot: "action",
    minWidth: 120,
    align: "center",
    fixed: "right",
  },
];

export default {
  name: "message-page",
  props: ["doctorId","hideTitle","readOnly"],
  components: {
    newsDialog
  },
  data() {
    return {
      keyword: "", // 查询姓名或电话
      page: 1,
      pageSize: PAGE_SIZE,
      total: 1,
      list: [], // 列表
      columns,
      statusMsgMap,
      dialogVisible: false, // 详情弹窗
      detailMessages: [],
      reply: "",
      status: 1,
      curId: null,
      curUserId: null,
      curUserName: "",
      editAble:false,
      news:{
        newsDialogVisible:false,
        receiverId:"",
        relatedUserId:"",
      },
    };
  },
  mounted() {
    // 初始化列表
    this.fetchData({ page: 1 });
    this.refreshData();
  },
  created(){
    var that=this;
    that.fetchData({ page: 1 });
    that.$watch('doctorId', function() {
      if(that.doctorId)
      {
        that.fetchData({ page: 1 });
      }
      else{
        that.list=[];
        that.total=0;
      }
    });
    that.$watch('readOnly', function() {
       this.editAble=!this.readOnly;
    });
    this.editAble=!this.readOnly;
  },
  computed: {
    messageList: function () {
      const list = this.detailMessages;
      return list;
    },
  },
  methods: {
    newsClose(){
      this.news.newsDialogVisible=false;
      this.news.receiverId='';
      this.news.relatedUserId='';
    },
    openNews(row){
      this.news.receiverId=row.doctorId;
      this.news.relatedUserId=row.userId;
      this.news.newsDialogVisible=true;
    },
    // 查询
    handleSearch() {
      this.fetchData({ page: 1 });
    },
    refreshData(){
      
    },
    fetchData({ page,loading }) {

      const vm = this;
      const { keyword } = vm;
      Axios.post("/api/client/message/queryList", {
        ...(keyword && { mobile: keyword }),
        page,
        loading,
        pageSize: PAGE_SIZE,
        doctorId:vm.doctorId
      })
        .then((res) => {
          handleFail(res);
          const {
            data: {
              page: { list, totalCount: total },
            },
          } = res;

          vm.total = total;
          vm.list = list;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 换页
    changePage(page) {
      this.page = page;
      this.fetchData({ page: page });
    },
    // 点击查看或处理
    handleOpenDialog(row) {
      const vm = this;
      var id=row.id;
      var status=row.isSend;
      var userId=row.userId;
      var userName=row.userName;
      if(vm.editAble)
      {
        row.isSend=3;
        Axios.post("/api/client/message/update", { id:id,isSend:row.isSend });
      }
      Axios.post("/api/client/message/query", { id, userId }).then((res) => {
        const {
          data: { message },
        } = res;
        vm.detailMessages = message;
        vm.dialogVisible = true;
        vm.status = status;
        // 当前id
        vm.curId = id;
        // 当前用户id
        vm.curUserId = userId;
        vm.curUserName = userName;
      });
    },
    freshData() {
      const { curId: id, curUserId: userId } = this;
      const vm = this;
      Axios.post("/api/client/message/query", { id, userId }).then((res) => {
        const {
          data: { message },
        } = res;
        vm.detailMessages = message;
      });
    },
    submit() {
      const vm = this;
      const { reply, curId, curUserId, curUserName } = this;
      // TODO: 回复
      Axios.post("/api/client/message/save", {
        replyId: curId,
        receiverId: curUserId,
        receiverName: curUserName,
        content: reply,
      })
        .then(function () {
          vm.freshData();
          vm.reply = null;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    close() {
      const { page } = this;
      this.fetchData({ page });
      this.detailMessages = [];
      this.reply = null;
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.message-list {
  height: 300px;
  overflow-y: scroll;
}
.dialog-content
{
  
}
/deep/.ivu-table-cell-slot
{
  position: relative;
}
/deep/.ivu-table-cell
{
  overflow: visible;
}
.content
{
  .dot{
    background-color: #ee0a24;
    border-radius: 100%;
    height: 8px;
    min-width: 0;
    width: 8px;
    position: absolute;
    right: 8px;
    top: -4px;
  }
}
.message-item {
  display: flex;
  margin-bottom: 30px;
  height: 64px;
  align-items: center;

  .right {
    display: flex;
    flex-direction: column;
    margin-left: 25px;

    .nick-name {
      font-size: 14px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.65);
      line-height: 32px;
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 32px;
    }
  }
}
.send-btn {
  margin: 10px 10px 0 0;
}

</style>
