<template>
  <Layout>
    <Card>
      <p v-if="!hideTitle" slot="title">预警管理</p>
      <div class="search-area">
        <DatePicker class="search-date" type="daterange" @on-change="changeDate" placeholder="开始时间 - 结束时间"></DatePicker>
        <Input class="search-input" v-model="keyword" placeholder="按手机号查询" />
        <Button type="primary" @click="handleSearch">查询</Button>
      </div>
      <div class="content">
      <EarlyTable :data="allList" @open="open" :readOnly="readOnly"/>
      <div class="lu-footer">
        <Page transfer :current="page" :total="total" @on-change="changePage" />
      </div>
    </div>
    </Card>
    
    <!-- 预警信息弹窗 -->
    <EarlyDialog :appendToBody="readOnly" :visible="dialogVisible" v-on:close="close" :earlyWarnData="earlyWarnData" />
  </Layout>
</template>
<script>
import Axios from "axios";
import { columns } from "./earlyData.js";
import EarlyDialog from "./early-dialog.vue";
import EarlyTable from "./early-table.vue";
import {handleFail } from "../../utils/login.js";

const PAGE_SIZE = 10;

const apiMap = {
  list: "/api/warning/queryList",
  detail: "/api/warning/info",
  delete: "/api/warning/delete",
  add: "/api/warning/save",
  edit: "/api/warning/update",
};

export default {
  name: "early-container",
  props: ["doctorId","hideTitle","readOnly"],
  components: {
    EarlyDialog,
    EarlyTable,
  },
  data() {
    return {
      start: null, // 开始时间 一天前
      end: null, // 结束时间 此时
      keyword: "", // 查询姓名或电话
      tabId: "all", // 预警状态
      page: 1,
      pageSize: PAGE_SIZE,
      total: 1,
      allList: [], // 预警列表 全部
      earlyWarnData: {}, // 预警详情
      dialogVisible: false, // 详情弹窗
      columns,
    };
  },

  async mounted() {

  },
  created(){
    var that=this;
    that.fetchData({ page: 1 });
    that.$watch('doctorId', function() {
      if(that.doctorId)
      {
        // 初始化列表
        that.fetchData({ page: 1 });
      }
      else{
        that.allList=[];
        that.total=0;
      }
    });

  },
  methods: {
    // 修改日期
    changeDate(date) {
      if (!(date && date.length == 2)) {
        return;
      }
      this.start = new Date(date[0]).getTime();
      this.end = new Date(date[1]).getTime();
    },
    // 换页
    changePage(page) {
      this.page = page;
      this.fetchData({ page });
    },
    // 查询
    handleSearch() {
      this.fetchData({ page: 1 });
    },
    fetchData({ page }) {
      const vm = this;
      const { start, end, keyword } = vm;
      Axios.post(apiMap.list, {
        ...(start && { start }),
        ...(end && { end }),
        ...(keyword && { mobile: keyword }),
        page,
        pageSize: PAGE_SIZE,
        doctorId:vm.doctorId
      })
        .then((response) => {
          handleFail(response);
          const {
            data: {
              page: { list, totalCount: total },
            },
          } = response;
          vm.allList = list;
          vm.total = total;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 打开预警详情弹窗
    open(id) {
      const vm = this;
      // 获取预警详情
      Axios.post(apiMap.detail, { id }).then((res) => {
        const {
          data: { warning },
        } = res;
        vm.earlyWarnData = warning;

        vm.dialogVisible = true;
      });
    },
    close(needRefresh) {
      if (needRefresh) {
        this.fetchData({ page: 1 });
      }
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.search-area {
  display: flex;
  justify-content: flex-start;
  .search-date {
    width: 200px;
    margin-right: 20px;
  }
  .search-input {
    width: 200px;
    margin-right: 20px;
  }
}
</style>
