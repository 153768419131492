<template>
  <Table class="lu-table" :columns="columns" :data="data">
    <template slot-scope="{ row }" slot="avatar">
      <img :src="row.avatar" class="avatar" />
    </template>

    <template slot-scope="{ row }" slot="action">
      <div v-if="!readOnly">
        <div class="lu-btn" v-if="user.isGLY()" @click="hanldeClick(row.id)">
          查看
        </div>
        <div class="lu-btn" v-else @click="hanldeClick(row.id)">
          {{ statusMsgMap[row.status]}}
        </div>
      </div>
      <div class="lu-btn" v-else @click="hanldeClick(row.id)">
        查看
      </div>
    </template>
  </Table>
</template>

<script>
/**
 * 预报警表格
 * @description 表格
 * @property {Array} data 预报警表格数据
 */
import { statusMsgMap, columns } from "./earlyData";

export default {
  name: "early-table",
  props: {
    data: {
      type: Array,
    },
    readOnly:{
     type:Boolean
    }
  },
  data() {
    return { statusMsgMap, columns };
  },
  methods: {
    hanldeClick(id) {
      this.$emit("open", id);
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.lu-btn{
  border: 0;
  background: transparent;
  font-size: 10px;
font-family: SourceHanSansCN-Normal, SourceHanSansCN;
font-weight: 400;
color: #264AFF;
}
.lu-btn:hover{

  cursor:pointer;
}
</style>
