<template>
  <el-dialog
  :visible.sync="visible"
  :before-close="handleClose"
  width='300px'
  >
  <div class="container">
    <div class="tip-div">
      正在生成中
    </div>
    <img v-if="qrCode" :src="qrCode" class="img"/>
  </div>
  </el-dialog>
</template>
<script>
export default {
  props: ["visible","doctorId"],
  components: {

  },
  data() {
    return {
      qrCode:'',
      loading:null,
    };
  },
  mounted(){


  },
  created() {
    var that=this;
    that.$watch('doctorId', function() {
      if(that.visible&&that.doctorId)
      {
        that.getQrCode();
      }
    });
    that.$watch('visible', function() {
      if(that.visible&&that.doctorId)
      {
        that.getQrCode();
      }
    });
  },
  methods: {
    handleClose: function () {
      this.$emit('close');
    },
    getQrCode(){
      var that=this;
      var scene='?';

      scene+='p=poster#b=0#d='+this.doctorId;
      that.api.userBaseQrCode({loading:false,env_version:'release',page:'pages/index/webview',check_path:false,width:'300',scene:scene}).then((response) => {
      if(response.data.code==0)
      {
        that.qrCode=response.data.data;
      }
      else{
      that.$Message.error(response.data.msg);
      }
    });
    }
  },
};
</script>
<style lang="less" scoped="scoped">
.container{
  position:relative;
}
.img{
 width:300px;
 height:300px;
 position:absolute;
 top:0px;
 left:0px;
}
.tip-div
{

width:300px;
height:300px;
background:#ffffff;
display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #000;
}
/deep/.el-dialog__header{
 padding:0;
}
/deep/.el-dialog
{
background:transparent;
}
/deep/.el-dialog__body
{
  padding:0;
}
</style>
