<template>
  <ul :class="classes" :style="styles">
    <slot></slot>
  </ul>
</template>
<script>
const prefixCls = "ivu-menu";

import eventHub from "../../../utils/event";

export default {
  name: "lu-menu",
  props: {
    mode: {
      default: "vertical",
    },
    theme: {
      default: "light",
    },
    activeName: {
      type: [String, Number],
    },
    openNames: {
      type: Array,
      default() {
        return [];
      },
    },
    accordion: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "240px",
    },
  },
  data() {
    return {
      currentActiveName: this.activeName,
      openedNames: [],
    };
  },
  computed: {
    classes() {
      let theme = this.theme;
      if (this.mode === "vertical" && this.theme === "primary") theme = "light";

      return [
        `${prefixCls}`,
        `${prefixCls}-${theme}`,
        {
          [`${prefixCls}-${this.mode}`]: this.mode,
        },
      ];
    },
    styles() {
      let style = {};

      if (this.mode === "vertical") style.width = this.width;

      return style;
    },
  },
  methods: {
    updateActiveName() {
      if (this.currentActiveName === undefined) {
        this.currentActiveName = -1;
      }
      console.log("on-update-active-name", this.currentActiveName);
      eventHub.$emit("on-update-active-name", this.currentActiveName);
    },

    handleEmitSelectEvent(name) {
      this.$emit("on-select", name);
    },
  },
  mounted() {
    this.openedNames = [...this.openNames];

    setTimeout(() => {
      this.updateActiveName();
    }, 300);

    this.$on("on-menu-item-select", (name) => {
      this.currentActiveName = name;
      this.$emit("on-select", name);
    });
  },
  watch: {
    openNames(names) {
      this.openedNames = names;
    },
    activeName(val) {
      this.currentActiveName = val;
    },
    currentActiveName() {
      this.updateActiveName();
    },
  },
};
</script>
