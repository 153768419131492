<template>
<div class="content-wrapper">
  <div class="mid-content">
    <div class="desc">正在开通中......</div>
  </div>
</div>
</template>
<script>
export default {
  name: "passModify-page",
  components: {

  },
  data() {

    return {
      checked:false,

    };
  },
  mounted(){

  },
  created() {

  },
  methods: {

  },
};
</script>
<style lang="less" scoped="scoped">
.content-wrapper
{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height:100%;
  .desc{
    font-size: 10px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }
}

</style>
