<template>
  <el-dialog :append-to-body="appendToBody?true:false" :visible="visible" title="预警信息" :beforeClose="handleClose">
    <el-form :model="form" :rules="rules" label-suffix=":" ref="ruleForm" label-width="120" label-colon>
      <el-form-item class="early-warn-text">
      <template slot="label"><div class="early-warn-label">{{earlyWarnData.warningName}}:</div></template>
        {{ earlyWarnData.description }}</el-form-item>
      <el-form-item label="报告时间"> {{ earlyWarnData.warningTime }}</el-form-item>
      <el-form-item label="处理时间"> {{ earlyWarnData.updateTime }}</el-form-item>

      <!-- 管理员 -->
      <template v-if="user.isGLY()||user.isTDGLY()||user.isZXSGLY()">
        <template>
          <el-form-item label="处理">{{ earlyWarnData.suggestion }}</el-form-item>
        </template>
      </template>

      <!-- 健康管理师 -->
      <template v-if="user.isJGS()">
        <!-- 待处理 -->
        <template v-if="isTODO">
          <el-form-item label="处理意见" prop="suggestion">
            <el-input type="textarea" placeholder="请输入内容" v-model="form.suggestion" maxlength="200" show-word-limit />
          </el-form-item>
        </template>
        <!-- 已处理 -->
        <template v-if="isDone">
          <el-form-item  label="处理意见">{{ earlyWarnData.suggestion }}</el-form-item>
        </template>
        <template v-if="isTRANS">
          <el-form-item  label="处理意见">已转交专家</el-form-item>
        </template>
      </template>
    </el-form>
    <div slot="footer">
      <Button class="early-btn" @click="handleClose">关闭</Button>
      <template v-if="isTODO">
        <Button v-if="user.isJGS()" class="early-btn" type="primary" @click="handleSubmit">提交</Button>
        <Button v-if="user.isJGS()" type="primary" @click="handleTransfer">转交专家</Button>
      </template>
    </div>
  </el-dialog>
</template>

<script>
/**
 * 预报警处理弹窗
 * @description 处理弹窗
 * @property {Boolean} visible 是否可见
 * @property {Boolean} isDone 是否处理完成
 * @property {String} role 角色
 */

import Axios from "axios";

const DONE = 2;
const TRANS = 3;
const TODO = 1;

export default {
  name: "early-dialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    earlyWarnData: {
      type: Object,
    },
    role: {
      type: String,
    },
    appendToBody:{
      type:Boolean
    }
  },
  data() {
    var checkSug = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("不能为空"));
      }
      setTimeout(() => {
        if (value.length < 3) {
          callback(new Error("不能少于 3 个字"));
        } else {
          if (value.length > 200) {
            callback(new Error("不能多于 200 个字"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    return {
      loading: false,
      form: { suggestion: "" },
      rules: {
        suggestion: [{ validator: checkSug, trigger: "change" }],
      },
    };
  },
  computed: {

    isDone: function () {
      return this.earlyWarnData.status === DONE;
    },
    isTRANS: function () {
      return this.earlyWarnData.status === TRANS;
    },
    isTODO: function () {
      return this.earlyWarnData.status === TODO;
    },
  },
  methods: {
    clear() {
      this.suggestion = "";
    },
    handleClose() {
      this.clear();
      this.$emit("close");
    },
    // 提交处理意见
    handleSubmit() {
      const vm = this;
      const {
        earlyWarnData: { id },
        form,
        loading,
      } = vm;

      if (loading) {
        return;
      }

      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          Axios.post("/api/warning/update", {
            id,
            suggestion: form.suggestion,
          })
            .then(function () {
              vm.$emit("close", true);
              vm.clear();
              vm.$Message.success("提交成功");
              vm.loading = false;
              vm.reset();
            })
            .catch(function () {
              vm.$Message.error("提交失败");
              vm.loading = false;
              vm.reset();
            });
        }
      });
    },
    reset() {
      this.form.suggestion = "";
    },
    // 转交给专家
    handleTransfer() {
      const vm = this;
      const {
        earlyWarnData: { id },
      } = vm;
      Axios.post("/api/warning/transfer", {
        id,
      })
        .then(function () {
          vm.$emit("close", true);
          vm.clear();
          vm.$Message.success("转交成功");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.early-warn-text {
  color: red !important;
}
.early-warn-label {
  color: red !important;
}
.warn-area {
  display: flex;
  justify-content: space-between;
}

.dialog-footer {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: flex-end;
}

.early-btn {
  margin-right: 10px;
}

/deep/.el-form-item__label
{
  line-height:30px;
}
/deep/.el-form-item__content
{
  line-height:30px;
}
/deep/.el-form-item
{
  margin-bottom:0px;
}
/deep/.el-dialog__body {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}
</style>
