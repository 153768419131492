String.prototype.countCharacters=function(){var str=this;var totalCount = 0;for(var i=0; i<str.length; i++){var c = str.charCodeAt(i);if ((c>=0x0001&&c<= 0x007e)||(0xff60<=c && c<=0xff9f)){totalCount++;}else{totalCount+=2;}}return totalCount;}
String.prototype.thousandsDeal=function(precision) {
    if (!precision) {
      precision=2;
    }
    var numbers = this.parseFloat(precision);
    var str =  numbers.toFixed(precision).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(/[.]?0+$/,"");
    return str;
};
String.prototype.imagePathToBase64ByFileReader=function(callback) {
  var xhr = new XMLHttpRequest();
  var url=this.absoluteUrl().toString();
  xhr.open("get", url, true);
  // 至关重要
  xhr.responseType = "blob";
  var status;
  xhr.onload = function () {
    if(xhr.callbackProperties)
    {
      status=xhr.callbackProperties.status;
      if (status == 200) {
        if (callback) {
          callback(xhr.callbackProperties.responseText);
        }
      }
    }
    else {
      status=xhr.status;
      if (status == 200) {
        //得到一个blob对象
        var blob = this.response;
        // 至关重要
        var oFileReader = new FileReader();
        oFileReader.onloadend = function (e) {
          var base64 = e.target.result;
          if (callback) {
            callback(base64.split(",")[1]);
          }
        };
        oFileReader.readAsDataURL(blob);
      }
    }
  }
  xhr.send();
};
//String
String.prototype.imagePathToBase64ByCanvas=function(callback) {
  //通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片，相比 createElement() 创建 <img> 省去了 append()，也就避免了文档冗余和污染
  var img = new Image();
  var dataUrl='';
  img.setAttribute('crossOrigin', 'anonymous');
  img.src=this;
  img.onload=function(){ //要先确保图片完整获取到，这是个异步事件
      var canvas = document.createElement("canvas"), //创建canvas元素
          width=img.width, //确保canvas的尺寸和图片一样
          height=img.height;
      canvas.width=width;
      canvas.height=height;
      canvas.getContext("2d").drawImage(img,0,0,width,height); //将图片绘制到canvas中
      dataUrl=canvas.toDataURL('image/jpeg'); //转换图片为dataUrl
      dataUrl=dataUrl.split(",")[1];
      callback?callback(dataUrl):null; //调用回调函数
  };
};
String.prototype.parseFloat=function() {
    var numbers = parseFloat(this);
    numbers=isNaN(numbers)?0:numbers;
    return numbers;
};
String.prototype.splitParam=function(sp1,sp2) {
    var param={};
    var vars = this.split(sp1);
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split(sp2);
        param[pair[0]]=pair[1];
    }
    return param;
};
String.prototype.formatNum=function(){
	var num = this;
	if(num == null){
		return '0';
	}
	num = parseFloat(num);
	if(parseInt(num / 1e4) == 0){
		return num.toFixed(2);
	}else if(parseInt(num / 1e8) == 0){
		num = num / 1e4;
		return num.toFixed(2) + '万';
	}else if(parseInt(num / 1e12) == 0){
		num = num / 1e8;
		return num.toFixed(2) + '亿';
	}else if(parseInt(num / 1e16) == 0){
		num = num / 1e12;
		return num.toFixed(2) + '万亿';
	}
	return num.toFixed(2);
}
String.prototype.absoluteUrl=function() {
  var that=this;
  var a = document.createElement('a');
  a.href = this;
  that = a.href;
  return that;
};
String.prototype.dateWithFormat=function(fmt) {
  var that=this;
  var d;
  if (fmt=='yyyymmdd') {
   that=that.slice(0, 4) + '/' + that.slice(4,6)+ '/' + that.slice(6);
   d= new Date(that);
  }
  return d;
};
String.prototype.addZeroTotalNum=function(total) {
    var l=this.length;
    var str=this;
    for (var i = l; i < total; i++) {
      str='0'+str;
    }
    return str;
};
String.prototype.appendString=function(string) {
    var str=this+'';
    str+=string;
    return str;
};
String.prototype.iphoneDateFormat=function() {
    return this.replace(/-/g, "/");
};
String.prototype.toDate=function(pattern,replace) {
    var formatedDate = this.replace(pattern,replace);
    return new Date(formatedDate);
};
String.prototype.compareVersion=function(v2) {
  var v1 = this.split('.')
  v2 = v2.split('.')
  const len = Math.max(v1.length, v2.length)

  while (v1.length < len) {
    v1.push('0')
  }
  while (v2.length < len) {
    v2.push('0')
  }

  for (var i = 0; i < len; i++) {
    const num1 = parseInt(v1[i])
    const num2 = parseInt(v2[i])

    if (num1 > num2) {
      return 1
    } else if (num1 < num2) {
      return -1
    }
  }
  return 0
}
