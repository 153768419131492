<template>
  <div>
    <Layout>
      <Card>
        <p slot="title">健康档案</p>
        
        <div class="search-box">
          <Button v-if="user.roleType==user.doctorTypeE.GLY" type="primary" @click="revamp = true" class="add-btn"
            >+ 新建档案</Button
          >
          <Input
            v-model="input"
            placeholder="按手机号查询"
            search
            enter-button="搜索"
            :style="{ width: '250px' }"
            @on-search="jkdaInquire"
          />
          <div class="search-tag-wrapper">
          <div class="search-tag-label">我的客户</div>
          <div class="search-tag-value">{{statisticsData.count}}</div>
          </div>

          <div class="search-tag-wrapper">
            <div class="search-tag-label">本月新增客户</div>
            <div class="search-tag-value">{{statisticsData.localMonthCount}}</div>
          </div>
        </div>
        
       <div class="content">
        <el-table :data="tableData" @row-click="showUserBasicInfo">
          <el-table-column prop="id" align="center" minWidth="120" label="用户编号"></el-table-column>
          <el-table-column prop="name" align="center" minWidth="150" label="姓名"></el-table-column>
          <el-table-column prop="mobile" align="center" minWidth="150" label="手机号"></el-table-column>
          <el-table-column prop="gender" align="center" minWidth="120" label="性别"></el-table-column>
          <el-table-column prop="birth" align="center" minWidth="150" label="出生日期"></el-table-column>
          <el-table-column prop="memberType" align="center" minWidth="150" label="会员等级">
            <template slot-scope="scope">
              {{user.memberTypeMap[scope.row.memberType]}}
            </template>
          </el-table-column>
          <el-table-column prop="nickName" align="center" minWidth="150" label="微信昵称"></el-table-column>
          <el-table-column prop="avatar" align="center" minWidth="120" label="微信头像">
            <template slot-scope="scope">
              <img :src="scope.row.avatar" />
            </template>
          </el-table-column>
          <template v-for="(team, index) in teams">
            <el-table-column :key="'team'+index" align="center" minWidth="140" :label="team.teamlabel+'团队'">
              <template slot-scope="scope">
                {{scope.row[team.teamKey]}}
              </template>
            </el-table-column>
            <el-table-column :key="'member'+index" align="center"  minWidth="150"  :label="team.teamlabel">
              <template slot-scope="scope">
                {{scope.row[team.memberKey]}}
              </template>
            </el-table-column>
            <el-table-column v-if="team.bindTime" align="center" :key="'time'+index"  prop="bindTime" minWidth="160" label="绑定时间"></el-table-column>
          </template>


        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="pagenum"
          @current-change="handleCurrentChange"
          :total="total"
        ></el-pagination>
        
      </div>
      </Card>
      
      <el-dialog append-to-body  title="新建档案" :visible.sync="revamp" minWidth="480px">
          <el-form style="padding:30px 20px;" :model="form" :rules="rules" ref="form" class="newkhdn">
            <el-form-item
              label="姓名："
              prop="name"
              :label-width="formLabelWidth"
            >
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item
              label="性别："
              prop="gender"
              :label-width="formLabelWidth"
            >
              <el-select v-model="form.gender">
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="出生年月："
              prop="birth"
              :label-width="formLabelWidth"
            >
              <el-date-picker
                v-model="form.birth"
                width="100%"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="手机号："
              prop="mobile"
              :label-width="formLabelWidth"
            >
              <el-input v-model="form.mobile" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <Button @click="revamp = false">取 消</Button>
            <Button class="okButton" type="primary" @click="newlykhda('form')">确 定</Button>
          </div>
        </el-dialog>
        <userBasicInfo :visible="userBasicInfo.visible" pathPre="/healthRecords" @close="handleUserBasicInfoClose" :userId="userBasicInfo.userId"/>

    </Layout>
  </div>
</template>
<script>
import { api } from "@/request/api";
import userBasicInfo from "./userBasicInfo.vue";

export default {
  components: {
    userBasicInfo
  },
  data() {
    return {
      total: 10,
      pagenum: 1,
      pagesize: 10,
      input: "",
      tableData: [],
      revamp: false,
      formLabelWidth: "100px",
      statisticsData:{},
      teams:[],
      userBasicInfo:{
        userId:'',
        visible:false
      },
      form: {
        name: "",
        gender: "",
        birth: "",
        mobile: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        birth: [
          { required: true, message: "请选择出生日期", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "change" },
        ],
      },
    };
  },
  mounted(){

    this.jkdaInquire();
    this.statistc();
    if(this.user.isZXS())
    {

      this.teams=[{
        teamlabel:this.user.doctorTypeE.JGS_S,
        teamKey:'manageTeamName',
        memberKey:'manageDoctorName',
        bindTime:true
      }];
    }
    else if(this.user.isJGS()){
      this.teams=[{
        teamlabel:this.user.doctorTypeE.ZXS_S,
        teamKey:'answerTeamName',
        memberKey:'answerDoctorName',
        bindTime:true
      }];

    }
    else{
      this.teams=[{
        teamlabel:this.user.doctorTypeE.JGS_S,
        teamKey:'manageTeamName',
        memberKey:'manageDoctorName',
        bindTime:false
      },{
        teamlabel:this.user.doctorTypeE.ZXS_S,
        teamKey:'answerTeamName',
        memberKey:'answerDoctorName',
        bindTime:false
      }];
    }

    console.log(this.teams);
  },
  created() {


  },
  methods: {

    showUserBasicInfo(row) {
      this.userBasicInfo.userId= row.id;
      this.userBasicInfo.visible=true;

    },
    handleUserBasicInfoClose(){
      this.userBasicInfo.userId= null;
      this.userBasicInfo.visible=false;

    },
    statistc(){
      var that=this;
      api.healthFileStatistic().then((response) => {
        response = response.data;
        if (response.code == 0) {
          that.statisticsData = response.data;
        }
        else{
          that.$message.error(response.msg);
        }
      });
    },
    jkdaInquire() {
      //列表
      var that = this;
      var parameter = {
        mobile: that.input,
        page: that.pagenum,
        pageSize: that.pagesize,
      };
      api.jkdaInquire(parameter).then((response) => {
        response = response.data;
        if (response.code == 0) {
          var page=response.page?response.page:response.data;
          that.total = page.totalCount;
          that.tableData = page.list;
        }
      });
    },
    handleCurrentChange(cpage) {
      //分页
      this.pagenum = cpage;
      this.jkdaInquire();
    },
    newlykhda(formName) {
      var that = this;
      var parameter = that.form;
      parameter.birth = Date.parse(parameter.birth);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.jkdaadd(parameter).then((response) => {
            response = response.data;
            if (response.code == 0) {
              that.revamp = false;
              that.jkdaInquire();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.okButton
{
  margin-left:10px;
}
.search-box {
  display: flex;
  .add-btn {
    margin-right: 10px;
  }
}
.content img {
  width: 40px;
}
.newkhdn {
  .el-date-editor.el-input {
    width: 100%;
  }
  .el-select {
    display: block;
  }

}
.search-tag-wrapper
{
  width: 134px;
  height: 31px;
  background: #D5E4FF;
  border-radius: 3px;
  opacity: 0.58;
  display:flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  .search-tag-label
  {
    font-size: 11px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  .search-tag-value
  {
    font-size: 18px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 500;
    color: #264AFF;
  }
}
</style>
