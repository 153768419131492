<template>
  <el-dialog
  title='用户换绑'
  fullscreen
  :visible.sync="visible"
  :before-close="handleClose"
  >
  <div class="container">
     <div class="header">
       <div class="header-left">

        <div class="header-left-desc">重要须知：<br>换绑行为仅限发生在当前团队人员中，一旦换绑，该用户将完全解除之前的绑定关系，请谨慎思考以后再进行换绑操作</div>

       </div>
       <div class="header-mid">
         <div class="header-mid-line"/>
       </div>
       <div class="header-right">

           <div v-if="doctor" class="card">
              <img class="card-image" :src="doctor.avatar"/>
              <div class="card-title">{{doctor.name}}</div>

           </div>
       </div>
     </div>
     <div class="mid-content">
        <div class="mid-content-bar">
         <div class="mid-content-bar-select">已选:{{selectNum}}/{{users.length}}</div>
         <div class="mid-content-bar-selectbox"><el-checkbox @change="allSelectChange" v-model="selectAllChecked">全选</el-checkbox></div>
         <div class="mid-content-bar-space"/>
         <div v-if="showError" class="mid-content-bar-label">请勾选用户以后再点击换绑</div>
         <Button @click="doConfirm" class="bind-btn">换绑</Button>
        </div>
        <div class="mid-content-list">
          <div v-for="(user, index) in users" :key="index" class="card">
             <div class="mask" @click="userClick(user)"/>
             <div class="card-selectbox"><el-checkbox @change="checked=>userSelectChange(user)" v-model="user.checked"></el-checkbox></div>
             <img class="card-image" :src="user.avatar"/>
             <div class="card-title">{{user.name}}</div>

          </div>
        </div>
     </div>
  </div>
  <changeBindConfirmDialog :visible="confirm.visible" @close="handleConfirmClose" @confirm="handleConfirm"/>
  <sucessDialog content="换绑成功" dialogTitle="用户换绑" appendToBody="true" :dialogVisible="sucessDialogVisible" @confirm="sucessDialogConfirm" @close="sucessDialogClose"/>
  </el-dialog>
</template>
<script>
import changeBindConfirmDialog from "./changeBindConfirmDialog.vue";
import sucessDialog from "../sucessDialog.vue";
export default {
  props: ["visible","doctor"],
  components: {
   changeBindConfirmDialog,
   sucessDialog
  },
  data() {
    return {
      selectAllChecked:false,
      sucessDialogVisible:false,
      showError:false,
      selectNum:0,
      users:[],
      selectUsers:[],
      confirm:{
        visible:false
      }
    };
  },
  created() {
    var that=this;
    that.$watch('visible', function() {
     if(that.visible)
     {
        that.doUserSearch();
     }
    });
  },
  methods: {
    init(){
      this.selectAllChecked=false;
      this.showError=false;
      this.selectNum=0;
      this.users=[];
      this.selectUsers=[];
    },
    handleClose: function () {
      this.init();
      this.$emit('close');
    },
    userClick(user){
      console.log(user);
      user.checked=!user.checked;
      this.userSelectChange();
    },
    userSelectChange(){
      var all=true;
      var num=0;
      for(var i=0;i<this.users.length;i++){
        var user=this.users[i];
        if(!user.checked){
        all=false;
        }
        else{
        num++;
        }
      }
      this.selectAllChecked=all;
      this.selectNum=num;
      if(this.selectNum>0)this.showError=false;
    },
    allSelectChange(val){
      var num=0;
      for(var i=0;i<this.users.length;i++){
        var user=this.users[i];
        user.checked=val;
        if(user.checked)num++;
      }
      this.selectNum=num;
      if(this.selectNum>0)this.showError=false;
    },
    doUserSearch(){
      var that=this;
      var parameter = {
        doctorId: that.doctor.id,
      };
      that.api.clientSearch(parameter).then((response) => {
        if (response.data.code == 0) {
          var users=response.data.data;
          if(!users)users=[];
          that.users=users;

        } else {
          that.$message({
            type: "success",
            message: response.data.msg,
          });
        }
      });
    },
    sucessDialogConfirm(){
     var that=this;
     that.sucessDialogVisible=false;
    },
    sucessDialogClose(){
     var that=this;
     that.sucessDialogVisible=false;
    },
    doConfirm(){
      var that=this;
      if(that.selectNum<=0){
        that.showError=true;
        return;
      }
      var users=[];
      for(var i=0;i<that.users.length;i++){
        var user=that.users[i];
        if(user.checked)users.push(user);
      }
      that.selectUsers=users;
      that.confirm.visible=true;
    },
    handleConfirmClose(){
      this.confirm.visible=false;
    },
    handleConfirm(doctor){
      var that=this;
      var userIds='';
      for(var i=0;i<this.selectUsers.length;i++){
        var user=this.selectUsers[i];
        if(userIds.length>0)userIds+=',';
        userIds+=user.id;
      }
      var parameter = {
        userId:userIds,
        sourceDoctorId:that.doctor.id,
        destDoctorId: doctor.id,
      };
      that.api.clientBind(parameter).then((response) => {
        if (response.data.code == 0) {
          that.confirm.visible=false;
          that.sucessDialogVisible=true;
          that.handleClose();
        } else {
          that.$message({
            type: "success",
            message: response.data.msg,
          });
        }
      });

    }
  },
};
</script>
<style lang="less" scoped="scoped">
.container{
  display: grid;
  background:#fff;
  padding-left: 20px;
  padding-right: 20px;
  grid-template-rows: auto 1fr;
  .header{
    display: grid;
    grid-template-columns:1fr 2px 1fr;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E3E3E3;
  }
  .header-left
  {
  display: flex;
      flex-direction: column;
      padding-right:50px;
      justify-content: center;
  }

  .header-left-desc
  {

    margin-bottom: 20px;
  font-size: 11px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  }
  .header-mid
  {
  display: flex;
  align-items: center;
  }
  .header-mid-line{
  width: 1px;
height: 130px;
background: #e3e3e3;
  }
  .header-right{
  padding-left:50px;
  display: flex;
  align-items: center;
  }
  .card
  {
  display: flex;
  position: relative;
  width: 166px;
  height: 75px;
  background: #F6F6F8;
  border-radius: 3px;
  align-items: center;
    padding: 10px;

  }
  .card-image
  {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  }
  .card-title
  {
  font-size: 11px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 8px;
  }
  .card-icon
  {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
  }
  .mask{
  position: absolute;
  left: 0;
  top: 0;
  width:100%;
  height:100%;
  }
  .mid-content{
  display: flex;
      flex-direction: column;
      padding-top: 10px;
  }
  .mid-content-bar{
  display: flex;
      align-items: center;
  }
  .mid-content-list{
  margin-top: 10px;
display: flex;
flex-wrap: wrap;
gap: 10px;

  }
  .mid-content-bar-select{
  margin-right: 38px;
  }
  .mid-content-bar-selectbox{

  }
  .mid-content-bar-label{
  font-size: 10px;
font-family: SourceHanSansCN-Normal, SourceHanSansCN;
font-weight: 400;
color: #D60000;
  }
  .mid-content-bar-space{
  flex:1;
  }
  .bind-btn {
    margin-left: 10px;
    background: #185AD6;
    border-radius: 2px;
    font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  }
  .card-selectbox{
  position: absolute;
  left: 0;
  top: 0;
  }
}
/deep/.el-dialog__body{
padding:10px;
}
/deep/.el-dialog{
display: grid;
grid-template-rows: auto 1fr;
}
</style>
