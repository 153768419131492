(function(doc, win) {
    var docEl = doc.documentElement,
    // 判断window中是否有orientationchange方法
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function() {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            //设置基础html的fontsize
            docEl.style.fontSize = 12.8 * (clientWidth / 1280) + 'px';
            console.log(docEl.style.fontSize, 'docEl.style.fontSize');
        };
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);
