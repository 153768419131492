<template>
  <el-dialog
    class="xgtz"
    title="关键体征数据"
    :visible.sync="visible"
    width="965px"
    :before-close="xgreduce"
    @opened="exclusive(num)"
  >
    <ul class="header">
      <li :class="{ on: num == 1 }" @click="exclusive(1)">血压（mmHg）</li>
      <li :class="{ on: num == 2 }" @click="exclusive(2)">
        血糖浓度（mmol/L）
      </li>
      <li :class="{ on: num == 3 }" @click="exclusive(3)">BMI</li>
      <li :class="{ on: num == 4 }" @click="exclusive(4)">腰臀比</li>
    </ul>
    <ul class="nvetime">
      <li :class="{ on: timenum == 1 }" @click="excltime(1)">近一周</li>
      <li :class="{ on: timenum == 2 }" @click="excltime(2)">近半月</li>
    </ul>
    <div
      id="myChart"
      :style="{ width: '925px', height: '380px', border: '1px solid #E3E3E3' }"
    ></div>
  </el-dialog>
</template>

<script>

export default {
  name: "examine-dialog",
  props: ['visible','userId'],
  data() {
    return {
      basics: [],
      num: 1,
      timenum: 1,
    };
  },
  mounted() {
    var that=this;
    that.$watch('userId', function() {
          if(that.userId)
          {
            that.exclusive(that.num);
          }
          else{
            that.basics=[];
          }
        });
  },
  methods: {
    exclusive(itme) {
      this.num = itme;
      this.database();
    },
    excltime(itme) {
      this.timenum = itme;
      this.drawLine();
    },
    database() {
      var that = this;
      var parameter = {
        userId: that.userId,
      };
      if (that.num == 1) {
        that.blood(parameter);
      } else if (that.num == 2) {
        that.bloodSugar(parameter);
      } else if (that.num == 3) {
        that.bmi(parameter);
      } else if (that.num == 4) {
        that.waistHip(parameter);
      }
    },
    blood(parameter) {
      var that = this;
      that.api.jkdabloodPressure(parameter).then((response) => {
        if (response.data.code == 0) {
          that.basics = response.data.data;
          that.drawLine();
        }
      });
    },
    bloodSugar(parameter) {
      var that = this;
      that.api.jkdabloodSugar(parameter).then((response) => {
        if (response.data.code == 0) {
          that.basics = response.data.data;
          that.drawLine();
        }
      });
    },
    bmi(parameter) {
      var that = this;
      that.api.jkdabmi(parameter).then((response) => {
        if (response.data.code == 0) {
          that.basics = response.data.data;
          that.drawLine();
        }
      });
    },
    waistHip(parameter) {
      var that = this;
      that.api.jkdawaistHip(parameter).then((response) => {
        if (response.data.code == 0) {
          that.basics = response.data.data;
          that.drawLine();
        }
      });
    },
    xgreduce() {
      var outerVisible = false;
      this.num=1;
      this.$emit("xgreduce", outerVisible);
    },
    dedupe(array) {
      return Array.from(new Set(array));
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      var option;
      var dated = [];
      var xAxisdata = [];
      var yAxisdata = [];
      var yAxisdata1 = [];
      var yAxisdata2 = [];
      var lengthnum = 0;
      if (this.basics.length > 0) {
        if (this.num == 2) {
          if (this.timenum == 1) {
            for (var i = 0; i < this.basics.length; i++) {
              dated.push(this.basics[i].date);
            }
            var xdate = this.dedupe(dated); //[1,2,3]
            if (xdate.length > 7) {
              lengthnum = 7;
            } else {
              lengthnum = xdate.length;
            }
            for (i = 0; i < lengthnum; i++) {
              xAxisdata.push(xdate[i]);
              this.basics.forEach((element) => {
                if (element.date == xdate[i]) {
                  yAxisdata1.unshift([i, element.value1, element.value2]);
                }
              });
            }
          } else if (this.timenum == 2) {
            if (xdate.length > 14) {
              lengthnum = 14;
            } else {
              lengthnum = xdate.length;
            }
            for (var j = 0; j < lengthnum; j++) {
              xAxisdata.push(xdate[j]);
              this.basics.forEach((element) => {
                if (element.date == xdate[j]) {
                  yAxisdata1.unshift([j, element.value1, element.value2]);
                }
              });
            }
          }
        } else {
          if (this.timenum == 1) {
            if (this.basics.length > 7) {
              lengthnum = 7;
            } else {
              lengthnum = this.basics.length;
            }
            for (i = 0; i < lengthnum; i++) {
              xAxisdata.unshift(this.basics[this.basics.length - i - 1].date);
              yAxisdata1.unshift(
                this.basics[this.basics.length - i - 1].value1
              );
              yAxisdata2.unshift(
                this.basics[this.basics.length - i - 1].value2
              );
            }
          } else if (this.timenum == 2) {
            if (this.basics.length > 14) {
              lengthnum = 14;
            } else {
              lengthnum = this.basics.length;
            }
            for (j = 0; j < lengthnum; j++) {
              xAxisdata.unshift(this.basics[this.basics.length - j - 1].date);
              yAxisdata1.unshift(
                this.basics[this.basics.length - j - 1].value1
              );
              yAxisdata2.unshift(
                this.basics[this.basics.length - j - 1].value2
              );
            }
          }
        }
      }
      if (this.num == 1) {
        yAxisdata.push(
          {
            data: yAxisdata1,
            type: "line",
          },
          {
            data: yAxisdata2,
            type: "line",
          }
        );
      } else if (this.num == 2) {
        yAxisdata.push({
          symbolSize: 20,
          data: yAxisdata1,
          type: "scatter",
        });
      } else if (this.num == 3) {
        yAxisdata.push({
          data: yAxisdata1,
          type: "line",
        });
      } else if (this.num == 4) {
        yAxisdata.push({
          data: yAxisdata1,
          type: "line",
        });
      }

      option = {
        xAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            inside: true,
          },
          data: xAxisdata,
        },
        yAxis: {
          type: "value",
        },
        grid: {
          left: "50px",
          right: "20px",
          top: "70px",
          bottom: "32px",
        },
        series: yAxisdata,
      };
      if (this.num == 2) {
        option.tooltip = {
          formatter: function (params) {
            //标签内容
            return params.data[2] + params.data[1];
          },
        };
        option.yAxis = {
          type: "value",
          min: 0,
          max: 18,
        };
      }
      // 绘制图表
      var myChart = this.$echarts.init(document.getElementById("myChart"));
      option && myChart.setOption(option, true);
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.xgtz {
  .header {
    display: flex;
    margin-bottom: 24px;
    li {
      width: 230px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #f1f1f2;
      font-weight: bold;
      border: 1px solid #e3e3e3;
    }
    .on {
      background: #d5e4ff;
      border: 1px solid #185ad6;
      color: #185ad6;
    }
  }
  .nvetime {
    display: flex;
    position: absolute;
    left: 20px;
    top: 143px;
    z-index: 100;
    li {
      width: 72px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-weight: bold;
      border: 1px solid #e3e3e3;
      border-radius: 2px 2px 0px 0px;
    }
    .on {
      border: 1px solid transparent;
      color: #185ad6;
    }
  }
  .el-dialog__body {
    padding:30px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
}
</style>
