<template>
  <el-dialog
  :visible.sync="visible"
  title="消息管理"
  fullscreen
  :before-close="handleClose"
  >
   <MessageM :doctorId="doctorId" :hideTitle="true" :readOnly="readOnly"/>
  </el-dialog>
</template>
<script>
import MessageM from "../MessageM.vue";
export default {
  props: ["visible","doctorId","readOnly"],
  components: {
    MessageM
  },
  data() {
    return {


    };
  },
  mounted(){
    

  },
  created() {

  },
  methods: {
    handleClose: function () {
      this.$emit('close');
    }
  },
};
</script>
<style lang="less" scoped="scoped">
/deep/.el-dialog__body
{
background:#f5f7f9;
}
</style>
