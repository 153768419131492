<template>
  <div>
    <Layout>
      <el-header>
        <p class="bigTitle">平台管理</p>
        <Button
          class="titleBtn"
          type="primary"
          icon="el-icon-plus"
          @click="dialogFormVisible = true"
          >上传首页轮播图</Button
        >
      </el-header>
      <el-main>
        <div class="mainBox">
          <ul>
            <li v-for="item in imgList" :key="item.id">
              <div class="imgBox">
                <i class="el-icon-close"></i>
                <img :src="item.url" alt="" v-if="item.url" />
              </div>
              <span class="editBtn" @click="dialogVisible = true"
                >编辑链接</span
              >
            </li>
          </ul>
        </div>
      </el-main>
    </Layout>
    <el-dialog
      title="编辑链接"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form>
        <el-form-item label="链接地址：" label-width="100px">
          <el-input autocomplete="off" inline></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <Button @click="dialogVisible = false">取 消</Button>
        <Button type="primary" @click="dialogVisible = false">确 定</Button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      imgList: [
        {
          url: "",
          id: 1,
        },
        {
          url: "",
          id: 2,
        },
        {
          url: "",
          id: 3,
        },
        {
          url: "",
          id: 4,
        },
        {
          url: "",
          id: 5,
        },
        {
          url: "",
          id: 6,
        },
        {
          url: "",
          id: 7,
        },
        {
          url: "",
          id: 8,
        },
      ],
    };
  },
  methods: {
    handleClose() {},
  },
};
</script>
<style lang="less" scoped="scoped">
.mainBox ul {
  width: 100%;
  display: flex;
  display: -webkit-flex; /* Safari */
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-left: 0;
  li {
    margin: 0 18px 20px;
  }
}
.imgBox {
  width: 210px;
  height: 270px;
  background: #d8d8d8;
  position: relative;
  cursor: pointer;

  i {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    transition: 0.3s;
  }
}
.imgBox:hover i {
  opacity: 1;
}
.el-main {
  padding: 4px 0 0;
}
.editBtn {
  width: 88px;
  height: 32px;
  background: #185ad6;
  border-radius: 2px;
  display: block;
  margin: 20px auto;
  text-align: center;
  line-height: 32px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
</style>
>
