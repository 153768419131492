<template>
  <div>
    <el-collapse v-model="activeNames" class="elementary">
      <el-collapse-item
        v-for="(item, index) in basics.data"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <p class="eleHead">
            人体成分
            <span class="hdate">评测时间：{{ item.date }}</span>
          </p>
        </template>
        <div class="elementaryCon">
          <ul>
            <li v-for="(citem, index) in item.data" :key="index">
              {{ citem.title + "：" + citem.value }}
            </li>
          </ul>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      activeNames: 0,
      basics: "",
      userId:"",
    };
  },
  created() {
    this.userId = this.$route.query.userId;
    this.compocsition();
  },
  methods: {
    compocsition() {
      var that = this;
      var parameter = {
        userId: that.userId,
      };
      api.jkdabodycompocsition(parameter).then((response) => {
        if (response.data.code == 0) {
          that.basics = response.data.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  .eleHead {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
    font-weight: bold;
    .hdate {
      color: #185ad6;
    }
    .hunfold {
      float: right;
    }
  }
  .elementaryCon ul {
    display: flex;
    padding-bottom: 20px;
  }

  li {
    width: 340px;
    padding: 20px;
    font-size: 14px;
    border-right: 1px solid #e3e3e3;
    margin-bottom: 20px;
  }
}
</style>
