<template>

    <Layout>
      <Card>
        <p slot="title">团队成员管理</p>
        <div class="search-box">
          <Input
            v-model="input"
            placeholder="按手机号查询"
            search
            enter-button="搜索"
            :style="{ width: '250px' }"
            @on-search="loadData"
          />

        </div>
        <div class="content">
        <el-table :data="tableData" @row-click="showBasicInfo">
          <el-table-column prop="id" align="center" minWidth="120" :label="team.label+'ID'"></el-table-column>
          <el-table-column prop="name" align="center" minWidth="120"  :label="team.label+'姓名'"></el-table-column>
          <el-table-column prop="mobile" align="center" minWidth="150"  label="手机号"></el-table-column>
          <el-table-column prop="sex" align="center" minWidth="120"  label="性别">
            <template slot-scope="scope">
              {{user.sexMap[scope.row.sex]}}
            </template>
          </el-table-column>
          <el-table-column prop="birth" align="center" minWidth="120"  label="出生日期"></el-table-column>
          <el-table-column prop="avatar" align="center" minWidth="120"  label="头像">
            <template slot-scope="scope">
              <img class="avatar" :src="scope.row.avatar" />
            </template>
          </el-table-column>

          <el-table-column prop="status" align="center" minWidth="120" label="在职状态">
            <template slot-scope="{ row }">
            {{user.workStatusMap[row.workStatus-1]}}
            <Button style="margin-left:5px;" v-if="row.workStatus==1" @click.native.stop="doDismiss(row)">{{user.workStatusMap[1]}}</Button>
            </template>
          </el-table-column>
          <el-table-column prop="avatar" align="center" minWidth="200" label="管理用户">
            <template slot-scope="scope">
               <Button @click.native.stop="clientListShow(scope.row)">{{scope.row.userCount}}</Button>
               <Button style="margin-left:4px;" @click.native.stop="changeBindShow(scope.row)">用户换绑</Button>

            </template>
          </el-table-column>

        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="pagenum"
          @current-change="handleCurrentChange"
          :total="total"
        ></el-pagination>
      </div>
      </Card>
      
      <clientListDialog @close="clientListDialogClose" :visible="clientList.visible" :doctorId="clientList.doctorId"/>
      <changeBindDialog @close="changeBindDialogClose" :doctor="changeDoctor" :visible="changeBind.visible"/>
      <dismissDialog :teamLabel="team.label" @close="dismissDialogClose" @confirm="dismissDialogConfirm" :visible="dismiss.visible"/>
      <dismissConfirmDialog @close="dismissDialogConfirmClose" :userId="dismiss.userId" @confirm="dismissDialogConfirmConfirm" :visible="dismissConfirm.visible"/>
      <basicInfo @close="basicInfoClose" :editAble="false" :userId="basicInfo.userId" :visible="basicInfo.visible"/>
      <messageDialog @close="messageDialogClose" :visible="message.visible" :doctorId="message.doctorId"/>
      
    </Layout>
</template>
<script>

import clientListDialog from "./clientListDialog.vue";
import changeBindDialog from "./changeBindDialog.vue";
import dismissDialog from "./dismissDialog.vue";
import dismissConfirmDialog from "./dismissConfirmDialog.vue";
import basicInfo from "../userInfo/basicInfo.vue";
import messageDialog from "./messageDialog.vue";

export default {

  components: {
   clientListDialog,
   changeBindDialog,
   dismissDialog,
   dismissConfirmDialog,
   basicInfo,
   messageDialog,

  },
  data() {
    return {
      total: 10,
      pagenum: 1,
      status,
      pagesize: 10,
      input: "",
      tableData: [],
      actionColumns:[],
      changeDoctor:null,
      team:{
       label:''
      },
      clientList:{
        visible:false,
        doctorId:null,
      },
      changeBind:{
       visible:false,
      },
      dismiss:{
       visible:false,
       userId:null
      },
      dismissConfirm:{
       visible:false,
      },
      basicInfo:{
       visible:false,
       userId:null,
      },
      message:{
        visible:false,
        doctorId:null,
      },

    };
  },
  mounted(){

    if(this.user.isTDGLY())
    {
      this.team.label=this.user.doctorTypeE.JGS_S;
    }
    else if(this.user.isZXSGLY())
    {
      this.team.label=this.user.doctorTypeE.ZXS_S;
    }
  },
  created() {

    var that=this;
    that.init();
    that.loadData();

  },
  methods: {
    init(){

    },

    handleClose: function () {
      this.$emit('close');
    },
    messageDialogClose(){
      this.message.visible=false;
      this.message.doctorId=null;
    },
    showBasicInfo(row){
      this.basicInfo.visible=true;
      this.basicInfo.userId=row.id;
    },
    basicInfoClose(){
      this.basicInfo.userId=null;
      this.basicInfo.visible=false;
    },
    changeBindShow(row){
      this.changeDoctor=row;
      this.changeBind.visible=true;
    },
    clientListShow(row){
      this.clientList.doctorId=row.id;
      this.clientList.visible=true;
    },
    clientListDialogClose(){
      this.clientList.doctorId=null;
      this.clientList.visible=false;
    },
    changeBindDialogClose(){
      this.changeBind.visible=false;
    },
    doDismiss(row){
      this.dismiss.userId=row.id;
      this.dismiss.visible=true;
    },
    dismissDialogConfirm(){
      this.dismiss.visible=false;
      this.dismissConfirm.visible=true;
    },
    dismissDialogClose(){
      this.dismiss.visible=false;
    },
    dismissDialogConfirmClose(){
      this.dismissConfirm.visible=false;
    },
    dismissDialogConfirmConfirm(){
      this.loadData();
      this.dismissConfirm.visible=false;
    },
    loadData() {
      //列表
      var that = this;
      var parameter = {
        mobile: that.input,
        page: that.pagenum,
        pageSize: that.pagesize
      };
      that.api.doctorQueryList(parameter).then((response) => {
        response = response.data;
        if (response.code == 0) {
          var page=response.page?response.page:response.data;
          that.total = page.totalCount;
          that.tableData = page.list;
        }
      });
    },
    handleCurrentChange(cpage) {
      //分页
      this.pagenum = cpage;
      this.loadData();
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.content img {
  width: 40px;
}
.search-box {
  display: flex;

}
/deep/.ivu-btn
{
  padding:5px;
}
/deep/.el-table td.el-table__cell div
{
  display: flex;
    align-items: center;
    justify-content:center;
}

/deep/.el-dialog__body
{
background:#f5f7f9;

}
/deep/.ivu-input-search
{
background: #185AD6 !important;
border-radius: 2px;
}

</style>
