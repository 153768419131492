<template>
  <div class="el-main-tab">
    <el-collapse v-model="activeNames" class="elementary">
      <el-collapse-item
        v-for="(item, index) in basics"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <p class="eleHead">
            <span class="hdate">{{ item.generateTime }}</span>
          </p>
        </template>
        <div class="elementaryCon">
            <div  class="card">
              <div class="card-title">
                 生化指标
              </div>
              <div v-for="(biochemistry, index) in item.biochemistryIndexStatusNames"
              :key="index"
              :name="index" class="card-table1">
                <div class="card-subtitle">
                   {{biochemistry.title}}
                </div>
                <div class="card-table-header">
                  <div class="card-table-left card-title-bold">项目</div>
                  <div class="card-table-mid card-title-bold"></div>
                  <div class="card-table-right card-title-bold">参考值</div>
                </div>
                <div v-for="(item, ind) in biochemistry.biochemistryIndexInfos"
                :key="ind"
                :name="ind" class="card-table-item">
                   <div class="card-table-left">{{item.name}}</div>
                   <div class="card-table-mid">{{item.value}}</div>
                   <div class="card-table-right">{{item.referenceRange}}</div>
                </div>
              </div>
            </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      activeNames: 0,
      basics: "",
      userId:"",
    };
  },
  created() {
    this.userId = this.$route.query.userId;
    this.compocsition();
  },
  methods: {
    compocsition() {
      var that = this;
      var parameter = {
        userId: that.userId,
      };
      api.jkdabiochemistryIndex(parameter).then((response) => {
      console.log(22);
        if (response.data.code == 0) {
          if(response.data.data)
          {
            that.basics = response.data.data;
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .eleHead {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
    font-weight: bold;
    .hdate {
      color: #185ad6;
      margin-left:0px;
    }
    .hunfold {
      float: right;
    }
  }
  .elementaryCon
  {
      .card{
       padding-left: 20px;
       padding-right: 20px;

      }
      .card-table1{
        margin-top:10px;
        margin-bottom:10px;
      }
      .card-title{
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      }
      .card-subtitle
      {
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      }
      .card-table-header
      {
    display: flex;
    padding-left: 10px;
    margin-top: 10px;
      }
      .card-table-left
      {
      font-size: 14px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    width: 200px;
    flex: 0 0 auto;
    text-align: left;
      }
      .card-table-mid
      {
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      width: 200px;
      flex: 0 0 auto;
      text-align: center;
      }
      .card-table-right
      {
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      width: 200px;
      flex: 0 0 auto;
      text-align: center;
      }
      .card-title-bold
      {
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      }
      .card-table-item
      {
    display: flex;
    padding-left: 10px;
    margin-top: 2px;
      }
  }
  /deep/.el-collapse-item__content
  {
   padding-bottom:0px;
  }
}
</style>
