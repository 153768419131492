<template>
  <div>
    <el-collapse v-model="activeNames" class="elementary">
      <el-collapse-item
        v-for="(item, index) in basics"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <p class="eleHead">

            <span class="hdate">评测时间：{{ item.generateTime }}</span>
          </p>
        </template>
        <div class="elementaryCon">
          <ul class="chapter">
            <li v-for="(chapter, index) in item.userPsychologyAssessRequest.chapter" :key="index">
              <span class="chapter-title">{{chapter.title}}</span>
              <ul class="question">
                <li v-for="(question, index) in chapter.questionList" :key="index">
                  <span class="question-title">{{index+1}}.{{question.title}}</span>

                  <span v-if="question.selections" class="question-answer">&nbsp;&nbsp;&nbsp;{{question.selections}}</span>
                  <span v-if="question.inputs" class="question-answer">&nbsp;&nbsp;&nbsp;{{question.inputs}}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      activeNames: 0,
      basics: [],
      userId:"",
    };
  },
  created() {
    this.userId = this.$route.query.userId;
    this.compocsition();
  },
  methods: {
    compocsition() {
      var that = this;
      var parameter = {
        userId: that.userId,
      };
      api.jkdapsychology(parameter).then((response) => {
        console.log(12223);
        if (response.data.code == 0) {
          var data=response.data.data;
          if(data)
          {
           that.basics = data;
          }

        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  .eleHead {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
    font-weight: bold;
    .hdate {
      color: #185ad6;
    }
    .hunfold {
      float: right;
    }
  }
  .elementaryCon ul {
    display: flex;
    
    flex-direction: column;
  }
  .chapter-title{
   color:rgba(0, 0, 0, 0.85);
   font-size: 16px;
   font-family: SourceHanSansCN-Medium, SourceHanSansCN;
   font-weight: 500;
  }
  .question-title
  {
  font-size: 14px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  }
  .question-answer{
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #264AFF;
  }
  li {
    width: 340px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }
  .question li{
  margin-bottom: 10px;
  margin-top: 10px;
  }
}
</style>
