export const tableData1 = [
  {
    name: "笑愚 贺",
    mobile: "0286-04898568",
    gender: "女",
    age: 38,
    address: "4982 智宸 街",
    nickname: "笑愚.贺75",
    avatar:
      "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng06d83353a1d668e2b4e9dd2acd558946188d585942ce0fa11b86425f153d4a71",
    status: 0, // 0 未处理 1已处理
  },
  {
    name: "瑾瑜 范",
    mobile: "0025-68257259",
    gender: "女",
    age: 45,
    address: "2860 谭 旁",
    nickname: "瑾瑜93",
    avatar:
      "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng06d83353a1d668e2b4e9dd2acd558946188d585942ce0fa11b86425f153d4a71",
    status: 0, // 0 未处理 1已处理
  },
];

export const tableData2 = [
  {
    name: "Cecelia Hagenes",
    mobile: "500-543-0598",
    gender: "女",
    age: 18,
    address: "Farrell Underpass",
    nickname: "Jayne79",
    avatar:
      "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng06d83353a1d668e2b4e9dd2acd558946188d585942ce0fa11b86425f153d4a71",
    status: 0, // 0 未处理 1已处理
  },
  {
    name: "Berta Doyle Mrs.",
    mobile: "096-611-9086",
    gender: "女",
    age: 98,
    address: "Dare Courts",
    nickname: "Mozelle.Johnson74",
    avatar:
      "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng06d83353a1d668e2b4e9dd2acd558946188d585942ce0fa11b86425f153d4a71",
    status: 0, // 0 未处理 1已处理
  },
];
export const tableData3 = [
  {
    name: "Aubree Gerlach",
    mobile: "82901-5932",
    gender: "女",
    age: 42,
    address: "Satterfield Haven",
    nickname: "Yvette39",
    avatar:
      "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng06d83353a1d668e2b4e9dd2acd558946188d585942ce0fa11b86425f153d4a71",
    status: 0, // 0 未处理 1已处理
  },
  {
    name: "Mara Gerhold",
    mobile: "(049) 042-3261 x158",
    gender: "男",
    age: 40,
    address: "Jerod Ranch",
    nickname: "Alyce.Homenick62",
    avatar:
      "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng06d83353a1d668e2b4e9dd2acd558946188d585942ce0fa11b86425f153d4a71",
    status: 0, // 0 未处理 1已处理
  },
];
// 表头
export const columns = [
  {
    title: "姓名",
    key: "name",
    minWidth: 123,
    align: "center",
  },
  {
    title: "手机号",
    key: "mobile",
    minWidth: 150,
    align: "center",
  },
  {
    title: "性别",
    key: "gender",
    minWidth: 88,
    align: "center",
  },
  {
    title: "年龄",
    key: "age",
    minWidth: 88,
    align: "center",
  },
  {
    title: "居住地",
    key: "address",
    minWidth: 250,
    align: "center",
  },

  {
    title: "微信昵称",
    key: "nickName",
    minWidth: 170,
    align: "center",
  },
  {
    title: "微信头像",
    slot: "avatar",
    align: "center",
    minWidth: 100,
  },
  {
    title: "预警信息",
    slot: "action",
    minWidth: 150,
    align: "center",
    fixed: "right",
  },
];

// 预警信息状态 map
export const statusMsgMap = {
  1: "处理",
  2: "查看",
};

