<template>
  <Layout>
    <Card>
      <p slot="title">营养素管理</p>
      <Button
        class="titleBtn"
        type="primary"
        icon="el-icon-plus"
        @click="addClick"
        >新增营养素</Button
      >
      <div class="content">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="120"
          align="center"
        ></el-table-column>
        <el-table-column prop="name" align="center"  minWidth="180" label="名称"></el-table-column>
        <el-table-column
          prop="humanClassifyName"
          align="center" 
          minWidth="200"
          label="针对领域"
        ></el-table-column>
        <el-table-column align="center" minWidth="200" label="操作">
          <template slot-scope="scope">
            <span @click="refundClick(scope.row)">修改</span>
            <span @click="deletes(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="pagenum"
        @current-change="handleCurrentChange"
        :total="total"
      ></el-pagination>
    </div>
    </Card>
    
    <el-dialog title="营养素" :visible.sync="revamp" minWidth="480px">
      <el-form :model="form" class="formCss">
        <el-form-item label="名称：" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="一句话描述：" :label-width="formLabelWidth">
          <el-input v-model="form.description" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="针对领域：（可多选）"
          :label-width="formLabelWidth"
        >
          <el-checkbox-group v-model="form.humanClassifyId">
            <el-checkbox-button
              v-for="item in territory"
              :label="item.id"
              :key="item.id"
              >{{ item.classifyName }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="链接地址：" :label-width="formLabelWidth">
          <el-input v-model="form.url" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <p style="padding-left: 45px">
            <span>图片：</span>
            <span style="float: right">（仅限一张800*800px）</span>
          </p>
          <el-upload
            list-type="picture-card"
            action="#"
            auto-upload:false
            :on-remove="handleRemove"
            :on-change="handleChange"
            :file-list="form.imgs"
            :http-request="uploadSectionFile"
            :class="{ hide: uploadDisabled }"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <Button @click="revamp = false">取 消</Button>
        <Button type="primary" @click="newlyyys(form)">确 定</Button>
      </div>
    </el-dialog>
  </Layout>
</template>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      total: 10,
      pagenum: 1,
      pagesize: 10,
      dialogImageUrl: "",
      uploadDisabled: false,
      disabled: false,
      revamp: false,
      territory: [],
      form: {
        name: "",
        description: "",
        humanClassifyId: [],
        url: "",
        imgs: [],
      },
      formLabelWidth: "100px",
      tableData: [],
    };
  },
  created() {
    this.territorys();
    this.yysInquire();
  },
  methods: {
    territorys() {
      //领域
      var that = this;
      api.territory().then((response) => {
        if (response.data.code == 0) {
          that.territory = response.data.data;
        }
      });
    },
    deletes(item) {
      //删除
      var that = this;
      this.$confirm("您确定要删除当前方案么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var parameter = {
            id: item.id,
          };
          api.yysdelete(parameter).then((response) => {
            if (response.data.code == 0) {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.yysInquire();
            } else {
              that.$message({
                type: "success",
                message: response.data.msg,
              });
            }
          });
        })
        .catch(() => {});
    },
    handleRemove() {
      setTimeout(() => {
        this.uploadDisabled = false;
      }, 1000);
    },
    handleChange() {
      //上传成功与否都会触发
      this.uploadDisabled = true;
    },
    uploadSectionFile(file) {
      var that = this;
      const parameter = new FormData();
      parameter.append("file", file.file);
      api.uploadFile(parameter).then((response) => {
        if (response.data.code == 0) {
          that.form.imgs = [{ url: response.data.data.url }];
        }
      });
    },
    //获取列表
    yysInquire() {
      //列表
      var that = this;
      var parameter = {
        page: that.pagenum,
        pagesize: that.pagesize,
      };
      api.yysInquire(parameter).then((response) => {
        response=response.data;
        if (response.code == 0) {
          var page=response.page?response.page:response.data;
          that.total = page.totalCount;
          that.tableData = page.list;
        }
      });
    },
    handleCurrentChange(cpage) {
      //分页切换
      this.pagenum = cpage;
      this.yysInquire();
    },
    //新增营养素
    newlyyys(item) {
      var that = this;
      var parameter = item;
      if (parameter.humanClassifyId) {
        parameter.humanClassifyId = item.humanClassifyId.join(",");
      }
      if (Array.isArray(parameter.imgs)) {
        parameter.img = item.imgs[0].url;
      }
      api.yysupdate(parameter).then((response) => {
        if (response.data.code == 0) {
          that.yysInquire();
          that.revamp = false;
        }
      });
    },
    //修改
    refundClick(item) {
      //修改
      this.revamp = true;
      var that = this;
      var parameter = {
        id: item.id,
      };
      api.yysdetail(parameter).then((response) => {
        if (response.data.code == 0) {
          that.form = response.data.nutrient;
          that.form.imgs = [{ url: response.data.nutrient.img }];
          if (response.data.nutrient.img) {
            that.uploadDisabled = true;
          }
          that.form.humanClassifyId = that.form.humanClassifyId.split(",");
          for (let i = 0; i < that.form.humanClassifyId.length; i++) {
            that.form.humanClassifyId[i] = Number(that.form.humanClassifyId[i]);
          }
        }
      });
    },
    addClick() {
      //新增
      this.revamp = true;
      this.uploadDisabled = false;
      this.form = {
        name: "",
        description: "",
        humanClassifyId: [],
        url: "",
        imgs: [],
      };
    },
  },
};
</script>
<style lang="less">
.formCss .el-checkbox-button {
  height: 22px;
  line-height: 22px;
  margin-left: 10px;
}
.formCss .el-form-item__label {
  line-height: 24px;
}
.formCss .el-radio-button__inner,
.formCss .el-radio-button:first-child .el-radio-button__inner,
.formCss .el-checkbox-button__inner,
.formCss .el-checkbox-button:first-child .el-checkbox-button__inner {
  border-radius: 2px !important;
  border: 1px solid #91d5ff;
  padding: 5px;
  font-size: 12px;
}
.formCss .el-upload--picture-card {
  width: 67px;
  height: 67px;
  line-height: 72px;
  background: rgba(6, 107, 168, 0.04);
  border-radius: 6px;
  border: 2px solid rgba(6, 107, 168, 0.2);
}
.formCss .hide .el-upload--picture-card {
  display: none;
}
.formCss .el-upload-list--picture-card {
  margin-left: 100px;
}
</style>
