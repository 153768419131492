<template>
  <el-dialog
  :visible.sync="visible"
  title="营养素方案申请"
  fullscreen
  :before-close="handleClose"
  >
   <nutritionApplyPlan :doctorId="doctorId" :hideTitle="true" :readOnly="true"/>
  </el-dialog>
</template>
<script>
import nutritionApplyPlan from "../nutritionApplyPlan.vue";
export default {
  props: ["visible","doctorId"],
  components: {
    nutritionApplyPlan
  },
  data() {
    return {


    };
  },
  mounted(){


  },
  created() {

  },
  methods: {
    handleClose: function () {
      this.$emit('close');
    }
  },
};
</script>
<style lang="less" scoped="scoped">

</style>
