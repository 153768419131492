import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueRouter from "vue-router";
import router from "./router/index.js";
import App from "./App.vue";
import less from "less";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import "./assets/less/common.less";
import "./assets/less/reset.less";
import "./assets/css/common.css";
import * as echarts from "echarts";
import httpsRequest from "./request/http.js";
import { api,uploader } from "@/request/api";
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
import { refreshHeaderToken } from "./utils/login";
import array from "./utils/extension/array";
import date from "./utils/extension/date";
import object from "./utils/extension/object";
import number from "./utils/extension/number";
import string from "./utils/extension/string";
import { Loading } from 'element-ui';
import "./utils/rem.js";
import { production } from "./request/config";

array,date,object,number,string;
api.loading=Loading;
Vue.prototype.api = api;
Vue.prototype.uploader = uploader;
Vue.prototype.$loading = Loading;
var user={
    roleType:'',
    memberTypeMap:{
    1: "vip会员",
    2: "体验会员",
    3: "游客",
    },
    memberTypeE:{
    VIP:1,
    TRYVIP:2,
    YOUKE:3
    },
    doctorTypeE:{
      JGS:'健康管理师',
      ZXS:'健康咨询师',
      TDGLY:'团队端',
      ZXSGLY:'健康咨询师管理员',
      JGGLY:'机构端',
      GLY:'系统管理员',

      JGS_S:'管理师',
      ZXS_S:'咨询师',
      GLY_S:'管理员'
    },
    sexMap:{
      0:'男',
      1:'女'
    },
    workStatusMap:[
      "在职",
      "离职"
    ],
    interfaceTypeMapE:{
      TEAM:0,
      HEALTH:1,
      DIET:2,
      PRECISE:3,
      WARNING:4,
      MSG:5,
      NURTRIENT:6,
      CONTACT:6
    }
};
user.isTDGLY= function () {
  return this.roleType === this.doctorTypeE.TDGLY;
};
user.isZXSGLY= function () {
  return this.roleType === this.doctorTypeE.ZXSGLY;
};
user.isGLY= function () {
  return this.roleType === this.doctorTypeE.GLY;
};
user.isJGS= function () {
  return this.roleType === this.doctorTypeE.JGS;
};
user.isZXS= function () {
  return this.roleType === this.doctorTypeE.ZXS;
};
user.isJGGLY= function () {
  return this.roleType === this.doctorTypeE.JGGLY;
};
Vue.prototype.user = user;
Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(ViewUI);
Vue.use(Viewer);
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(less);
Vue.prototype.$echarts = echarts;
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "data-source",
  },
});
refreshHeaderToken(production);

// document.oncontextmenu=function(evt){
//   evt.preventDefault();
// }
//
// document.onselectstart=function(evt){
//  evt.preventDefault();
// };

new Vue({
  router,
  httpsRequest,
  render: (h) => h(App),
}).$mount("#app");
