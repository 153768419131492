<template>
  <div>
    <Layout>
      <Card>
        <p slot="title">角色管理</p>
        <Button
          class="titleBtn"
          type="primary"
          :class="add - btn"
          @click="dialogFormVisible = true"
          >新增角色</Button
        >
        <div class="search-area">
          <span>角色类型：</span>
          <Select v-model="curType" style="width: 200px">
            <Option
              v-for="item in roleList"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}</Option
            >
          </Select>
          <Button type="primary" @click="fetchData({ page: 1 })">搜索</Button>
        </div>
      </Card>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="roleType" label="角色类别" width="120">
        </el-table-column>
        <el-table-column prop="roleName" label="角色姓名" width="120">
        </el-table-column>
        <el-table-column prop="area" width="150" label="管理地区">
        </el-table-column>
        <el-table-column prop="community" width="120" label="管理社区">
        </el-table-column>
        <el-table-column prop="qualifications" width="180" label="资质">
        </el-table-column>
        <el-table-column prop="genius" width="180" label="擅长">
        </el-table-column>
        <el-table-column prop="memLimit" width="180" label="用户管理上限">
        </el-table-column>
        <el-table-column align="center" width="200" label="操作">
          <span>编辑</span>
          <span @click="isShowloginMan = true">登录管理</span>
        </el-table-column>
        <template slot="empty">
          <empty-data></empty-data>
        </template>
      </el-table>
      <div class="lu-footer">
        <Page
          transfer
          :current.sync="page"
          :total="total"
          @on-change="changePage"
        />
      </div>
    </Layout>
    <!-- 登录管理 -->
    <!-- <el-dialog title="登录管理" :visible.sync="isShowloginMan" width="450px">
      <el-form>
        <el-form-item label="设置用户名:" :label-width="loginManLableW">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="设置密码:" :label-width="loginManLableW">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码:" :label-width="loginManLableW">
          <el-input></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <Button @click="isShowloginMan = false">取 消</Button>
        <Button type="primary" @click="addRole('ruleForm')">提交/修改</Button>
      </div>
    </el-dialog> -->
    <!-- 新增角色 -->
    <el-dialog
      :title="titleText"
      :visible.sync="dialogFormVisible"
      width="650px"
      class="addForm"
    >
      <el-form :model="formData" :rules="rules" ref="ruleForm">
        <el-form-item
          label="角色类别:"
          :inline-message="true"
          prop="roleType"
          class="mWidth"
          :label-width="formLabelWidth"
        >
          <el-select v-model="formData.roleType" placeholder="请选择角色类别">
            <el-option label="健康管理师" value="shanghai"></el-option>
            <el-option label="健康咨询师" value="beijing"></el-option>
            <el-option label="医生集团" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="角色姓名:"
          :inline-message="true"
          prop="roleName"
          class="mWidth"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="formData.roleName"
            autocomplete="off"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="管理地区:"
          class="manageArea sWidth"
          :label-width="formLabelWidth"
        >
          <v-distpicker
            :province="formData.province"
            :city="formData.city"
            :area="formData.area"
            @province="selectProvince"
            @city="selectCity"
            @area="selectArea"
          ></v-distpicker>
        </el-form-item>
        <el-form-item
          label="管理社区:"
          class="community lWidth"
          :label-width="formLabelWidth"
        >
          <div v-for="(item, index) in formData.community" :key="index">
            <Select v-model="item.value" placeholder="请选择管理社区" multiple>
              <Option
                v-for="item in communityList"
                :label="item.name"
                :value="item"
                :key="item"
              ></Option>
            </Select>
          </div>
        </el-form-item>
        <el-form-item label="资质:" :label-width="formLabelWidth">
          <el-input
            v-model="formData.qualifications"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="擅长:" :label-width="formLabelWidth">
          <el-input v-model="formData.genius" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="头像上传:" :label-width="formLabelWidth">
          <!-- <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :file-list="fileList">
            <Button size="small" type="primary">点击上传</Button>
            <div slot="tip" class="el-upload__tip">
              限1张，小于200kb，800*800
            </div>
          </el-upload> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <Button @click="dialogFormVisible = false">取 消</Button>
        <Button type="primary" @click="addRole('ruleForm')">提交/修改</Button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import emptyData from "./tableEmpty.vue";
import axios from "axios";
import VDistpicker from "v-distpicker";

const PAGE_SIZE = 10;

export default {
  name: "role-page",
  components: {
    emptyData,
    VDistpicker,
  },
  data() {
    return {
      page: 1,
      pageSize: PAGE_SIZE,
      total: 1,
      isShowloginMan: false, //是否展示登录管理
      loginManLableW: "100px",
      curType: '',
      roleList: [
       
      ],
      rules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        roleType: [
          { required: true, message: "请输入角色类别", trigger: "blur" },
        ],
      },
      titleText: "新增角色",
      tableData: [],
      gridData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      dialogTableVisible: false,
      dialogFormVisible: false,
      formData: {
        roleType: "",
        roleName: "",
        province: "",
        city: "",
        area: "",
        community: [
          {
            name: "月坛小区",
            value: "yuetan",
          },
        ],
        qualifications: "",
        genius: "",
      },
      formLabelWidth: "85px",
      list: [],
      communityList: [],
    };
  },
  mounted() {
    // 初始化角色列表
    this.fetchData({ page: 1 });
    this.fetchCommunityData();
  },
  methods: {
    fetchCommunityData() {
      const vm = this;
      axios.post("/api/community/recursor").then((res) => {
        console.log(res);
        const { data } = res;
        vm.communityList = data.map(({ categoryName }) => categoryName);
      });
    },
    fetchData({ page }) {
      const vm = this;
      axios
        .get("/sys/role/list", {
          params: {
            roleType: this.curType,
            page,
            pageSize: PAGE_SIZE,
          },
        })
        .then((res) => {
          console.log(res);
          const {
            data: {
              page: { list, totalCount: total },
            },
          } = res;

          vm.list = list.map(({ name, province, city, region: area, id }) => ({
            name,
            province,
            city,
            area,
            id,
          }));
          vm.total = total;
        });
    },
    refresh() {
      this.fetchData({ page: this.page });
    },
    // 换页
    changePage(page) {
      this.fetchData({ page });
    },
    handlePreview() {},
    handleClick(tab, event) {
      console.log(tab.index, event);
    },
    //新增角色
    addRole(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false;
          this.tableData.push(this.formData);
        }
      });
    },
    //增加管理社区
    addCommunity() {
      this.formData.community.push({
        name: "",
        value: "",
      });
    },
    //删除管理社区
    delCommunity(index) {
      this.formData.community.splice(index, 1);
    },
    selectProvince(obj) {
      const { value } = obj;
      this.form.province = value;
    },
    selectCity(obj) {
      const { value } = obj;
      this.form.city = value;
    },
    selectArea(obj) {
      const { value } = obj;
      this.form.area = value;
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.el-form-item__label {
  color: rgba(0, 0, 0, 0.85);
}
.el-form-item {
  margin-bottom: 10px;
}
.Tabs__content {
  padding-left: 20px;
}
.el-input__inner {
  height: 30px;
}
.sWidth .el-input,
.sWidth .el-select {
  width: 105px;
}
.mWidth .el-input,
.mWidth .el-select {
  width: 175px;
}
.lWidth .el-input,
.lWidth .el-select {
  width: 225px;
}
.manageArea .el-select,
.community .el-select {
  margin-right: 10px;
  margin-bottom: 12px;
  float: left;
}
.toolsBtn {
  width: 36px;
  height: 36px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  float: left;
  text-align: center;
  margin-top: 1px;
  margin-right: 10px;
  line-height: 37px;
  cursor: pointer;
}
.addCom {
  cursor: pointer;
}
.addForm .el-dialog__body {
  height: 430px;
  overflow-y: scroll;
}

.search-area {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
</style>
