<template>
  <div>
    <el-dialog title="精准膳食菜单历史"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :append-to-body="appendToBody?true:false"
    >
      <el-collapse v-model="activeNames" class="elementary">
        <el-collapse-item
          v-for="(item, index) in basics"
          :key="index"
          :name="index"
        >
          <template slot="title">
            <p class="eleHead">

              <span class="hdate">{{ item.generateTime }}</span>
            </p>
          </template>
          <div class="elementaryCon">
            <div class="item-wrapper" v-for="(citem, index) in item.foodDayInfos" :key="index">
              <div class="item-title">{{citem.title}}：</div>
              <div class="item-value">{{citem.content}}</div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialogVisible","userId","appendToBody"],
  data() {
    return {
      activeNames: 0,
      basics: "",
    };
  },
  created() {
    var that=this;
    that.$watch('userId', function() {
      if(that.userId)
      {
        that.basics=[];
        that.loadData();
      }

    });
  },
  methods: {
    handleClose(){
      this.$emit('close');
    },
    loadData() {
      var that = this;
      console.log(1234);
      var parameter = {
        userId: that.userId,
      };
      that.api.preciseQuery(parameter).then((response) => {
        if (response.data.code == 0) {
          that.basics = response.data.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  .eleHead {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
    font-weight: bold;
    .hdate {
      color: #185ad6;
    }
    .hunfold {
      float: right;
    }
  }
  .elementaryCon{
    .item-wrapper{
      display: flex;
      padding: 0 20px;
      margin-bottom: 20px;
      .item-title{
      font-size: 11px;
font-family: SourceHanSansCN-Medium, SourceHanSansCN;
font-weight: 500;
color: rgba(0, 0, 0, 0.85);
flex-basis: 10%;
    text-align: right;
      }
      .item-value{
      font-size: 10px;
font-family: SourceHanSansCN-Normal, SourceHanSansCN;
font-weight: 400;
color: rgba(0, 0, 0, 0.85);
flex-basis: 90%;
      }
    }
  }

  li {
    width: 340px;
    padding: 20px;
    font-size: 14px;
    border-right: 1px solid #e3e3e3;
    margin-bottom: 20px;
  }
}
/deep/.el-collapse
{
border-top:0;
border-bottom:0;
}
/deep/.el-dialog__wrapper
{
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-dialog
{
  margin-top: unset;
  width: 1200px;
  height: 80%;
  display: flex;
  flex-direction: column;
}
/deep/.el-dialog__body
{
  overflow: scroll;
  flex: 1;
}
</style>
