import { production } from "./config";
import { mock,basisURL } from "./config";
import axios from "axios";
import router from "../router/index.js";
import { Loading } from 'element-ui';
axios.defaults.baseURL = basisURL;
// 请求超时时间
axios.defaults.timeout = 30000;
//post请求头   application/json;charset=UTF-8
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 请求拦截器
axios.interceptors.request.use(function (config) {
    // 请求的时候加个toke，存在就加上
    console.log(config.data);
    if (!config.data) {
      config.data={};
    }
    if (config.data.loading==undefined) {
      config.data.loading=true;
    }
    if (axios.defaults.loadingCount==undefined) {
      axios.defaults.loadingCount=0;
    }

    if (config.data.loading) {
      axios.defaults.loadingCount++;
      axios.loadingInstance = Loading.service({ fullscreen: true });
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// 响应拦截器
axios.interceptors.response.use(function (response) {
    //判断下相应过来的状态码,这里根据后端怎么写响应码有关
    axios.defaults.loadingCount--;
    if (axios.defaults.loadingCount<=0) {
      axios.loadingInstance?axios.loadingInstance.close():null;
    }
    if (response.data.code == 203) {
      sessionStorage.removeItem("token");
      router.push({ path: "/login" });
    }
    return response;
}, function (error) {
   axios.defaults.loadingCount--;
   if (axios.defaults.loadingCount<=0) {
     axios.loadingInstance?axios.loadingInstance.close():null;
   }
    //判断响应错误码
    // if (error.response.status) {
    //     switch (error.response.status) {
    //         case 401:
    //             console.log(401)
    //             break
    //         case 403:
    //             console.log(403)
    //             break
    //         case 404:
    //             console.log(404)
    //             break
    //         default:
    //             Toast({
    //                 message: '默认响应错误码',
    //                 duration: 1500,
    //                 forbidClick: true
    //             });


    //     }
    // }
    return Promise.reject(error);
});
const api = {
  axiost(data) {
    data.url = data._url;
    if (!production && data._mock && mock) {
      data.url = data._mock;
    }
    if (data.method == "get") {
      return axios({
        url: data.url,
        method: data.method,
        title: data.title,
        params: data.data,
      });
    }
    else {
      return axios({
        url: data.url,
        method: data.method,
        title: data.title,
        data: data.data,
      });
    }
  },
  territory() {
    var data = {
      _url: "/classify/query",
      method: "post",
      title: "领域接口",
      data: {},
    };
    return this.axiost(data);
  },
  userInfo() {
    var data = {
      _url: "/api/account/userInfo",
      method: "post",
      title: "用户类型接口",
      data: {},
    };
    return this.axiost(data);
  },
  ddglzkx() {
    var data = {
      _url: "/api/order/summary",
      method: "post",
      title: "订单管理综核销数据查询",
      data: { 123: 1 },
    };
    return this.axiost(data);
  },
  ddglInquire(parameter) {
    var data = {
      _url: "/api/order/queryList",
      method: "post",
      title: "订单管理列表查询",
      data: parameter,
    };
    return this.axiost(data);
  },
  ddglofflineAgencies(parameter) {
    var data = {
      _url: "/api/shop/recursor",
      method: "post",
      title: "获取机构列表接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  ddglrefundy(parameter) {
    var data = {
      _url: "/api/order/refund",
      method: "post",
      title: "订单管理退款",
      data: parameter,
    };
    return this.axiost(data);
  },
  ddglOrderCheck(parameter) {
    var data = {
      _url: "/api/order/check",
      method: "post",
      title: "订单管理退款",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdaInquire(parameter) {
    var data = {
      _url: "/api/healthFile/query",
      method: "post",
      title: "健康档案列表查询",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdaadd(parameter) {
    var data = {
      _url: "/api/healthFile/add",
      method: "post",
      title: "新增健康档案",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdaparticulars(parameter) {
    var data = {
      _url: "/api/healthFile/info",
      method: "post",
      title: "获取客户详情",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdadetail(parameter) {
    var data = {
      _url: "/api/client/archive/detail",
      method: "post",
      title: "获取客户体征详情",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdaaddsign(parameter) {
    var data = {
      _url: "/api/client/keyCharacter/save",
      method: "post",
      title: "新增体征记录",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdabloodPressure(parameter) {
    var data = {
      _url: "/api/healthFile/bloodPressure/particulars",
      method: "post",
      title: "血压数据",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdabloodSugar(parameter) {
    var data = {
      _url: "/api/healthFile/bloodSugar/particulars",
      method: "post",
      title: "血糖数据",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdabmi(parameter) {
    var data = {
      _url: "/api/healthFile/bmi/particulars",
      method: "post",
      title: "高重数据",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdawaistHip(parameter) {
    var data = {
      _url: "/api/healthFile/waistHip/particulars",
      method: "post",
      title: "腰臀数据",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdabasics(parameter) {
    var data = {
      _url: "/api/client/archive/info",
      method: "post",
      title: "获取客户基础信息",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdanutrition(parameter) {
    var data = {
      _url: "/api/client/archive/meals",
      method: "post",
      title: "获取客户营养膳食接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdabodycompocsition(parameter) {
    var data = {
      _url: "/api/client/archive/bodyCompositionInfo",
      method: "post",
      title: "获取客户人体成分接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdapsychology(parameter) {
    var data = {
      _url: "/api/client/archive/psychology",
      method: "post",
      title: "获取客户心理状态接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdabiochemistryIndex(parameter) {
    var data = {
      _url: "/api/client/archive/biochemistryIndex",
      method: "post",
      title: "廘尊档案生化指标接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdasleep(parameter) {
    var data = {
      _url: "/api/client/archive/sleep",
      method: "post",
      title: "廘尊档案睡眠监测接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdatongue(parameter) {
    var data = {
      _url: "/api/client/archive/tongue",
      method: "post",
      title: "廘尊档案中医详情接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdaexclusive(parameter) {
    var data = {
      _url: "/api/client/archive/exclusiveSchema",
      method: "post",
      title: "获取客户专属方案接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdaupdate(parameter) {
    var data = {
      _url: "/api/client/keyCharacter/mobile/foodDay/update",
      method: "post",
      title: "获取客户专属方案修改",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdamedical(parameter) {
    var data = {
      _url: "/api/client/archive/report",
      method: "post",
      title: "获取客户报告",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdauploadmedicine(parameter) {
    var data = {
      _url: "/api/client/keyCharacter/report/save",
      method: "post",
      title: "新增报告接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  jkdanutrient(parameter) {
    var data = {
      _url: "/api/client/archive/nutrient",
      method: "post",
      title: "获取客户营养素方案列表接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  yysInquire(parameter) {
    var data = {
      _url: "/api/nutrient/queryList",
      method: "post",
      title: "营养素列表接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  yysdetail(parameter) {
    var data = {
      _url: "/api/nutrient/info",
      method: "post",
      title: "营养素详情接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  yysupdate(parameter) {
    var url1 = "";
    if (parameter.id) {
      url1 = "/api/nutrient/update";
    } else {
      url1 = "/api/nutrient/save";
    }
    var data = {
      _url: url1,
      method: "post",
      title: "营养素新增修改接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  yysdelete(parameter) {
    var data = {
      _url: "/api/nutrient/delete",
      method: "post",
      title: "删除营养素接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  yysApplyInquire(parameter) {
    var data = {
      _url: "/api/apply/nutrient/queryList",
      method: "post",
      title: "营养素方案申请列表接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  yysApplyDetail(parameter) {
    var data = {
      _url: "/api/apply/nutrient/detail",
      method: "post",
      title: "营养素方案获取详情接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  yysApplyupdate(parameter) {
    var data = {
      _url: "/api/apply/nutrient/update",
      method: "post",
      title: "营养素方案提交接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  uploadFile(parameter) {
    var data = {
      _url: "/api/file/upload",
      method: "post",
      title: "上传文件",
      data: parameter,
    };
    return this.axiost(data);
  },
  getLoginSMS(parameter) {
    var data = {
      _url: "/api/sms/login",
      method: "post",
      title: "登录验证码获取接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  getRegisterSMS(parameter) {
    var data = {
      _url: "/api/sms/register",
      method: "post",
      title: "注册验证码获取接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  getRetrieveSMS(parameter) {
    var data = {
      _url: "/api/sms/retrieve",
      method: "post",
      title: "找回密码验证码获取接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  userRegister(parameter) {
    var data = {
      _url: "/api/user/register",
      method: "post",
      title: "用户注册接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  doctorLink(parameter) {
    var data = {
      _url: "/api/doctor/link",
      method: "get",
      title: "团队管理员和机构管理员邀请注册接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  userModifyPassword(parameter) {
    var data = {
      _url: "/api/user/modify/password",
      method: "post",
      title: "用户修改密码接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  userRetrievePassword(parameter) {
    var data = {
      _url: "/api/user/retrieve/password",
      method: "post",
      title: "用户密码找回接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  userBaseSave(parameter) {
    var url="/api/user/base/save";
    if (parameter.id) {
      url="/api/user/base/update";
    }
    var data = {
      _url: url,
      method: "post",
      title: "用户基本信息补充保存接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  userBaseInfo(parameter) {
    var data = {
      _url: "/api/user/base/info",
      method: "post",
      title: "用户详情接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  teamQuery(parameter) {
    var data = {
      _url: "/api/team/query",
      method: "post",
      title: "团队下拉模块接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  preciseStatistic(parameter) {
    var data = {
      _url: "/api/precise/statistic",
      method: "post",
      title: "精准膳食查询统计接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  preciseUpdate(parameter) {
    var url="/api/precise/save";
    if (parameter.id) {
      url="/api/precise/update";
    }
    var data = {
      _url: url,
      method: "post",
      title: "精准膳食修改接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  preciseInfo(parameter) {
    var data = {
      _url: "/api/precise/info",
      method: "post",
      title: "精准膳食信息接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  preciseQuery(parameter) {
    var data = {
      _url: "/api/precise/query",
      method: "post",
      title: "精准膳食查看列表接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  preciseDelete(parameter) {
    var data = {
      _url: "/api/precise/delete",
      method: "post",
      title: "精准膳食删除接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  clientMessageQuery(parameter) {
    var data = {
      _url: "/api/client/message/query",
      method: "post",
      title: "消息查询接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  clientMessageSave(parameter) {
    var data = {
      _url: "/api/client/message/save",
      method: "post",
      title: "消息发送接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  healthFileStatistic(parameter) {
    var data = {
      _url: "/api/healthFile/statistic",
      method: "post",
      title: "档案统计客户数量接口",
      data: parameter,
    };
    return this.axiost(data);
  },
  doctorQueryList(parameter) {
    var data = {
      _url: "/api/doctor/queryList1",
      method: "post",
      title: "团队列表请求",
      data: parameter,
    };
    return this.axiost(data);
  },
  doctorQuit(parameter) {
    var data = {
      _url: "/api/doctor/quit",
      method: "post",
      title: "团员离职",
      data: parameter,
    };
    return this.axiost(data);
  },
  doctorInfo(parameter) {
    var data = {
      _url: "/api/doctor/info",
      method: "post",
      title: "查看医师详情",
      data: parameter,
    };
    return this.axiost(data);
  },
  clientSearch(parameter) {
    var data = {
      _url: "/api/client/search",
      method: "post",
      title: "根据离职健管师/咨询师id，name搜索获取用户",
      data: parameter,
    };
    return this.axiost(data);
  },
  doctorQuery(parameter) {
    var data = {
      _url: "/api/doctor/query",
      method: "post",
      title: "搜索在职的健管师/咨询师",
      data: parameter,
    };
    return this.axiost(data);
  },
  clientBind(parameter) {
    var data = {
      _url: "/api/client/bind",
      method: "post",
      title: "批量换绑/单个换绑（档案）",
      data: parameter,
    };
    return this.axiost(data);
  },
  manageQueryDoctor(parameter) {
    var data = {
      _url: "/api/manage/queryDoctor",
      method: "post",
      title: "团队列表",
      data: parameter,
    };
    return this.axiost(data);
  },
  messageQueryList(parameter) {
    var data = {
      _url: "/api/client/message/queryList",
      method: "post",
      title: "消息列表",
      data: parameter,
    };
    return this.axiost(data);
  },
  warningQueryList(parameter) {
    var data = {
      _url: "/api/warning/queryList",
      method: "post",
      title: "预警列表",
      data: parameter,
    };
    return this.axiost(data);
  },
  dietaryQuery(parameter) {
    var data = {
      _url: "/api/dietary/query",
      method: "post",
      title: "饮食解读列表",
      data: parameter,
    };
    return this.axiost(data);
  },
  preciseQueryList(parameter) {
    var data = {
      _url: "/api/precise/queryList",
      method: "post",
      title: "精准餐单列表",
      data: parameter,
    };
    return this.axiost(data);
  },
  userBaseQrCode(parameter) {
    var data = {
      _url: "/api/user/base/qrcode",
      method: "post",
      title: "生成小程序健康管理师/健康咨询师二维码",
      data: parameter,
    };
    return this.axiost(data);
  },
  teamQueryList(parameter) {
    var data = {
      _url: "/api/team/queryList",
      method: "post",
      title: "团队分页查询",
      data: parameter,
    };
    return this.axiost(data);
  }
};
const uploader ={
  upload(file,callback){
    const parameter = new FormData();
    parameter.append("file", file.file);
    api.uploadFile(parameter).then((response) => {
      if(callback)callback(response)
    });
  }
}
export { api,uploader };
