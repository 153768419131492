<template>
  <Layout>
    <Card>
      <p slot="title">线下服务管理</p>
      <div class="content">
        <Table class="lu-table" :columns="columns" :data="list">
          <template slot-scope="{ index }" slot="num">
            {{ index + 1 }}
          </template>
          <template slot-scope="{ row }" slot="status">
            {{ statusMsgMap[row.onLine] }}
          </template>
          <template slot-scope="{ row }" slot="action">
            <Button @click="handleEdit(row.id)" type="text"> 编辑 </Button>
          </template>
        </Table>
        <div class="lu-footer">
          <Page transfer :current="page" :pageSize="pageSize" :total="total" @on-change="changePage" />
        </div>
      </div>
    </Card>
    
    <OffLineServicesModal :visible="visible" :fields="fields" :form="detailForm" v-on:submit="onSubmit" v-on:close="onClose" />
  </Layout>
</template>
<script>
import Axios from "axios";
import OffLineServicesModal from "./common/OfflineServicesModal.vue";

const PAGE_SIZE = 10;

const statusMsgMap = {
  1: "上架",
  2: "下架",
};

const columns = [
  {
    title: "序号",
    key: "num",
    slot: "num",
    minWidth: 120,
    align:"center",
  },
  {
    title: "服务项目ID",
    key: "id",
    minWidth: 180,
    align:"center",
  },
  {
    title: "服务项目",
    key: "name",
    minWidth: 300,
    align:"center",
  },
  {
    title: "原价",
    key: "price",
    minWidth: 140,
    align:"center",
  },
  {
    title: "现价",
    key: "nowPrice",
    minWidth: 140,
    align:"center",
  },
  {
    title: "上架状态",
    minWidth: 120,
    slot: "status",
    align:"center",
  },
  {
    title: "操作",
    slot: "action",
    minWidth: 120,
    align: "center",
    fixed: "right",
  },
];

export default {
  name: "service-page",
  components: { OffLineServicesModal },
  data() {
    return {
      page: 1,
      pageSize: PAGE_SIZE,
      total: 1,
      statusMsgMap,
      columns,
      list: [],
      visible: false, // 详情弹窗
      curId: null,
      detailForm: {},
      fields: [],
    };
  },
  mounted() {
    // 初始化线下服务列表
    this.fetchData({ page: 1 });
    this.fetchFields();
  },
  methods: {
    fetchData({ page }) {
      const vm = this;
      Axios.post("/api/goods/queryList", {
        page,
        pageSize: PAGE_SIZE,
      })
        .then((res) => {
          const {
            data: {
              page: { list, totalCount },
            },
          } = res;
          vm.list = list;
          vm.total = totalCount;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchFields() {
      const vm = this;
      Axios.post("/classify/query", {})
        .then((res) => {
          const {
            data: { data },
          } = res;
          vm.fields = data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 点击编辑按钮
    handleEdit(id) {
      this.curId = id;
      const vm = this;
      Axios.post("/api/goods/info", { id }).then((res) => {
        const {
          data: { goods },
        } = res;
        vm.visible = true;
        vm.detailForm = goods;
      });
    },
    // 换页
    changePage(page) {
      const { currentTabId: tabId } = this;
      this.fetchData({ tabId, page });
    },
    // 编辑线下机构
    onSubmit(form) {
      const vm = this;
      const url = "/api/goods/update";
      Axios.post(url, { ...form })
        .then(function () {
          vm.visible = false;
          vm.curId = null;
          // 改变状态，刷新数据
          vm.fetchData({ page: 1 });
        })
        .catch(function (error) {
          vm.visible = false;
          vm.curId = null;
          console.log(error);
        });
    },
    onClose() {
      this.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.content{
   padding-top:0px !important;
}
</style>
