<template>
  <div>
    <el-collapse v-model="activeNames" class="elementary">
      <el-collapse-item
        v-for="(item, index) in basics"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <p class="eleHead">
            营养膳食
            <span class="hdate">评测时间：{{ item.date }}</span>
          </p>
        </template>
        <ul class="conbox">
          <li class="conli" v-for="(citem, index) in item.chapter" :key="index">
            <h4>{{ citem.title }}</h4>
            <ul>
              <li v-for="(litem, index) in citem.questionList" :key="index">
                {{
                  litem.title +
                  "：" +
                  litem.times +
                  " " +
                  (litem.weight ? litem.weight : "")
                }}
              </li>
            </ul>
          </li>
        </ul>
        <div class="ssbl">
          <h4>
            <span>营养膳食</span>
            <p><i></i>标准饮食搭配</p>
            <p><i></i>用户饮食搭配</p>
          </h4>
          <ul>
            <li v-for="(citem, index) in item.userMealsRatio" :key="index">
              <h5>{{ citem.mealType }}</h5>
              <div class="bfb">
                <p>
                  <el-progress
                    :percentage="citem.normalRatio"
                    v-if="!isNaN(parseInt(citem.normalRatio))"
                    :format="format"
                    :stroke-width="11"
                    color="#066ba8"
                    >{{ citem.ratio }}</el-progress
                  >
                </p>
                <p>
                  <el-progress
                    :percentage="citem.ratio * 100"
                    v-if="!isNaN(parseInt(citem.ratio * 100))"
                    :format="format"
                    :stroke-width="11"
                    color="rgba(6, 107, 168, 0.2)"
                  ></el-progress>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      activeNames: 0,
      basics: "",
      userId:"",
    };
  },
  created() {
    this.userId = this.$route.query.userId;
    this.nutrition();
  },
  methods: {
    format(percentage) {
      percentage = `${percentage.toFixed(2)}%`;
      return percentage;
    },
    nutrition() {
      var that = this;
      var parameter = {
        userId: that.userId,
      };
      api.jkdanutrition(parameter).then((response) => {
        if (response.data.code == 0) {
          that.basics = response.data.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  .eleHead {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
    font-weight: bold;
    .hdate {
      color: #185ad6;
      margin-left: 20px;
    }
  }
  .conbox {
    padding: 0 20px;
    .conli {
      margin-bottom: 20px;
      background: rgba(6, 107, 168, 0.04);
      border-radius: 4px;
      font-size: 0;
      li {
        display: inline-block;
        height: 70px;
        min-width: 310px;
        line-height: 70px;
        font-size: 14px;
        padding-left: 20px;
        border-right: 1px solid #e3e3e3;
      }
    }
    h4 {
      font-size: 14px;
      padding-left: 20px;
      padding-top: 20px;
      span {
        margin-left: 30px;
        color: #185ad6;
      }
    }
  }
  .ssbl {
    padding: 0 20px 20px;
    h4 {
      margin-bottom: 20px;
      p {
        float: right;
        font-size: 14px;
        font-weight: normal;
        &:last-child i {
          background: rgba(6, 107, 168, 0.2);
        }
        i {
          display: inline-block;
          border-radius: 50%;
          margin-left: 50px;
          margin-right: 10px;
          width: 8px;
          height: 8px;
          background: #066ba8;
        }
      }
    }
    ul {
      background: rgba(6, 107, 168, 0.04);
      border-radius: 4px;
      padding: 20px 0;
      li {
        display: inline-block;
        width: 290px;
        font-size: 14px;
        padding-left: 20px;
        margin-bottom: 10px;
        .bfb {
          padding: 20px;
          border-right: 1px solid #e3e3e3;
        }
        .el-progress-bar {
          width: 80%;
        }
      }
    }
  }
}
</style>
