<template>
<el-dialog
title='用户基本信息'
fullscreen
:visible.sync="visible"
:before-close="handleClose"
>
  <div class="content-wrapper">
    <div class="mid-content">
      <el-form :model="ruleForm" :disabled="!editAble" :rules="rules" ref="ruleForm">

        <el-form-item label="姓名:" prop="name">

          <el-input
            v-if="editAble"
            v-model="ruleForm.name"
            placeholder="请输入姓名"
            autocomplete="off"
          ></el-input>
          <template v-else>
             {{ruleForm.name}}
          </template>
        </el-form-item>
        <el-form-item label="性别:" prop="sex">

        <el-select v-if="editAble" v-model="ruleForm.sex" clearable placeholder="请选择性别">
         <el-option
           v-for="item in sexList"
           :key="item.id"
           :label="item.label"
           :value="item.value">
         </el-option>
        </el-select>
        <template v-else>
           {{user.sexMap[ruleForm.sex]}}
        </template>
        </el-form-item>

        <el-form-item label="出生日期:" prop="birth">

          <el-date-picker
            v-if="editAble"
            v-model="ruleForm.birth"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            placeholder="选择出生日期">
          </el-date-picker>
          <template v-else>
             {{ruleForm.birth}}
          </template>
        </el-form-item>

        <el-form-item label="居住地址:"  prop="region">
          <template v-if="editAble">
          <el-cascader
            :options="regions"
            :clearable="true"
            v-model="ruleForm.region"
            @change="handleRegionChange"
          >
          </el-cascader>
          <el-input
            class="region-input"
            v-model="ruleForm.lifeAddress"
            placeholder="请输入详细地址"
            autocomplete="off"
          ></el-input>
          </template>
          <template v-else>
             {{ruleForm.province}}-{{ruleForm.city}}-{{ruleForm.county}}-{{ruleForm.lifeAddress}}
          </template>
        </el-form-item>

        <el-form-item label="民族:" prop="nationality">

        <el-select v-if="editAble" v-model="ruleForm.nationality" clearable placeholder="请选择民族">
          <el-option
            v-for="item in nationalityList"
            :key="item.id"
            :label="item.info"
            :value="item.info">
          </el-option>
        </el-select>
        <template v-else>
           {{ruleForm.nationality}}
        </template>
        </el-form-item>

        <el-form-item label="所属团队:" prop="teamId">
         <el-select v-if="teamList.length>0" v-model="ruleForm.teamId" @change="handleTeamChange" clearable placeholder="请选择所属团队">
          <el-option
            v-for="item in teamList"
            :key="item.id"
            :label="item.teamName"
            :value="item.id">
          </el-option>
         </el-select>
         <template v-else>
            {{ruleForm.teamName}}
         </template>
        </el-form-item>
        <el-form-item label="展示给用户的个人介绍:" prop="description">
          <el-input v-if="editAble" type="textarea" maxlength="500"  show-word-limit v-model="ruleForm.description"></el-input>
          <template v-else>
             <div class="item-wrapper">
             {{ruleForm.description}}
             </div>
          </template>
        </el-form-item>

        <el-form-item label="身份证正反面:" prop="idcard">
          <el-upload
            v-if="editAble"
            action="#"
            :http-request="uploadIDCardImgFront"
            :show-file-list="false"
          >
            <img v-if="ruleForm.idCardImgFront" :src="ruleForm.idCardImgFront" class="idcard">
            <i v-else class="el-icon-plus idcard-uploader-icon">上传正面</i>
          </el-upload>
          <template v-else>
             <img v-if="ruleForm.idCardImgFront" :src="ruleForm.idCardImgFront" class="idcard">
          </template>
          <el-upload
            v-if="editAble"
            style="margin-left:12px;"
            action="#"
            :http-request="uploadIDCardImgBack"
            :show-file-list="false"
          >
            <img v-if="ruleForm.idCardImgBack" :src="ruleForm.idCardImgBack" class="idcard">
            <i v-else class="el-icon-plus idcard-uploader-icon">上传背面</i>
          </el-upload>
          <template v-else>
             <img v-if="ruleForm.idCardImgBack" :src="ruleForm.idCardImgBack" class="idcard">
          </template>
        </el-form-item>
        <el-form-item label="头像(免冠尺寸800*800并且小于500k):" prop="avatar">
          <el-upload
            v-if="editAble"
            action="#"
            :http-request="uploadAvatarImg"
            :show-file-list="false"
          >
            <img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <template v-else>
             <img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar">
          </template>
        </el-form-item>
        <el-form-item label="注册营养师证书/公共营养师国家职业资格证书/职业资格证书（3选1）" prop="certificateImg">
          <div v-if="editAble" class="certificateImg-wrapper">
            <el-radio style="margin-top:18px;" v-model="ruleForm.certificateType" :label="1">职业资格证书</el-radio>
            <el-radio style="margin-top:18px;" v-model="ruleForm.certificateType" :label="2">公共营养师国家职业资格证书</el-radio>
            <el-radio style="margin-top:18px;" v-model="ruleForm.certificateType" :label="3">注册营养师证书</el-radio>
            <el-upload
              style="margin-top:18px;"
              action="#"
              :http-request="uploadCertificateImg"
              :show-file-list="false"
            >
              <img v-if="ruleForm.certificateImg" :src="ruleForm.certificateImg" class="certificateImg">
              <i v-else class="el-icon-plus certificateImg-uploader-icon"></i>
            </el-upload>
          </div>
          <template v-else>
             <img v-if="ruleForm.certificateImg" :src="ruleForm.certificateImg" class="certificateImg">
          </template>
        </el-form-item>

      </el-form>
      <el-button v-if="editAble" @click="submit" class="submitButton">提交</el-button>
      <sucessDialog :appendToBody="true" content="基本信息保存成功" :dialogVisible="sucessDialogVisible" @confirm="sucessDialogConfirm" @close="sucessDialogClose"/>
    </div>
  </div>
</el-dialog>
</template>
<script>
import { regionData,CodeToText,TextToCode } from "element-china-area-data";
import sucessDialog from "../sucessDialog.vue";
export default {
  name: "basicInfo-page",
  props: ["visible","userId","editAble"],
  components: {
    sucessDialog
  },
  data() {
    var checkIDCard=((rule,value,callback)=>{
      if(!this.ruleForm.idCardImgFront){
        callback(new Error("请上传身份证正面照"));
      }else if(!this.ruleForm.idCardImgBack){
        callback(new Error("请上传身份证反面照"));
      }else{
        callback();
      }
    });
    var checkCertificate=((rule,value,callback)=>{
      if(!this.ruleForm.certificateType){
        callback(new Error("请选择证书类型"));
      }else if(!this.ruleForm.certificateImg){
        callback(new Error("请上传证书图片"));
      }else{
        callback();
      }
    })
    return {
      sucessDialogVisible:false,
      regions: regionData,
      teamList:[],
      sexList:[{id:1,label:"男",value:0},{id:2,label:"女",value:1}],
      nationalityList:[{id:1,info:"汉族",value:1,},{id:2,info:"壮族",value:2,},{id:3,info:"满族",value:3,},{id:4,info:"回族",value:4,},{id:5,info:"苗族",value:5,},{id:6,info:"维吾尔族",value:6,},{id:7,info:"土家族",value:7,},{id:8,info:"彝族",value:8,},{id:9,info:"蒙古族",value:9,},{id:10,info:"藏族",value:10,},{id:11,info:"布依族",value:11,},{id:12,info:"侗族",value:12,},{id:13,info:"瑶族",value:13,},{id:14,info:"朝鲜族",value:14,},{id:15,info:"白族",value:15,},{id:16,info:"哈尼族",value:16,},{id:17,info:"哈萨克族",value:17,},{id:18,info:"黎族",value:18,},{id:19,info:"傣族",value:19,},{id:20,info:"畲族",value:20,},{id:21,info:"傈僳族",value:21,},{id:22,info:"仡佬族",value:22,},{id:23,info:"东乡族",value:23,},{id:24,info:"高山族",value:24,},{id:25,info:"拉祜族",value:25,},{id:26,info:"水族",value:26,},{id:27,info:"佤族",value:27,},{id:28,info:"纳西族",value:28,},{id:29,info:"羌族",value:29,},{id:30,info:"土族",value:30,},{id:31,info:"仫佬族",value:31,},{id:32,info:"锡伯族",value:32,},{id:33,info:"柯尔克孜族",value:33,},{id:34,info:"达斡尔族",value:34,},{id:35,info:"景颇族",value:35,},{id:36,info:"毛南族",value:36,},{id:37,info:"撒拉族",value:37,},{id:38,info:"布朗族",value:38,},{id:39,info:"塔吉克族",value:39,},{id:40,info:"阿昌族",value:40,},{id:41,info:"普米族",value:41,},{id:42,info:"鄂温克族",value:42,},{id:43,info:"怒族",value:43,},{id:44,info:"京族",value:44,},{id:45,info:"基诺族",value:45,},{id:46,info:"德昂族",value:46,},{id:47,info:"保安族",value:47,},{id:48,info:"俄罗斯族",value:48,},{id:49,info:"裕固族",value:49,},{id:50,info:"乌孜别克族",value:50,},{id:51,info:"门巴族",value:51,},{id:52,info:"鄂伦春族",value:52,},{id:53,info:"独龙族",value:53,},{id:54,info:"塔塔尔族",value:54,},{id:55,info:"赫哲族",value:55,},{id:56,info:"珞巴族",value:56,}],
      ruleForm:{
        name: "",
        sex:"",
        birth:"",
        region:[],
        lifeAddress:"",
        nationality:"",
        teamId:"",
        description:"",
        idcard:"",
        idCardImgFront:"",
        idCardImgBack:"",
        avatar:"",
        certificateType:"",
        certificateImg:"",
        sysUserId:'',
        teamName:"",
      },
      activeName: '',
      rules: { // 表单验证规则
        name: [
          { required: true, message: '请输入姓名',trigger: 'blur'}
        ],
        sex:[
          { required: true, message: '请输入性别',trigger: 'blur'}
        ],
        birth:[
          { required: true, message: '请选择出生日期',trigger: 'blur'}
        ],
        region:[
          { type: 'array', required: true, message: '请选择省市区',trigger: 'blur'}
        ],
        lifeAddress:[
          { required: true, message: '请输入详细地址',trigger: 'blur'}
        ],
        nationality:[
          { required: true, message: '请选择民族',trigger: 'blur'}
        ],
        teamId:[
          { required: true, message: '请选择所属团队',trigger: 'blur'}
        ],
        description:[
          { required: true, message: '请输入个人简介',trigger: 'blur'},
          { min: 0, max: 500, message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        idcard:[
          { required: true,validator:checkIDCard,trigger:"blur"}
        ],
        avatar:[
          { required: true, message: '请上传您的头像',trigger: 'blur'}
        ],
        certificateImg:[
          { required: true,validator:checkCertificate,trigger:"blur"}
        ],
      }
    };
  },
  mounted(){




  },
  created() {
    var that=this;
    that.$watch('visible', function() {
     if(that.visible)
     {
        that.loadData();
        if(that.editAble)
        {
          that.loadTeam();
          if(that.user.isJGS())
          {
             that.rules.certificateImg[0]={ required: false,trigger:"blur"}
          }
          else{
             that.rules.certificateImg[0]={ required: false,trigger:"blur"};
          }
          
        }
        else{
          that.rules={};
        }
     }
    });
  },
  methods: {
    handleClose: function () {
      this.$emit('close');
    },
    sucessDialogClose(){
      this.sucessDialogVisible=false;
      this.$emit('close');
    },
    sucessDialogConfirm(){
      this.sucessDialogVisible=false;
      this.$emit('close');
    },
    handleRegionChange(){
      this.ruleForm.province= CodeToText[this.ruleForm.region[0]];
      this.ruleForm.city= CodeToText[this.ruleForm.region[1]];
      this.ruleForm.county= CodeToText[this.ruleForm.region[2]];
    },
    handleTeamChange(){
      var team=this.teamList.findWithKey('id',this.ruleForm.teamId);
      this.ruleForm.teamName=team.teamName;
    },
    loadTeam(){
      var that=this;
      that.api.teamQuery().then((response) => {
        var data=response.data;
        if(data.code==0)
        {
          that.teamList=data.data;
        }
        else
        {
          that.$message({message: data.msg?data.msg:'接口报错', type: 'error'})
        }
      });
    },
    loadData(){
      var that=this;
      if(that.userId)
      {
        that.api.doctorInfo({id:that.userId}).then((response) => {
          var data=response.data;
          if(data.code==0)
          {
            var region=[];
            var obj=data.doctor;

            if(obj.province)region[0]=TextToCode[obj.province].code;
            if(obj.city)region[1]=TextToCode[obj.province][obj.city].code;
            if(obj.county)region[2]=TextToCode[obj.province][obj.city][obj.county].code;
            console.log(region);
            that.ruleForm=obj;
            that.ruleForm.region=region;

          }
          else
          {
            that.$message({message: data.msg?data.msg:'接口报错', type: 'error'})
          }
        });
      }
      else{
        that.api.userBaseInfo().then((response) => {
          var data=response.data;
          if(data.code==0)
          {
            var region=[];
            var obj=data.data;

            if(obj.province)region[0]=TextToCode[obj.province].code;
            if(obj.city)region[1]=TextToCode[obj.province][obj.city].code;
            if(obj.county)region[2]=TextToCode[obj.province][obj.city][obj.county].code;
            console.log(region);
            that.ruleForm=obj;
            that.ruleForm.region=region;

          }
          else
          {
            that.$message({message: data.msg?data.msg:'接口报错', type: 'error'})
          }
        });
      }

    },
    submit(){
      var that=this;
      console.log('that.ruleForm',that.ruleForm);
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          that.api.userBaseSave(that.ruleForm).then((response) => {
            var data=response.data;
            if(data.code==0)
            {
              that.sucessDialogVisible=true;
              
            }
            else
            {
              that.$message.error(data.msg)

            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    uploadIDCardImgFront(file) {
      var that = this;
      if(that.beforeIDCARDUpload(file))
      {
        that.uploader.upload(file,function(response){
          var data=response.data;
          if(data.code==0)
          {
          that.ruleForm.idCardImgFront=response.data.data.url;
          }
          else
          {
          that.$message.error('上传身份证正面照片失败!');
          }
        });
      }
    },
    uploadIDCardImgBack(file) {
      var that = this;
      if(that.beforeIDCARDUpload(file))
      {
        that.uploader.upload(file,function(response){
           var data=response.data;
           if(data.code==0)
           {
           that.ruleForm.idCardImgBack=response.data.data.url;
           }
           else
           {
           that.$message.error('上传身份证反面照片失败!');
           }
        });
      }
    },
    uploadAvatarImg(file) {
      var that = this;
      if(that.beforeAvatarUpload(file))
      {
        that.uploader.upload(file,function(response){
          var data=response.data;
          if(data.code==0)
          {
          that.ruleForm.avatar=response.data.data.url;
          }
          else
          {
          that.$message.error('上传头像照片失败!');
          }
        });
      }
    },
    uploadCertificateImg(file) {
      var that = this;
      if(that.beforeCertificateImgUpload(file))
      {
        that.uploader.upload(file,function(response){
          var data=response.data;
          if(data.code==0)
          {
          that.ruleForm.certificateImg=response.data.data.url;
          }
          else
          {
          that.$message.error('上传头像照片失败!');
          }
        });
      }
    },
    beforeIDCARDUpload(file) {
      const isJPG = file.file.type === 'image/jpeg';
      const isPNG = file.file.type === 'image/png'
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      if (!isJPG&&!isPNG) {
        this.$message.error('上传身份证照片只能是 JPG或者PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传身份证照片大小不能超过 2MB!');
      }
      return (isJPG||isPNG) && isLt2M;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.file.type === 'image/jpeg';
      const isPNG = file.file.type === 'image/png'
      const isLt2M = file.file.size / 1024 / 1024 < 2;

      if (!isJPG&&!isPNG) {
        this.$message.error('上传头像图片只能是 JPG或者PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return (isJPG||isPNG) && isLt2M;
    },
    beforeCertificateImgUpload(file) {
      const isJPG = file.file.type === 'image/jpeg';
      const isPNG = file.file.type === 'image/png'
      const isLt2M = file.file.size / 1024 / 1024 < 2;

      if (!isJPG&&!isPNG) {
        this.$message.error('上传资格证书图片只能是 JPG或者PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传资格证书图片大小不能超过 2MB!');
      }
      return (isJPG||isPNG) && isLt2M;
    }
  },
};
</script>
<style lang="less" scoped="scoped">
.content-wrapper
{
  display: flex;
  align-items: center;
  flex-direction: column;
  background:#fff;

  .mid-content{
    width:auto;
  }
  .submitButton{
    width: 302px;
    height: 40px;
    background: #185AD6;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .region-input
  {
    width:268px;
    margin-left:8px;
  }

  .idcard-uploader-icon {
    font-size: 11px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    width: 194px;
    height: 107px;
    line-height: 107px;
    text-align: center;
  }
  .idcard {
    width: 194px;
    height: 107px;
    line-height: 107px;
    display: block;
  }
  .avatar-uploader-icon {
    font-size: 11px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
    line-height: 80px;
    display: block;
  }
  .certificateImg-uploader-icon {
    font-size: 11px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    width: 103px;
    height: 130px;
    line-height: 130px;
    text-align: center;
  }
  .certificateImg {
    width: 103px;
    height: 130px;
    line-height: 130px;
    display: block;
  }
  .certificateImg-wrapper
  {
    display:flex;
    flex-direction: column;
  }
}
.item-wrapper{
 width: 400px;
}
/deep/.el-upload {
  background: rgba(6, 107, 168, 0.04);
  border-radius: 5px;
  border: 2px solid rgba(6, 107, 168, 0.2);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.el-upload:hover {
  border-color: #409EFF;
}
/deep/.el-input
{
 width:302px;
 flex:1;
}
/deep/.el-textarea
{
  width: 578px;
  height: 102px;
}

/deep/.el-textarea__inner
{

    height: 100%;
    resize: none;
}
/deep/.el-input__icon
{
line-height:unset;
}
/deep/.el-form-item
{
display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom:30px
}
/deep/.el-form-item__label
{
position: absolute;
right: 100%;
top: 0;
width: 280px;
height: 100%;
display: flex;
justify-content: flex-end;
align-items: center;
line-height:unset;
}
/deep/.el-input__inner
{
  text-align: left;
  font-size: 12px;
  height:32px;
  &:-webkit-autofill {
    transition: background-color 99999s ease-in-out 0s !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.85) !important;
  }
  // 自动填充文本样式
  &:-webkit-autofill::first-line {
    font-size: 12px;
  }
}

/deep/.el-radio__label
{
font-size: 11px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #323233;
}
/deep/.el-form-item__content {
  line-height:unset;
  display: flex;
  align-items: center;
  position:relative;
}
/deep/.el-form-item__error
{
  font-size:10px;
}
/deep/.el-form
{
margin-top:20px;
}
/deep/.el-checkbox__input
{
line-height:unset;
display: flex;
}
/deep/.el-cascader
{
 line-height:unset;
}
</style>
