<template>
  <el-dialog
  title='用户换绑'
  :visible.sync="visible"
  :before-close="handleClose"
  append-to-body
  >
  <div class="container">
    <div class="header-left-desc">重要须知：<br>换绑行为仅限发生在当前团队人员中，一旦换绑，该用户将完全解除之前的绑定关系，请谨慎思考以后再进行换绑操作</div>
    <Input
      v-model="input"
      placeholder="请输入要绑定的医生姓名，再点击搜索"
      search
      enter-button="搜索"
      :style="{ width: '430px' }"
      @on-search="doSearch"
    />
    <div class="card-wrapper">
      <div v-for="(doctor, index) in doctors" :key="index" class="card">
         <div class="mask" @click="doctorClick(doctor)"/>
         <div class="card-selectbox"><el-checkbox @change="checked=>onChange(doctor)" v-model="doctor.checked"></el-checkbox></div>
         <img class="card-image" :src="doctor.avatar"/>
         <div class="card-title">{{doctor.name}}</div>

      </div>
    </div>
  </div>
  <div slot="footer" class="dialog-footer">
    <div v-if="showError" class="mid-content-bar-label">请勾选医生以后再点击确定</div>
    <el-button @click="handleClose">取 消</el-button>
    <el-button @click="handleConfirm" type="primary">确定</el-button>
  </div>
  </el-dialog>
</template>
<script>
export default {
  props: ["visible"],
  data() {
    return {
      input:'',
      showError:false,
      doctors:[],
      selectDoctor:null,
    };
  },
  created() {
    var that=this;
    that.$watch('visible', function() {
      if(!that.visible)
      {
        that.init();
      }
      
    });
  },
  methods: {
    init(){
      this.doctors=[];
      this.selectDoctor=null;
      this.input='';
      this.showError=false;
    },
    handleClose: function () {
      this.init();
      this.$emit('close');
    },
    handleConfirm(){
      if(!this.selectDoctor)
      {
      this.showError=true;
      return;
      }
      this.$emit('confirm',this.selectDoctor);
    },
    onChange(doctor){

      if(doctor.checked)
      {
       for(var i=0;i<this.doctors.length;i++)
       {
        var doc=this.doctors[i];
        if(doc.id!=doctor.id){
        doc.checked=false;
        }
       }

      }
      
      this.$forceUpdate();
      if(doctor.checked)this.selectDoctor=doctor;
      else this.selectDoctor=null;
      console.log(doctor,this.selectDoctor);
    },
    doctorClick(doctor){

      doctor.checked=!doctor.checked;
      this.onChange(doctor);
    },
    doSearch(){
      var that=this;
      if(!that.input)return;
      var parameter = {
        name: that.input,
      };

      that.api.doctorQuery(parameter).then((response) => {
        if (response.data.code == 0) {
          var doctors=response.data.data;
          if(!doctors)doctors=[];
          that.doctors=doctors;

        } else {
          that.$message({
            type: "success",
            message: response.data.msg,
          });
        }
      });
    }
  },
};
</script>
<style lang="less" scoped="scoped">
.container{
  display: grid;
  background:#fff;
  padding:20px 30px;
  .header-left-desc
  {
  margin-bottom: 20px;
  font-size: 11px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  }
  .card-wrapper{
  margin-top: 10px;
  display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .card
  {
  display: flex;
  position: relative;
  width: 166px;
  height: 75px;
  background: #F6F6F8;
  border-radius: 3px;
  align-items: center;
    padding: 10px;

  }
  .card-image
  {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  }
  .card-title
  {
  font-size: 11px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 8px;
  }
  .card-icon
  {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
  }
  .card-selectbox{
  position: absolute;
  left: 0;
  top: 0;
  }
  .mask{
  position: absolute;
  left: 0;
  top: 0;
  width:100%;
  height:100%;
  }
}
.mid-content-bar-label{
font-size: 10px;
font-family: SourceHanSansCN-Normal, SourceHanSansCN;
font-weight: 400;
color: #D60000;
margin-right:10px;
}
.dialog-footer{
display: flex;
    justify-content: flex-end;
    align-items: center;
}
/deep/.ivu-input-search
{
background: #185AD6 !important;
border-radius: 2px;
font-size: 13px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.65);
}
/deep/.el-dialog__body{

}
/deep/.el-dialog{
display: grid;
grid-template-rows: auto 1fr;
}

</style>
