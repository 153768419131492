import { render, staticRenderFns } from "./nutritionApplyPlan.vue?vue&type=template&id=87e56018&scoped=true"
import script from "./nutritionApplyPlan.vue?vue&type=script&lang=js"
export * from "./nutritionApplyPlan.vue?vue&type=script&lang=js"
import style0 from "./nutritionApplyPlan.vue?vue&type=style&index=0&id=87e56018&prod&scoped=true&lang=css"
import style1 from "./nutritionApplyPlan.vue?vue&type=style&index=1&id=87e56018&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87e56018",
  null
  
)

export default component.exports