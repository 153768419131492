<template>
  <el-dialog
  :visible.sync="visible"
  :before-close="handleClose"
  width='300px'
  >
  <div class="container">
    <textarea v-model="doctorLink" id="copytextarea"></textarea>
  </div>
  <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button @click="handleConfirm" type="primary">复制</el-button>
  </div>
  </el-dialog>
</template>
<script>
export default {
  props: ["doctorLink","visible"],
  components: {

  },
  data() {
    return {
      
    };
  },
  mounted(){


  },
  created() {
    
    
  },
  methods: {
    handleClose: function () {
      this.$emit('close');
    },
    handleConfirm(){
      var Url2 = document.getElementById("copytextarea");
      Url2.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      alert("已复制好，可贴粘。");
    }
  },
};
</script>
<style lang="less" scoped="scoped">
.container{
  position:relative;
}
/deep/.el-dialog__header{
 padding:0;
}
textarea{
  width: 100%;
    height: 100px;
    border: 0;
    padding: 2px;
}
/deep/.el-dialog__body
{
  padding:0;
}
</style>
