<template>
  <el-dialog
  :visible.sync="visible"
  width="565px"
  :before-close="handleClose"
  >
   <div class="dialog-content">
     <div class="dialog-content-title">该{{teamLabel}}已离职?</div>
     <div class="dialog-content-subtitle">请确保先将该员工的全部用户换绑至团队内其他健管师再进行操作</div>
     <div class="dialog-content-desc">重要须知：<br>换绑仅限将用户换至当前团队人员<br>一旦换绑，该用户将完全解除之前的绑定关系，请谨慎思考以后再进行换绑操作<br>一旦关闭该员工账号，该员工账号将被永久停止，请谨慎操作</div>
   </div>
   <div slot="footer" class="dialog-footer">
     <el-button @click="handleClose">点错了</el-button>
     <el-button @click="handleConfirm" type="primary">已全部换绑,继续关停此账号</el-button>
   </div>
  </el-dialog>
</template>
<script>
export default {
  props: ["visible","teamLabel"],
  components: {

  },
  data() {
    return {


    };
  },
  mounted(){


  },
  created() {

  },
  methods: {
    handleClose: function () {
      this.$emit('close');
    },
    handleConfirm(){
      this.$emit('confirm');
    }
  },
};
</script>
<style lang="less" scoped="scoped">
.dialog-content{
  
  background:#fff;
  display: flex;
flex-direction: column;
align-items: center;
  .dialog-content-title{
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    width: 50%;
    text-align: center;
  }
  .dialog-content-subtitle{
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  width: 50%;
text-align: center;
margin-top: 30px;
    margin-bottom: 30px;
  }
  .dialog-content-desc{
  font-size: 14px;
font-family: SourceHanSansCN-Normal, SourceHanSansCN;
font-weight: 400;
color: rgba(0, 0, 0, 0.85);
  }
}

/deep/.el-dialog__header
{
  border:0;
}
</style>
