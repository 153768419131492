<template>
  <div ref="main_tab" class="el-main-tab">
    <el-collapse v-model="activeNames" @change="handleChange" class="elementary">
      <el-collapse-item
        v-for="(item, index) in basics"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <p class="eleHead">
            <span class="hdate">{{ item.generateTime }}</span>
          </p>
        </template>
        <div class="elementaryCon">
            <div class="card">
              <div class="header">{{item.userReportSleepInfo.userReportSleepMonitorInfo.title}}</div>
              <div class="row">
                 <div v-for="(monitorInfo,ind) in item.userReportSleepInfo.userReportSleepMonitorInfo.monitorInfos" :key="ind" class="row-f">
                   <div class="iconfont">
                     <el-image style="width:20px;height:20px;" :src="monitorInfo.img"/>
                   </div>
                   <div class="line">
                       <div class="vline"/>
                   </div>
                   <div class="row-content">
                     <div class="title">{{monitorInfo.title}}</div>
                     <div class="value">{{monitorInfo.value}}</div>
                   </div>
                 </div>
              </div>
            </div>
            <div v-for="(monitor,ind) in item.userReportSleepInfo.monitors" :key="ind" class="card">
               <div class="monitor-header">{{monitor.title}}</div>
               <div class="monitor-row monitor-header-title">
                 <div class="monitor-row-left">{{monitor.head.title}}</div>
                 <div class="monitor-row-center">{{monitor.head.value}}</div>
                 <div class="monitor-row-right">{{monitor.head.range}}</div>
               </div>
               <div v-for="(item,index) in monitor.body" :key="index" class="monitor-row monitor-title">
                 <div v-if="item" class="monitor-row-left">{{item.title}}</div>
                 <div v-if="item" class="monitor-row-center">{{item.value}}</div>
                 <div v-if="item" class="monitor-row-right">{{item.range}}</div>
               </div>
            </div>
            <div style="height:20px"/>
            <div class="card chart-wrapper" v-bind:ref="('xltdChart-'+(index))">
               <div class="chart-title">心率/呼吸/体动</div>
               <div class="chart-subtitle">单位:次/分钟</div>
               <div class="chart" v-bind:id="('xltdChart-'+(index))" style="width:100%;height:260px;"/>
               <div class="xltd-chart-legend">
                 <div class="xltd-chart-legend-item-wrapper">
                     <div class="xltd-chart-legend-item-dot" style="background:#F5696B"/>
                     <div class="xltd-chart-legend-item-title">
                        心率
                     </div>
                 </div>
                 <div class="xltd-chart-legend-item-wrapper">
                     <div class="xltd-chart-legend-item-dot" style="background:#21C596"/>
                     <div class="xltd-chart-legend-item-title">
                        呼吸
                     </div>
                 </div>
                 <div class="xltd-chart-legend-item-wrapper">
                     <div class="xltd-chart-legend-item-dot" style="background:#264AFF"/>
                     <div class="xltd-chart-legend-item-title">
                        体动
                     </div>
                 </div>
               </div>
            </div>
            <div class="card chart-wrapper">
               <div class="chart-title">打鼾</div>
               <div class="chart-subtitle">单位:次/分钟</div>
               <div class="chart" v-bind:id="('dhChart-'+(index))" style="width:100%;height:230px;"/>

            </div>
            <div class="card chart-wrapper">
               <div class="chart-title">睡眠阶段图</div>
               <div class="chart" v-bind:id="('smjdChart-'+(index))" style="width:100%;height:420px;"/>
               <div class="smjd-chart-legend">
                 <div v-for="(status,index) in smjdStatusArray" :key="index" class="smjd-chart-legend-item-wrapper">
                     <div class="smjd-chart-legend-item-dot" :style="{'background':smjdColors[index]}"/>
                     <div class="smjd-chart-legend-item-title">
                        {{status}}
                     </div>
                 </div>

               </div>
            </div>

        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      activeNames: 0,
      basics: [],
      charts:[],
      userId:'',
      smjdColors:['#a8dd89','#f7c052','#800000','#33ddf8','#FF00FF'],
      smjdStatusArray:['深睡','浅睡','REM','清醒','离床']
    };
  },
  created() {
    var that=this;
    that.userId = this.$route.query.userId;
    that.compocsition();
    window.onresize=function(){
       for(var i=0;i<that.charts.length;i++)
       {
        var chart=that.charts[i];
        chart.resize();
       }
    }
  },
  methods: {
    compocsition() {
      var that = this;
      var parameter = {
        userId: that.userId,
      };
      api.jkdasleep(parameter).then((response) => {
        if (response.data.code == 0) {
          var datas=response.data.data;
          if(datas&&datas.length>0)
          {
           for(var i=0;i<datas.length;i++){
             var data=datas[i].userReportSleepInfo;
             console.log(2223);
             var monitors=[];
             monitors.push(data.userReportSleepStagesInfo);
             monitors.push(data.userReportSleepBreathEventInfo);
             monitors.push(data.userReportSleepHeartHealthIndexInfo);
             data.monitors=monitors;
           }

           that.basics =datas;
           that.loadXltdChart(0);
           that.loadDhChart(0);
           that.loadSmjdChart(0);
          }

        }
      });
    },
    handleChange(val) {
      for(var i=0;i<val.length;i++)
      {
        var v=val[i];
        this.loadXltdChart(v);
        this.loadDhChart(v);
        this.loadSmjdChart(v);
      }
    },
    loadSmjdChart(index){
      var that=this;

      var pieces=[];
      var series=[
        {
          name: '睡眠阶段',
          type: 'line',
          symbol:'none',
          data: [],
        }
      ];
      var xAxisDatas=[];

      var sleepData=this.basics[index];
      var userReportSleepInfo=sleepData.userReportSleepInfo;
      var chartData=userReportSleepInfo.userReportSleepChartInfo;
      if(chartData)
      {

        var timeScale=30000;

        var data=[];
        var sleepRangeCoordinateList=chartData.userReportSleepRangeInfo.coordinateList;

        var start=9999999999999999;
        var end=0;
        for(var i=0;i<sleepRangeCoordinateList.length;i++){
          var sleepRange=sleepRangeCoordinateList[i];
          var start_t=(sleepRange.start/1);
          var end_t=(sleepRange.end/1);
          start=start>start_t?start_t:start;
          end=end>end_t?end:end_t;
        }
        var count=parseInt((end-start)/timeScale);
        for(var ii=0;ii<count;ii++){
           var t=start+ii*timeScale;
           var date= new Date(t);
           var time=date.format("hh:mm");
           xAxisDatas.push(time);
           data.push(null);
        }
        var pieces_start=0;
        for(var iii=0;iii<sleepRangeCoordinateList.length;iii++){
           var coordinate=sleepRangeCoordinateList[iii];
           var sleepStatus=coordinate.sleepStatus;

           var start_tt=coordinate.start/1;
           var end_tt=coordinate.end/1;
           var start_index=parseInt(((start_tt-start)/timeScale));
           var end_index=parseInt(((end_tt-start)/timeScale));

           var count_t=parseInt((end_tt-start_tt)/timeScale);
           for(var l=0;l<count_t;l++)
           {
             data[start_index+l]=sleepStatus-1;
           }


           var interval=0;
           if(start_index!=0){
             var color='#b1b1b1';
             if((pieces_start+interval)!=start_index)
             {
               for(var ll=0;ll<(start_index-pieces_start);ll++){
                  data[pieces_start+ll]=4;
               }
               color=this.smjdColors[4];
             }

             pieces.push({min:pieces_start,max:start_index,color:color});
             start_index+=interval;
           }

           pieces.push({min:start_index,max:end_index-interval,color:this.smjdColors[sleepStatus-1]});
           pieces_start=end_index-interval;
        }
        series[0].data=data;


        var option={
          color: this.smjdColors,
          backgroundColor: "transparent",
          tooltip: {
            trigger: 'axis',
            show:false,
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            right: '4%',
            top: '8%',
            bottom: '18%',
            left:'4%'
          },
          xAxis: [
            {
              axisTick:{
                show:false,
              },
              axisLine:{
                show:false,
              },
              axisLabel:{
                show:true,
                showMaxLabel: true,
                showMinLabel:true,
                interval: 'auto',
                rotate: 60,
                textStyle: {
                  color: '#1C2833',
                  fontFamily: 'sans-serif',
                  fontSize: 10,
                  fontStyle: 'italic'
                }
              },
              data: xAxisDatas
            }
          ],
          yAxis: [
            {
              name: '',
              position: 'left',
              alignTicks: true,
              axisTick:{
                show:false
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: '#999'
                }
              },
              splitLine:{
                show:true,
                lineStyle:{
                  color:'#eeeeee'
                }
              },
              axisLabel:{
                show:true,
                showMaxLabel: true,
                showMinLabel:true,
                interval: 'auto',

              },
              type: 'category',
              data: this.smjdStatusArray
            }
          ],
          visualMap: {
            show: false,
            dimension: 0,
            pieces: pieces
          },
          series: series
        };

        // 绘制图表
        var chartId="smjdChart-"+index;
        console.log(chartId);
        this.$nextTick(() => {
          var myChart = this.$echarts.init(document.getElementById(chartId));
          option && myChart.setOption(option, true);
          if(that.charts.indexOf(myChart)==-1)
          that.charts.push(myChart);
          console.log(that.charts);
        })
      }
    },
    loadDhChart(index){
      var that=this;
      var colors=['#2f7cfb'];
      var sleepData=this.basics[index];
      var userReportSleepInfo=sleepData.userReportSleepInfo;
      var chartData=userReportSleepInfo.userReportSleepChartInfo;

      if(chartData)
      {
        var xAxisDatas=[];
        var series=[
          {
            name: '打鼾',
            type: 'bar',
            data: []
          }
        ];

        var data0=[];

        var snoreCoordinateList=chartData.userReportSleepSnoreInfo.coordinateList;
        snoreCoordinateList.sort(function(a, b){return a.xvalue - b.xvalue});
        var start=0;
        var end=0;
        if(snoreCoordinateList.length>0)
        {
          start=snoreCoordinateList[0].xvalue/1;
          end=snoreCoordinateList[snoreCoordinateList.length-1].xvalue/1;
        }
        var timeScale=30000;
        var count=(end-start)/timeScale;
        for(var i=0;i<count+1;i++)
        {
          var t=start+i*timeScale;
          var date= new Date(t/1);
          var time=date.format("hh:mm");
          xAxisDatas.push(time);

          var snoreCoordinate=snoreCoordinateList.findWithKey("xvalue", t);
          if(snoreCoordinate)
          {
          data0.push(snoreCoordinate.yvalue);
          }
          else{
          data0.push(null);
          }
        }
        series[0].data=data0;


        var option={
          color: colors,
          backgroundColor: "transparent",
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            right: '4%',
            top: '8%',
            bottom: '18%',
            left:'4%'
          },
          xAxis: [
            {
              axisTick:{
                show:false,
              },
              axisLine:{
                show:false,
              },
              axisLabel:{
                show:true,
                showMaxLabel: true,
                showMinLabel:true,
                interval: 'auto',
                rotate: 60,
                textStyle: {
                  color: '#1C2833',
                  fontFamily: 'sans-serif',
                  fontSize: 10,
                  fontStyle: 'italic'
                }
              },
              data: xAxisDatas
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '',
              position: 'left',
              alignTicks: true,
              axisTick:{
                show:false,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: '#999'
                }
              },
              splitLine:{
                show:true,
                lineStyle:{
                  color:'#eeeeee'
                }
              },
              axisLabel: {
                formatter: '{value}'
              }
            }
          ],
          series: series
        };
        // 绘制图表
        var chartId="dhChart-"+index;
        console.log(chartId);
        this.$nextTick(() => {
          var myChart = this.$echarts.init(document.getElementById(chartId));
          option && myChart.setOption(option, true);
          if(that.charts.indexOf(myChart)==-1)
          that.charts.push(myChart);
          console.log(that.charts);
        })
      }
    },
    loadXltdChart(index){
      var that=this;
      var sleepData=this.basics[index];
      var userReportSleepInfo=sleepData.userReportSleepInfo;
      var chartData=userReportSleepInfo.userReportSleepChartInfo;

      if(chartData)
      {

        chartData.ok=true;
        var colors=['#de5f6a', '#78cab0', '#2586e0'];
        var xAxisDatas=[];
        var series=[
          {
            name: '心率',
            type: 'line',
            symbol:'none',
            data: [],
            markLine: {
              silent: true,
              symbol:'none',
              lineStyle:{
                type: 'dashed',
                dashOffset: 10,
                color:colors[0]
              },
              data: [
                {
                  yAxis: 50,
                  label:{
                  show:false
                  }
                }
              ]
            }
          },
          {
            name: '呼吸',
            type: 'line',
            data: [],
            symbol:'none',
            markLine: {
              silent: true,
              symbol:'none',
              lineStyle:{
                type: 'dashed',
                dashOffset: 10,
                color:colors[1]
              },
              data: [
                {
                  yAxis: 20,
                  label:{
                  show:false
                  }
                }
              ]
            }
          },
          {
            name: '体动',
            type: 'bar',
            data: [],
            markLine: {
              silent: true,
              symbol:'none',
              lineStyle:{
                type: 'dashed',
                dashOffset: 10,
                color:colors[2]
              },
              data: [
                {
                  yAxis: 10,
                  label:{
                  show:false
                  }
                }
              ]
            }
          }
        ];

        var timeDatas=[];
        var obj={};
        var heartCoordinateList=chartData.userReportSleepHeartInfo.coordinateList;
        var breathCoordinateList=chartData.userReportSleepBreathInfo.coordinateList;
        var bodyMotionCoordinateList=chartData.userReportSleepBodyMotionInfo.coordinateList;
        var coordinateList=[];
        coordinateList = coordinateList.concat(heartCoordinateList);
        coordinateList = coordinateList.concat(breathCoordinateList);
        coordinateList = coordinateList.concat(bodyMotionCoordinateList);

        coordinateList.sort(function(a, b){return a.xvalue - b.xvalue});

        for(var i=0;i<coordinateList.length;i++){
          var coordinate=coordinateList[i];
          var datetime=coordinate.xvalue;
          if(datetime)
          {
            var date= new Date(datetime.iphoneDateFormat()/1);
            var time=date.format("yyyy-MM-dd hh:mm:ss");

            if(!obj[time])
            {
            obj[time]=time;
            timeDatas.push(time);
            }
            coordinate.time=time;

          }
        }


        var data0=[];
        var data1=[];
        var data2=[];

        for(var ii=0;ii<timeDatas.length;ii++){
           var timeData=timeDatas[ii];
           var date_= new Date(timeData.iphoneDateFormat());
           var ttime=date_.format("hh:mm");
           xAxisDatas.push(ttime);

           var heartCoordinate=heartCoordinateList.findWithKey("time", timeData);
           if(heartCoordinate)
           {
           data0.push(heartCoordinate.yvalue);
           }
           else{
           data0.push(null);
           }
           var breathCoordinate=breathCoordinateList.findWithKey("time", timeData);
           if(breathCoordinate)
           {
           data1.push(breathCoordinate.yvalue);
           }
           else{
           data1.push(null);
           }
           var bodyMotionCoordinate=bodyMotionCoordinateList.findWithKey("time", timeData);
           if(bodyMotionCoordinate)
           {
           data2.push(bodyMotionCoordinate.yvalue);
           }
           else{
           data2.push(null);
           }
        }
        console.log(9909);
        series[0].data=data0;
        series[1].data=data1;
        series[2].data=data2;



        var option={
          color: colors,
          backgroundColor: "transparent",
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            right: '4%',
            top: '8%',
            bottom: '18%',
            left:'4%'
          },
          xAxis: [
            {
              axisTick:{
                show:false,
              },
              axisLine:{
                show:false,
              },
              axisLabel:{
                show:true,
                showMaxLabel: true,
                showMinLabel:true,
                interval: 'auto',
                rotate: 60,
                textStyle: {
                  color: '#1C2833',
                  fontFamily: 'sans-serif',
                  fontSize: 10,
                  fontStyle: 'italic'
                }
              },
              data: xAxisDatas
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '',
              position: 'left',
              alignTicks: true,
              axisTick:{
                show:false,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: '#999'
                }
              },
              splitLine:{
                show:true,
                lineStyle:{
                  color:'#eeeeee'
                }
              },
              axisLabel: {
                formatter: '{value}'
              }
            }
          ],
          series: series
        };
        // 绘制图表
        var chartId="xltdChart-"+index;
        console.log(chartId);
        this.$nextTick(() => {
          var myChart = this.$echarts.init(document.getElementById(chartId));
          option && myChart.setOption(option, true);
          if(that.charts.indexOf(myChart)==-1)
          that.charts.push(myChart);
          console.log(that.charts);
        })


      }


    }
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  .eleHead {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
    font-weight: bold;
    .hdate {
      color: #185ad6;
      margin-left:0px;
    }
    .hunfold {
      float: right;
    }
  }
  .elementaryCon{
    .card{
      padding-left:20px;
      padding-right:20px;
    }
    .header {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 60px;
      width:400px;

    }
    .vline {
      width: 1px;
      height: 42px;
      background: #f1f1f2;
    }
    .row-f {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .iconfont {
      flex-basis: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .line {
      flex-basis: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .row-content {
      flex: 1;
    }
    .title {
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
    .value {
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }


    .monitor-row {
      display: grid;
      grid-template-columns: 1.5fr 1fr 1fr;
      width:500px;
    }
    .monitor-row-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .monitor-row-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .monitor-row-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .monitor-header-title {
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      border-bottom: 1px #f2f2f2 solid;
      padding-bottom: 10px;
      padding-top: 10px;
    }
    .monitor-title {
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-top: 10px;
    }
    .monitor-header {
      font-size: 16px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 20px;
    }
    .chart-title
    {
      font-size: 16px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
    .chart-subtitle
    {
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
    .chart-wrapper
    {
    margin-top:20px;
    }
  }
  .xltd-chart-legend
  {
  display:flex;
  justify-content:space-around;
  padding-left: 15px;
      padding-right: 15px;
      height:34px;
      margin-bottom: 10px;
  }
  .xltd-chart-legend-item-wrapper
  {
  display: flex;
      align-items: center;
  }
  .xltd-chart-legend-item-dot
  {
  height: 6px;
      width: 6px;
      border-radius: 3px;
      margin-right: 5px;
  }
  .xltd-chart-legend-item-title
  {
  font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1C2833;
  }
  .smjd-chart-legend
  {
  display:flex;
  justify-content:space-around;
  padding-left: 15px;
      padding-right: 15px;
      height:17px;
      margin-bottom: 10px;
  }
  .smjd-chart-legend-item-wrapper
  {
  display: flex;
      align-items: center;
  }
  .smjd-chart-legend-item-dot
  {
  height: 10px;
      width: 20px;
      margin-right: 5px;
  }
  .smjd-chart-legend-item-title
  {
  font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1C2833;
  }
}
</style>
