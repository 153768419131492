<template>
  <el-dialog title="登录管理" :visible="visible" :beforeClose="handleClose">
    <!-- 设置表单 -->
    <Form
      ref="formValidate"
      :model="formValidate"
      :rules="ruleValidate"
      :label-width="120"
    >
      <FormItem label="设置用户名" prop="username">
        <Input v-model="formValidate.username" placeholder="请输入"></Input>
      </FormItem>
      <FormItem label="设置密码" prop="password">
        <Input v-model="formValidate.password" placeholder="请输入"></Input>
      </FormItem>
      <FormItem label="再次输入密码" prop="passwordAgain">
        <Input
          v-model="formValidate.passwordAgain"
          placeholder="请输入"
        ></Input>
      </FormItem>
    </Form>
    <!-- 自定义页脚 -->
    <div slot="footer">
      <Button class="early-btn" @click="handleClose">关闭</Button>
      <Button
        class="early-btn"
        type="primary"
        @click="handleSubmit('formValidate')"
        >提交</Button
      >
    </div>
  </el-dialog>
</template>
<script>
/**
 * 登录设置弹窗
 * @description 处理弹窗
 * @property {Boolean} visible 是否可见
 * @property {Object} form 表单项
 * @property {Boolean} visible 是否可见
 * @property {Object} form 表单项
 * @property {Function} submit 提交
 * @property {Function} close 关闭
 */
export default {
  name: "login-modal",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      dafault: {
        username: "",
        password: "",
      },
    },
    submit: {
      type: Function,
    },
    close: {
      type: Function,
    },
  },

  computed: {
    formValidate: function () {
      const { username = "", password = "" } = this.form;
      return {
        username,
        password,
        passwordAgain: password,
      };
    },
  },

  data() {
    return {
      ruleValidate: {
        username: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur",
          },
          {
            type: "string",
            validator: (rule, value) => value.length >= 6 && value.length <= 8,
            message: "密码不少于6位且不多于8位",
            trigger: "blur",
          },
        ],

        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
          {
            type: "string",
            validator: (rule, value) => value.length >= 6 && value.length <= 8,
            message: "密码不少于6位且不多于8位",
            trigger: "blur",
          },
        ],

        passwordAgain: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
          {
            type: "string",
            validator: (rule, value) => value.length >= 6 && value.length <= 8,
            message: "再次输入的密码不少于6位且不多于8位",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    validatePassword() {
      const {
        formValidate: { password, passwordAgain },
      } = this;

      return password === passwordAgain;
    },
    handleSubmit() {
      this.$refs["formValidate"].validate((valid) => {
        if (valid) {
          if (this.validatePassword()) {
            this.$message.success("提交成功!");
            // 关闭弹窗
            this.$emit("submit", this.form);
          } else {
            this.$message.error("您填写的密码前后不一致");
          }
        } else {
          this.$message.error("请您填写正确格式的数据!");
        }
      });
    },
    handleClose: function () {
      // 关闭弹窗
      this.$emit("close");
    },
  },
};
</script>
