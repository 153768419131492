<template>
  <el-dialog
  :visible.sync="visible"
  width="800px"
  :before-close="handleClose"
  >
   <div class="dialog-content">
     <div class="dialog-content-title">是否关停该账号！</div>

     <div class="dialog-checkbox">
       <el-checkbox @change="change" v-model="checked">停止该账号</el-checkbox>
     </div>
     <div class="dialog-content-desc">一旦停止该员工账号，该员工账号将被永久停止，请谨慎操作</div>
   </div>
   <div slot="footer" class="dialog-footer">
     <div v-if="showError" class="dialog-content-subtitle">请勾选以后再提交</div>
     <el-button @click="handleClose">取消</el-button>
     <el-button @click="handleConfirm" type="primary">确认无误，确认关停</el-button>
   </div>
  </el-dialog>
</template>
<script>
export default {
  props: ["visible",'userId'],
  components: {

  },
  data() {
    return {
      checked:false,
      showError:false,
    };
  },
  mounted(){


  },
  created() {

  },
  methods: {
    change(){
      if(this.checked) this.showError=false;
    },
    handleClose: function () {
      this.checked=false;
      this.$emit('close');
    },
    handleConfirm(){
      var that=this;
      if(!that.checked)
      {
       that.showError=true;
      }
      else{
       that.api.doctorQuit({doctorId:that.userId}).then((response) => {
         response = response.data;
         if (response.code == 0) {
           that.checked=false;
           that.$emit('confirm');
         }
         else{
         that.$message.error(response.msg);
         }
       });

      }

    }
  },
};
</script>
<style lang="less" scoped="scoped">
.dialog-content{
  
  background:#fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .dialog-content-title{
    font-size: 25px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    width: 70%;
    text-align: center;
    margin-bottom:30px;
  }

  .dialog-content-desc{
  font-size: 20px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  padding-left: 26px;
  }
  .dialog-checkbox{
  width: 100%;
  margin-top:30px;
  }
}
.dialog-footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dialog-content-subtitle{
font-size: 14px;
font-family: SourceHanSansCN-Medium, SourceHanSansCN;
font-weight: 500;
color: #D60000;
margin-right:10px;
}

/deep/.el-checkbox
{
display: flex;
    align-items: center;
}

/deep/.el-checkbox__label
{
font-size: 20px;
font-family: SourceHanSansCN-Normal, SourceHanSansCN;
font-weight: 400;
color: rgba(0, 0, 0, 0.85);
}

/deep/.el-dialog__header
{
  border:0;
}
</style>
