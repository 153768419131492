<template>
  <el-dialog title="线下服务管理" :visible="visible" :beforeClose="handleClose">
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="160" class="bussiness-form">
      <FormItem label="名称:" prop="name">
        <Input v-model="formValidate.name" disabled></Input>
      </FormItem>

      <FormItem label="价格:" prop="nowPrice">
        <Input v-model="formValidate.nowPrice" type="number"></Input>
      </FormItem>

      <FormItem label="原价:" prop="price">
        <Input v-model="formValidate.price" type="number"></Input>
      </FormItem>

      <FormItem label="预约人数:" prop="subscribeNum">
        <Input v-model="formValidate.subscribeNum" type="number" disabled></Input>
      </FormItem>

      <FormItem label="上架状态:" prop="status">
        <RadioGroup v-model="formValidate.onLine">
          <Radio :label="statusObj['up']">
            <span>上架</span>
          </Radio>
          <Radio :label="statusObj['down']">
            <span>下架</span>
          </Radio>
        </RadioGroup>
      </FormItem>

      <FormItem label="主图：（1张，800*800" prop="mainPic">
        <el-upload :multiple="false" :limit="1" :on-exceed="handleExceed" action="" :file-list="formValidate.imgList" list-type="picture-card" :http-request="uploadSectionFile" :on-remove="handleImgRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </FormItem>

      <FormItem label="顶图：（1张）" prop="topPic">
        <el-upload :multiple="false" :limit="1" :on-exceed="handleExceed" action="" :file-list="formValidate.topList" list-type="picture-card" :http-request="uploadTopSectionFile" :on-remove="handleTopImgRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </FormItem>
      <FormItem label="团购详情图：（5张）">
        <el-upload multiple :limit="5" :on-exceed="handleGroupExceed" action="" :file-list="groupList" list-type="picture-card" :http-request="uploadGroupSectionFile" :on-remove="handleGroupImgRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </FormItem>

      <FormItem label="针对领域:" prop="checkedFields">
        <el-checkbox-group v-model="checked">
          <el-checkbox v-for="field in fieldOptions" :key="field" :label="field">{{field}}</el-checkbox>
        </el-checkbox-group>
      </FormItem>
    </Form>
    <div slot="footer" class="dialog-footer">
      <Button class="early-btn" @click="handleClose">关闭</Button>
      <Button class="early-btn" type="primary" @click="handleSubmit('formValidate')">提交</Button>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";
/**
 * 线下服务弹窗
 * @description 处理弹窗
 * @property {Boolean} visible 是否可见
 * @property {Object} originForm 默认表单项
 * @property {Function} submit 提交
 * @property {Function} close 取消
 */

export default {
  name: "offline-service-modal",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      dafault: {},
    },
    submit: {
      type: Function,
    },
    close: {
      type: Function,
    },
    fields: {
      type: Array,
    },
  },
  watch: {
    form: function (value) {
      const { humanClassifyName, groupBugImg } = value;

      // 初始化领域
      this.checked = humanClassifyName
        ? value.humanClassifyName.split(",")
        : [];

      // 初始化团购
      const groupPics = groupBugImg ? groupBugImg.split(",") : [];
      this.groupList = groupPics.map((url) => ({
        name: "group" + url,
        url,
      }));
    },
  },
  computed: {
    formValidate: function () {
      const form = Object.assign({}, this.form);

      const { img, topImg } = form;

      // 初始化上传文件列表
      form.imgList = img ? [{ name: "img", url: img }] : [];
      form.topList = topImg ? [{ name: "top", url: topImg }] : [];

      return form;
    },
    // checked: function () {
    //   const { humanClassifyId } = this.form;
    //   // 初始化领域列表
    //   const humanClassifyIds = humanClassifyId
    //     ? humanClassifyId.split(",")
    //     : [];
    //   return this.fields
    //     .filter((item) => humanClassifyIds.includes(item.id))
    //     .map((item) => item.classifyName);
    // },
    fieldOptions: function () {
      return this.fields.map((item) => item.classifyName);
    },
  },
  data() {
    const validatePrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("不能为空"));
      } else {
        callback();
      }
    };
    const validateFields = (rule, value, callback) => {
      callback();
      // console.log(1111, value);
      // if (!value) {
      //   return callback(new Error("不能为空"));
      // }
      // // 模拟异步验证效果
      // setTimeout(() => {
      //   if (!value.length) {
      //     callback(new Error("至少选择一项"));
      //   } else {
      //     callback();
      //   }
      // }, 1000);
    };
    return {
      groupList: [],
      checked: [],
      statusObj: {
        up: 1,
        down: 2,
      },
      ruleValidate: {
        nowPrice: [
          {
            validator: validatePrice,
            trigger: "blur",
          },
        ],
        price: [
          {
            validator: validatePrice,
            trigger: "blur",
          },
        ],
        checkedFields: [
          {
            validator: validateFields,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleExceed() {
      this.$message.warning("当前限制选择 1 个文件");
    },
    handleGroupExceed() {
      this.$message.warning("当前限制选择 5 个文件");
    },
    handleChange(file) {
      //上传成功与否都会触发
      console.log("change", file);
    },
    uploadSectionFile(file) {
      const vm = this;
      const parameter = new FormData();
      parameter.append("file", file.file);
      axios.post("/api/file/upload", parameter).then((res) => {
        const {
          data: {
            data: { url },
          },
        } = res;
        vm.handleUploadSuccess(url);
      });
    },
    uploadTopSectionFile(file) {
      const vm = this;
      const parameter = new FormData();
      parameter.append("file", file.file);
      axios.post("/api/file/upload", parameter).then((res) => {
        const {
          data: {
            data: { url },
          },
        } = res;
        vm.handleTopUploadSuccess(url);
      });
    },
    uploadGroupSectionFile(file) {
      const vm = this;
      const parameter = new FormData();
      parameter.append("file", file.file);
      axios.post("/api/file/upload", parameter).then((res) => {
        const {
          data: {
            data: { url },
          },
        } = res;
        vm.handleGroupUploadSuccess(url);
      });
    },
    handleUploadSuccess(url) {
      this.formValidate.img = url;
    },
    handleTopUploadSuccess(url) {
      this.formValidate.topImg = url;
    },
    handleGroupUploadSuccess(url) {
      this.groupList.push({ name: "group" + url, url });
    },
    handleImgRemove() {
      this.formValidate.img = "";
    },

    handleTopImgRemove() {
      this.formValidate.topImg = "";
    },
    handleGroupImgRemove(file) {
      console.log(file.url);
      const newList = this.groupList.filter((item) => item.url !== file.url);
      this.groupList = newList;
    },
    handleGroupSuccess(response, file) {
      this.formValidate.groupPics.push(file);
    },
    handleSubmit(name) {
      // 表单校验
      const { checked, fields, formValidate, groupList } = this;

      const form = Object.assign({}, formValidate);

      // 格式化数据
      form.groupBugImg = groupList.map((item) => item.url).join(",");

      form.humanClassifyId = fields
        .filter((item) => checked.includes(item.classifyName))
        .map((item) => item.id)
        .join(",");
      form.humanClassifyName = checked.join(",");

      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$message.success("提交成功!");

          this.$emit("submit", form);
        } else {
          this.$message.error("请填写正确格式的数据");
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>
