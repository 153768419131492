<template>
  <div>
    <div class="elementary">
      <h4>现有症状&既往史&家族史</h4>
      <div class="elesvdk" v-if="basics.illnessInfo">
        <dl v-for="(item, index) in basics.illnessInfo" :key="index">
          <dt>{{ item.title }}</dt>
          <dd v-for="(citem, index) in item.content" :key="index">
            {{ citem }}
          </dd>
        </dl>
      </div>
    </div>
    <div class="elementary" v-if="basics.userBodyStatus">
      <h4>
        体型体态<span>生活方式得分为{{ basics.userBodyStatus.score }}</span>
      </h4>
      <div class="elementaryCon">
        <ul>
          <li>性别：{{ basics.userBodyStatus.tUserBaseinfo.sex }}</li>
          <li>年龄：{{ basics.userBodyStatus.tUserBaseinfo.age }}</li>
        </ul>
        <ul>
          <li>居住环境：{{ basics.userBodyStatus.tUserBaseinfo.lifeEnv }}</li>
          <li>现居地：{{ basics.userBodyStatus.tUserBaseinfo.lifeAddress }}</li>
        </ul>
        <ul>
          <li>身高：{{ basics.userBodyStatus.tUserBmiInfo.height }}CM</li>
          <li>体重：{{ basics.userBodyStatus.tUserBmiInfo.weight }}kg</li>
        </ul>
        <ul>
          <li>腰围：{{ basics.userBodyStatus.tUserWaisthipInfo.waist }}CM</li>
          <li>臀围：{{ basics.userBodyStatus.tUserWaisthipInfo.hipline }}CM</li>
        </ul>
      </div>
    </div>
    <div class="elementary" v-if="basics.lifeStyle">
      <h4>
        生活方式<span>生活方式得分为{{ basics.lifeStyle.score }}</span>
      </h4>
      <div class="elementaryCon lifes">
        <ul>
          <li v-for="(item, index) in basics.lifeStyle.data" :key="index">
            {{ item.categoryName }}：{{ item.categoryValue }}
          </li>
        </ul>
      </div>
    </div>
    <div class="elementary" v-if="basics.lifeStatus">
      <h4>
        生活状态<span>生活方式得分为{{ basics.lifeStatus.score }}</span>
      </h4>
      <div class="elementaryCon lifes">
        <ul>
          <li v-for="(item, index) in basics.lifeStatus.data" :key="index">
            {{ item.categoryName }}：{{ item.categoryValue }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      basics: {},
      userId:""
    };
  },
  created() {
    var that=this;
    that.userId = that.$route.query.userId;
    that.jkdabasics();
    that.$watch('$route', function() {
      that.userId = that.$route.query.userId;
      if(that.userId)
      {
        that.jkdabasics();
      }
      else{
        that.basics={};
      }
    });

  },
  methods: {
    jkdabasics() {
      var that = this;
      var parameter = {
        userId: that.userId,
      };
      that.api.jkdabasics(parameter).then((response) => {
        response = response.data;
        if (response.code == 0) {
          that.basics = response.data;
          for (var i = 0; i < that.basics.illnessInfo.length; i++) {
            that.basics.illnessInfo[i].content =
              that.basics.illnessInfo[i].content.split(",");
            that.basics.illnessInfo[i].content = that.basics.illnessInfo[
              i
            ].content.filter(function (s) {
              return s && s.trim();
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  padding: 20px 0;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  h4 {
    padding-left: 20px;
    span {
      margin-left: 30px;
      color: #185ad6;
    }
  }
  .elementaryCon {
    display: flex;
    margin-top: 20px;
  }
  ul {
    &:last-child {
      border-right: none;
    }
    width: 340px;
    padding: 20px;
    font-size: 14px;
    border-right: 1px solid #e3e3e3;
    li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .lifes {
    ul {
      width: 100%;
      li {
        display: inline-block;
        height: 55px;
        min-width: 280px;
        line-height: 55px;
        font-size: 14px;
        padding: 0 20px;
        margin: 0;
        border-right: 1px solid #e3e3e3;
      }
    }
  }
  .elesvdk {
    font-size: 14px;
    padding-left: 20px;
    margin-top: 30px;
    dl {
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    dt {
      margin-bottom: 20px;
    }
    dd {
      display: inline-block;
      padding: 0 8px;
      margin-right: 10px;
      height: 24px;
      line-height: 24px;
      background: #f2fbff;
      border-radius: 2px;
      border: 1px solid #96d0ee;
    }
  }
}
</style>
