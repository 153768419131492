<template>
  <Layout>
    <Card>
      <p slot="title">线下机构管理</p>
      <Button type="primary" @click="handleAddAgency">新增机构</Button>
      <div class="content">
      <Tabs type="card" v-model="currentTabId" @on-click="handleChangeTab">
        <template v-for="item in tabList">
          <TabPane :label="item.name" :name="item.id" :key="item.id">
            <Table class="lu-table" :columns="columns" :data="getList(item.id)">
              <template slot-scope="{ row }" slot="status">
                {{ statusMsgMap[row.onLine] }}
              </template>
              <template slot-scope="{ row }" slot="action">
                <Button @click="handleEdit(row.id)" type="text"> 编辑 </Button>
                <!-- <Button @click="handleOpenLoginDialog(row.id)" type="text">
                  登录管理
                </Button> -->
              </template>
            </Table>
          </TabPane>
        </template>
      </Tabs>
      <div class="lu-footer">
        <Page transfer :current="page" :pageSize="pageSize" :total="total" @on-change="changePage" />
      </div>
    </div>
    </Card>
    
    <!-- 新增编辑弹窗 -->
    <OffLineAgenciesModal :form="detailForm" :visible="visible" :serviceOptions="serviceOptions" :editing="editing" v-on:submit="submit" v-on:close="close" />
    <!-- 登录设置弹窗 -->
    <LoginModal :form="loginForm" :visible="loginVisible" v-on:submit="handleLogin" v-on:close="handleCloseLogin" />
  </Layout>
</template>
<script>
import Axios from "axios";
import LoginModal from "./common/LoginModal";
import OffLineAgenciesModal from "./common/OfflineAgenciesModal";
import { handleFail } from "../utils/login";

const PAGE_SIZE = 10;

const CENTER = "健管中心";
const MEDICINE = "中医馆";

const statusMsgMap = {
  1: "上架",
  2: "下架",
};

const defaultForm = {
  shopType: CENTER, // 1 2
  shopName: "", // 机构名称
  province: "",
  city: "",
  region: "", // 省市区
  address: "", // 社区
  rate: "", // 评分 1-5
  originServer: "", // 起始服务数
  openTime: "", // 营业时间
  onLine: 1, // 上架状态 上架1 下架2
  img: "", // 主图
  topImg: "", // 顶图
  tags: "",
  services: [], // 支持的服务
  speciality: "", // 特长
};

const tabList = [
  {
    name: "健康管理中心",
    id: CENTER,
  },
  {
    name: "中医馆",
    id: MEDICINE,
  },
];

// 表头
const columns = [
  {
    title: "机构类型",
    key: "shopType",
    minWidth: 145,
    align:'center',
  },
  {
    title: "机构名称",
    key: "shopName",
    minWidth: 185,
    align:'center',
  },
  {
    title: "位置",
    key: "address",
    align:'center',
    minWidth: 185,
  },
  {
    title: "评分",
    key: "rate",
    align:'center',
    minWidth: 100,
  },
  {
    title: "起始服务数",
    key: "originServer",
    minWidth: 120,
    align:'center',
  },

  {
    title: "上架状态",
    minWidth: 120,
    align:'center',
    slot: "status",
  },
  {
    title: "操作",
    slot: "action",
    minWidth: 120,
    align: "center",
    fixed: "right",
  },
];

export default {
  name: "agencies-page",
  components: { LoginModal, OffLineAgenciesModal },
  data() {
    return {
      currentTabId: CENTER,
      tabList,
      page: 1,
      pageSize: PAGE_SIZE,
      total: 1,
      agenciesData: {
        center: [],
        medicine: [],
      }, // 列表数据: 健康管理中心列表、中医馆列表
      columns,
      statusMsgMap,
      visible: false, // 详情弹窗
      loginVisible: false, // 详情弹窗
      curId: null,
      editing: false,
      detailForm: defaultForm,
      loginForm: {
        username: "",
        password: "",
      },
      serviceOptions: [], // 线下服务
    };
  },
  mounted() {
    // 初始化线下机构列表
    this.fetchData({ tabId: CENTER, page: 1 });
    // 获取线下服务列表
    this.fetchOfflineServices();
  },
  methods: {
    fetchOfflineServices() {
      const vm = this;
      Axios.post("/api/goods/query", {}).then((res) => {
        const {
          data: { goods: list },
        } = res;
        vm.serviceOptions = list.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      });
    },
    getList(id) {
      if (id === CENTER) {
        return this.agenciesData.center;
      } else {
        return this.agenciesData.medicine;
      }
    },
    // 增加
    handleAddAgency() {
      this.visible = true;
      this.editing = false;
      this.curId = null;
    },
    handleChangeTab(tabId) {
      this.page = 1;
      this.fetchData({
        tabId,
        page: 1,
      });
    },
    fetchData({ tabId, page }) {
      const vm = this;
      const { currentTabId } = vm;
      Axios.post("/api/shop/queryList", {
        shopType: tabId,
        page,
        pageSize: PAGE_SIZE,
      })
        .then((response) => {
          handleFail(response);
          const {
            data: {
              page: { list, totalCount: total },
            },
          } = response;

          vm.total = total;
          if (currentTabId === CENTER) {
            vm.agenciesData.center = list;
          } else {
            vm.agenciesData.medicine = list;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 换页
    changePage(page) {
      const { currentTabId: tabId } = this;
      this.fetchData({ tabId, page });
    },
    // 点击编辑按钮
    handleEdit(id) {
      this.editing = true;
      this.curId = id;
      this.visible = true;

      const vm = this;

      Axios.post("/api/shop/info", {
        id,
      }).then((res) => {
        const {
          data: { shop },
        } = res;
        vm.detailForm = shop;
      });
    },
    // 新增或编辑线下机构
    submit(form) {
      const { curId, editing, currentTabId } = this;
      const vm = this;
      const url = editing ? "/api/shop/update" : "/api/shop/save";
      Axios.post(url, {
        ...(editing && { id: curId }),
        ...form,
      })
        .then(function () {
          vm.visible = false;
          vm.curId = null;
          // 改变状态，刷新数据
          vm.fetchData({ page: 1, tabId: currentTabId });
        })
        .catch(function (error) {
          vm.visible = false;
          vm.curId = null;
          console.log(error);
        });
    },
    close() {
      this.visible = false;
      // 关闭弹窗，重置form
      this.detailForm = defaultForm;
    },
    // 打开登录设置
    handleOpenLoginDialog(id) {
      // 获取登录设置详情
      Axios.get("/api/offlineAgencies/loginDetail", {
        id,
      }).then((res) => {
        const {
          data: { username, password },
        } = res;
        this.loginForm = { username, password };
        this.loginVisible = true;
        this.curId = id;
      });
    },
    // handleOpenMyDialog() {
    //   this.$Modal.confirm({
    //     title: "Title",
    //     content:  <LoginModal form="loginForm" visible="loginVisible" v-on:submit="handleLogin" v-on:close="handleCloseLogin" />,
    //     onOk: () => {
    //       this.$message.info("Clicked ok");
    //     },
    //     onCancel: () => {
    //       this.$message.info("Clicked cancel");
    //     },
    //   });
    // },
    // 登录设置-提交
    handleLogin({ username, password }) {
      const { curId: id } = this;
      Axios.post("/api/offlineAgencies/loginSubmit", {
        id,
        username,
        password,
      }).then(() => {
        this.handleCloseLogin();
      });
    },
    // 登录设置-关闭
    handleCloseLogin() {
      this.loginVisible = false;
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.message-list {
  height: 300px;
  overflow-y: scroll;
}
.message-item {
  display: flex;
  margin-bottom: 30px;
  height: 64px;
  align-items: center;

  .right {
    display: flex;
    flex-direction: column;
    margin-left: 25px;

    .nick-name {
      font-size: 14px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.65);
      line-height: 32px;
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 32px;
    }
  }
}
.send-btn {
  margin: 10px 0 0 0;
}
</style>
