import { render, staticRenderFns } from "./changeBindDialog.vue?vue&type=template&id=09591606&scoped=true"
import script from "./changeBindDialog.vue?vue&type=script&lang=js"
export * from "./changeBindDialog.vue?vue&type=script&lang=js"
import style0 from "./changeBindDialog.vue?vue&type=style&index=0&id=09591606&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09591606",
  null
  
)

export default component.exports