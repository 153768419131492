import { render, staticRenderFns } from "./infobot.vue?vue&type=template&id=1453b4bc&scoped=true"
import script from "./infobot.vue?vue&type=script&lang=js"
export * from "./infobot.vue?vue&type=script&lang=js"
import style0 from "./infobot.vue?vue&type=style&index=0&id=1453b4bc&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1453b4bc",
  null
  
)

export default component.exports