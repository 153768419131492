<template>
  <div>
  <el-dialog
  title="精准膳食菜单填写"
  :visible.sync="dialogVisible"
  :before-close="handleClose"
  class="dialog"
  top="0"
  >
    <div class="dialog-content">
    <div class="tip-wrapper">
       <div class="tip">须知:一经提交将无法修改,请您再三确认以后提交</div>
       <div class="tip">定制日期:{{time}}</div>
    </div>



    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">


    <el-form-item
      v-for="(item, index) in rules"
      :label="item[0].title"
      :key="index"
      :prop="index"
      :rules="item"
    >
        <el-input cols=5 @input="checkInput(index)" maxlength="500" show-word-limit type="textarea" v-model="ruleForm[index]"></el-input>

    </el-form-item>


    </el-form>
    </div>
    <div slot="footer">
      <div class="button-wrapper">
        <div class="cancelButton" @click="handleClose">关闭</div>
        <div class="submitButton" @click="handleConfirm">确认无误,提交</div>
      </div>
    </div>
 </el-dialog>
  </div>
</template>
<script>


export default {
  name: "edit-page",
  props: ["dialogVisible","userId"],
  data() {
    return {
      time:new Date().format("MM-dd"),
      ruleForm:{
        
      },
      rules: { // 表单验证规则
        breakfast: [
          { required: true,title:'早餐:', message: '请输入', trigger: 'blur'},
          { message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        breakfastSnack: [
          { required: true,title:'上午加餐:', message: '请输入', trigger: 'blur'},
          {  message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        lunch: [
          { required: true,title:'午餐:', message: '请输入', trigger: 'blur'},
          {  message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        lunchSnack: [
          { required: true,title:'下午加餐:', message: '请输入', trigger: 'blur'},
          {  message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        dinner: [
          { required: true,title:'晚餐:', message: '请输入', trigger: 'blur'},
          {  message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        vegetables: [
          { required: true,title:'蔬菜选择:', message: '请输入', trigger: 'blur'},
          {  message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        fruits: [
          { required: true,title:'水果选择:', message: '请输入', trigger: 'blur'},
          {  message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        grease: [
          { required: true,title:'油脂选择:', message: '请输入', trigger: 'blur'},
          {  message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        nut: [
          { required: true,title:'坚果选择:', message: '请输入', trigger: 'blur'},
          {  message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        protein: [
          { required: true,title:'蛋白选择:', message: '请输入', trigger: 'blur'},
          {  message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
        suggestion: [
          { required: true,title:'本日专属建议:', message: '请输入', trigger: 'blur'},
          {  message: '请输入 0 到 500 个字符', trigger: 'change' }
        ],
      }
    };
  },
  mounted(){
    
  },
  created() {
    var that=this;
    that.init();
    that.$watch('userId', function() {
      if(that.userId)
      {
        this.loadData();
      }
      else{
        that.init();
      }
    });
    this.loadData();
  },
  methods: {
    init(){
     this.ruleForm={
        breakfast: "",
        breakfastSnack: "",
        lunch: "",
        lunchSnack: "",
        dinner: "",
        vegetables: "",
        fruits: "",
        grease: "",
        nut: "",
        protein: "",
        suggestion:"",
        status:3,
        generateTime:"",
        userId:""
      };
    },
    loadData(){
      var that=this;
      that.api.preciseInfo({userId:that.userId}).then((response) => {
         
         if(response.data.code==0)
         {
           Object.assign(that.ruleForm, response.data.data);
           console.log(4445,that.ruleForm);
         }
         else
         {
           var msg=response.data.msg?response.data.msg:'精准膳食信息接口错误';
           that.$Message.error(msg);
         }
      });
    },
    handleClose(){
      this.$emit('close');
    },
    handleConfirm(){
    var that=this;
    that.ruleForm.userId=that.userId;
    console.log(that.ruleForm);
    that.$refs['ruleForm'].validate((valid) => {
      if (valid) {
         var date_= new Date();
         that.ruleForm.generateTime=date_.format("yyyy-MM-dd hh:mm:ss");

        that.api.preciseUpdate(that.ruleForm).then((response) => {
          var data=response.data;
          if(data.code==0)
          {
            that.$refs['ruleForm'].resetFields();
            that.$emit('ok');
          }
          else
          {
            that.$Message.error(data.msg?data.msg:'保存失败');
          }
        });
      } else {
        console.log('error submit!!');
        return false;
      }
    });

    },
    checkInput(key){
      var txtVal = this.ruleForm[key];

      console.log(encodeURIComponent(txtVal));


    },
  },
};
</script>
<style lang="less" scoped>

.tip-wrapper
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:12px;
  margin-bottom:4px;
  padding-left:16px;
  padding-right:16px;
}
.button-wrapper
{
display: flex;
justify-content: flex-end;
}
.saveButton
{
  width: 48px;
  height: 26px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:8px;
}
.cancelButton
{
  width: 70px;
  height: 26px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
}
.submitButton
{
  width: 104px;
  height: 26px;
  background: #185AD6;
  border-radius: 2px;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:8px;
}
.tip
{
font-size: 10px;
font-family: SourceHanSansCN-Normal, SourceHanSansCN;
font-weight: 400;
color: rgba(0, 0, 0, 0.85);
}
.dialog-content
{
  height: 100%;
  display: flex;
  flex-direction: column;
}
/deep/.el-input__inner
{
  text-align: right;
  height: 40px;
  background: transparent;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  font-size: 12px;
  &:-webkit-autofill {
    transition: background-color 99999s ease-in-out 0s !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.85) !important;
  }
  // 自动填充文本样式
  &:-webkit-autofill::first-line {
    font-size: 12px;
  }
}
/deep/.el-form{
  overflow: scroll;
  flex: 1;
  padding-left:16px;
  padding-right:16px;
}
/deep/.el-form-item__error
{
  font-size:10px;
}
/deep/.el-textarea
{
  width: 392px;
  height: 128px;
  font-size: 10px;

}
/deep/.el-dialog__footer
{
border-top: 1px solid rgba(0, 0, 0, 0.15);
padding-top:10px;
padding-bottom:10px;
}
/deep/.el-textarea__inner
{
  height: 100%;
  resize: none;
  border-radius:0;
  padding:6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
/deep/.el-dialog__wrapper
{
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-dialog
{
  margin: 0px;
  width: 560px;
  height: 90%;
  display: flex;
  flex-direction: column;
}
/deep/.el-dialog__body
{
  overflow: scroll;
  flex: 1;
  padding:0;
}
/deep/.el-input__count{
 line-height:20px;
}
/deep/.el-form-item__label
{
  width: 112px;
}
</style>
