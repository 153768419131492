<template>
  <div>
    <el-dialog

    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :append-to-body="appendToBody?true:false"
    >
    <div slot="title">
      {{dialogTitle}}
    </div>
    <div class="dialog-content">
      <img class="success-logo" src="../assets/img/success.png" alt="" />
      <div class="success-desc" style="margin-top:16px" v-html="content"/>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button @click="handleConfirm" type="primary">确定</el-button>
    </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialogVisible","content","appendToBody","dialogTitle"],
  data() {
    return {

    };
  },
  created() {

  },
  methods: {
    handleClose(){
      this.$emit('close');
    },
    handleConfirm(){
      this.$emit('confirm');
    }
  },
};
</script>
<style lang="less" scoped="scoped">
.success-button
{
  width: 280px;
  height: 40px;
  background: #185AD6;
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  margin-top: 16px;
  border: 0;
}
.success-desc
{
  font-size: 13px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
}
.success-logo
{
  width: 43px;
  height: 43px;
  margin-top: 10px;
}
.dialog-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
/deep/.el-dialog
{
  
  width: 400px;
  
}
/deep/.el-dialog__header
{
  border:0;
}
/deep/.el-dialog__wrapper
{
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/.el-dialog__body
{
  overflow: hidden;
  flex: 1;
}
</style>
