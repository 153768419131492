<template>
  <Layout>
    <Card class="planBox">
      <p v-if="!hideTitle" slot="title">营养素方案申请</p>
      <div class="search-area">
        <DatePicker
          v-model="sjValue"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          placeholder="开始时间 - 结束时间"
        ></DatePicker>
        <Input class="search-input" v-model="formData.mobile" placeholder="按手机号查询" />
        <Button type="primary" @click="searchList">查询</Button>
      </div>
      <div class="content">
      <message-table :labelText="tableText" :readOnly="readOnly" :data="tableData" v-on:open="open" />
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="formData.page"
        @current-change="handleCurrentChange"
        :total="total"
      ></el-pagination>
    </div>
    </Card>
    
    <el-dialog title="营养素方案制作" :visible.sync="dialogVisible" width="480px">
      <div class="dialog-con">
        <div class="dialog-top">
          <div class="topImg dialogConItem">
            <div class="pdfBox">
              <p>{{ dialogData.fileName }}</p>
              <img src="../assets/img/bglogin.png" alt />
              <el-button @click="openpdf(dialogData.url)">下载</el-button>
            </div>
          </div>
          <div class="reportTime dialogConItem">报告时间：{{ dialogData.time }}</div>
        </div>
        <div class="dialog-bot">
          <p>选择营养素领域：（多选）</p>
          <div>
            <el-checkbox-group v-model="nutritionitem" @change="AllChange">
              <el-checkbox-button
                v-for="item in nutritionList"
                :label="item.id"
                :key="item.id"
              >{{ item.name }}</el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div class="checkBox" v-if="cityOptions">
            <p>选择营养素：（多选）</p>
            <el-checkbox-group v-model="checkedCities">
              <el-checkbox
                v-for="(item, index) in cityOptions"
                :label="item.id"
                :key="index"
              >{{ item.name }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <Button @click="dialogVisible = false">关闭</Button>
        <Button type="primary" @click="dispose">处理</Button>
      </span>
    </el-dialog>

    <el-dialog append-to-body title="营养素方案制作" :visible.sync="dialogVi" width="480px">
      <div class="dialog-con">
        <div class="dialog-top">
          <div class="topImg dialogConItem">
            <div class="pdfBox">
              <p>{{ dialogData.fileName }}</p>
              <img src="../assets/img/bglogin.png" alt />
              <el-button @click="openpdf(dialogData.url)">下载</el-button>
            </div>
          </div>
          <div class="reportTime dialogConItem">报告时间：{{ dialogData.time }}</div>
        </div>
        <div class="dialog-bot">
          <div class="checkBox" v-if="dialogData.nutrientName">
            <p>为用户选择的营养素：</p>
            <span>{{dialogData.nutrientName}}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <Button @click="dialogVi = false">关闭</Button>
      </span>
    </el-dialog>
  </Layout>
</template>
<script>
import messageTable from "./common/messageTable.vue";
import img1 from "../assets/img/bglogin.png";
export default {
  props: ["doctorId","hideTitle","readOnly"],
  components: {
    messageTable,
  },
  data() {
    return {
      tableData: [],
      fileType: "pdf",
      tableText: "申请信息",
      sjValue: [],
      formData: {
        start: "",
        end: "",
        mobile: "",
        page: 1,
        pagesize: 10,
      },
      total: 0,
      activeName: "all",
      all: [],
      done: [],
      todos: [],
      todosNum: 4,
      status: 0, // 0 未处理 1 已处理
      dialogVisible: false, // 详情弹窗
      dialogVi:false, // 查看弹框
      dialogData: {},
      urlimg: img1,
      srcList: [img1],
      nutritionList: [],
      nutritionitem: [],
      cityOptions: [],
      checkedCities: [],
      detailitem: "",
    };
  },
  created() {
    var that=this;
    that.yysApplyInquire();
    that.$watch('doctorId', function() {
      if(that.doctorId)
      {
        that.yysApplyInquire();
      }
      else{
        that.tableData=[];
        that.total=0;
      }
    });

  },
  methods: {
    openpdf(itme) {
      window.open(itme);
    },
    AllChange(val) {
      this.cityOptions = [];
      this.checkedCities = [];
      for (var i = 0; i < this.nutritionList.length; i++) {
        for (var j = 0; j < val.length; j++) {
          if (this.nutritionList[i].id == val[j]) {
            this.cityOptions.push.apply(
              this.cityOptions,
              this.nutritionList[i].children
            );
          }
        }
      }
      var hash = {};
      this.cityOptions = this.cityOptions.reduce(function (item, next) {
        hash[next.id] ? "" : (hash[next.id] = true && item.push(next));
        return item;
      }, []);
    },
    open(row) {
      if (row.item==1) {
        this.dialogVisible = true;
      } else {
        this.dialogVi = true;
      }
      this.getDetail(row);
    },
    searchList() {
      this.formData.start = Date.parse(this.sjValue[0]);
      this.formData.end = Date.parse(this.sjValue[1]);
      this.formData.page = 1;
      this.yysApplyInquire();
    },
    //获取列表
    yysApplyInquire() {
      //列表
      var that = this;
      that.formData.doctorId=that.doctorId;
      that.api.yysApplyInquire(that.formData).then((response) => {
        response = response.data;
        if (response.code == 0) {
          var page=response.page?response.page:response.data;
          that.total = page.totalCount;
          that.tableData = page.list;
        }
      });
    },
    //点击分页
    handleCurrentChange(cpage) {
      //分页切换
      this.formData.page = cpage;
      this.yysApplyInquire();
    },
    //获取详情
    getDetail(item) {
      var that = this;
      that.detailitem = item;
      that.api.yysApplyDetail({ id: item.id }).then((response) => {
        if (response.data.code == 0) {
          that.dialogData = response.data.data;
          that.nutritionList = response.data.data.schema;
        }
      });
    },
    //处理
    dispose() {
      var that = this;
      var parameter = {
        id: that.detailitem.id,
        userId: that.detailitem.userId,
        nutrientId: that.checkedCities.join(","),
      };
      that.api.yysApplyupdate(parameter).then((response) => {
        if (response.data.code == 0) {
          that.dialogVisible = false;
          that.yysApplyInquire();
        }
      });
    },
  },
};
</script>
<style scoped>
.dialog-bot >>> .el-checkbox-button {
  height: 22px;
  line-height: 22px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.dialog-bot >>> .el-form-item__label {
  line-height: 24px;
}
.dialog-bot >>> .el-radio-button__inner,
.dialog-bot >>> .el-radio-button:first-child .el-radio-button__inner,
.dialog-bot >>> .el-checkbox-button__inner,
.dialog-bot >>> .el-checkbox-button:first-child .el-checkbox-button__inner {
  border-radius: 2px !important;
  border: 1px solid #91d5ff;
  padding: 5px;
  font-size: 12px;
}
.dialog-con >>> .el-dialog__footer {
  border-top: 1px solid #dcdfe6;
  
}
.topImg >>> .el-button {
  display: block;
  margin-left: 10px;
  padding: 0px 20px;
  line-height: 26px;
  height: 26px;
  margin-top: 10px;
}
.search-area >>> .search-input {
  width: 200px !important;
}
</style>
<style lang="less" scoped="scoped">
.search-area {
  display: flex;
  justify-content: flex-start;
  .search-input {
    width: 200px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
.dialog-con {
  max-height: 400px;
  overflow-y: scroll;
  padding: 30px 20px;
}
.search-area {
  display: flex;
  flex-direction: row;
  .search-range {
    width: 240px;
    margin-right: 24px;
  }
  .search-input {
    width: 548px;
  }
}
.el-badge {
  margin-bottom: 35px;
  color: #185ad6;
}
.dialog-top {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  .dialogConItem {
    width: 50%;
  }
  .topImg {
    img {
      width: 95px;
      height: 110px;
      display: block;
    }
    .pdfBox {
      p {
        margin-bottom: 5px;
      }
    }
  }
}
.dialog-bot {
  p {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
    margin-bottom: 20px;
  }
  .el-textarea__inner {
    height: 123px;
  }
}
.checkBox {
  margin-top: 20px;
}
.el-checkbox {
  margin-bottom: 10px;
}
</style>
