<template>
  <div>
    <div class="content-wrapper">
      <div class="title">欢迎注册全民智慧健康管理系统</div>
      <div class="sub-title">{{ruleForm.type==1?'团队端':'健管师'}}</div>
      <div class="mid-content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <!-- <el-form-item prop="organization">
            <el-input
              v-model="ruleForm.organization"
              maxlength="12"
              minlength="6"
              placeholder="请输入组织名称"
              autocomplete="off"
            ></el-input>
            <div class="form-item-tip">{{rules.organization[0].tip}}</div>
          </el-form-item>-->
          <el-form-item v-if="ruleForm.type==1" prop="teamName">
            <el-input
              v-model="ruleForm.teamName"
              maxlength="12"
              minlength="3"
              placeholder="请输入团队名称"
              autocomplete="off"
            ></el-input>
            <div class="form-item-tip">{{rules.teamName[0].tip}}</div>
          </el-form-item> 
          <el-form-item prop="userName">
            <el-input
              v-model="ruleForm.userName"
              maxlength="12"
              minlength="6"
              placeholder="请输入用户名"
              autocomplete="off"
            ></el-input>
            <div class="form-item-tip">{{rules.userName[0].tip}}</div>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="ruleForm.password"
              type="password"
              maxlength="12"
              minlength="6"
              placeholder="请设置登录密码"
              autocomplete="off"
            ></el-input>
            <div class="form-item-tip">{{rules.password[0].tip}}</div>
          </el-form-item>
          <el-form-item prop="confirmPassword">
            <el-input
              v-model="ruleForm.confirmPassword"
              type="password"
              maxlength="12"
              minlength="6"
              placeholder="请再次确认您的登录密码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="mobile">
            <el-input
              v-model="ruleForm.mobile"
              placeholder="请输入您的手机号"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="verifyCode">
            <el-input
              v-model="ruleForm.verifyCode"
              placeholder="请输入验证码"
              autocomplete="off"
            ></el-input>
            <el-button @click="getVeriCode" :class="checkCode.class">{{checkCode.title}}</el-button>
  
          </el-form-item>
          <el-button @click="register" class="registerButton">注册</el-button>
          <el-form-item prop="checked">
            <div class="agree-wrapper">
               <el-checkbox v-model="ruleForm.checked"></el-checkbox>
               <div class="agree-desc">我已阅读并同意<span class="agree">服务条款、隐私政策</span></div>
            </div>
          </el-form-item>
        </el-form>
  
      </div>
    </div>
    <sucessDialog content="您已注册成功<br>请先完善基本信息" :dialogVisible="sucessDialogVisible" @confirm="sucessConfirm" @close="sucessDialogClose"/>
  </div>
  </template>
  <script>
  import sucessDialog from "../sucessDialog.vue";
  import { api } from "@/request/api";
  import {
    setToken,
  } from "../../utils/login";
  export default {
    name: "register-page",
    components: {
      sucessDialog
    },
    data() {
      var checkPwd2=((rule,value,callback)=>{
        if(value.trim().length==0){
          callback(new Error("请确认密码不能为空"));
        }else if(value !=this.ruleForm.password){
          callback(new Error("两次输入的密码不一致，请重新输入"));
        }else{
          callback();
        }
      })
      return {
        sucessDialogVisible:false,
        checkCode:{
          disable:false,
          class:'veriCodeButton',
          count:120,
          title:'获取验证码'
        },
        ruleForm:{
          userName: "",
          password:"",
          confirmPassword:"",
          mobile:"",
          verifyCode:"",
          checked:[],
          type:"",
          teamId:"",
          teamName:"",
        },
        activeName: '',
        rules: { // 表单验证规则
          teamName: [
            { required: true, message: '请输入团队名称',tip:'团队名称为3-12个字符，不支持符号，一旦设置成功无法修改', trigger: 'blur'},
            {
              pattern: /^[a-zA-Z0-9\u4e00-\u9fa5]{3,12}$/,
              message: "团队名称输入不符合要求",
              trigger: "blur",
            }
          ],
          // organization: [
          //   { required: true, message: '请输入组织名称',tip:'组织名称为6-12个字符，不支持符号，一旦设置成功无法修改', trigger: 'blur'},
          //   {
          //     pattern: /^[a-zA-Z]{6,12}$/,
          //     message: "组织名称输入不符合要求",
          //     trigger: "blur",
          //   }
          // ],
          userName: [
            { required: true, message: '请输入账号',tip:'用户名为英文字母6-12个字符，不支持符号，一旦设置成功无法修改', trigger: 'blur'},
            {
              pattern: /^[a-zA-Z]{6,12}$/,
              message: "账户输入不符合要求",
              trigger: "blur",
            }
          ],
          password: [
            { required: true, message: '请设置登录密码',tip:'密码为英文字母+数字6-12个字符，不支持符号', trigger: 'blur'},
            {
              pattern: /^[a-zA-Z0-9]{6,12}$/,
              message: "密码设置不符合要求",
              trigger: "blur",
            }
          ],
          confirmPassword: [
            { required: true, message: '请再次确认您的登录密码', trigger: 'blur'},
            {validator:checkPwd2,trigger:"blur"}
          ],
          mobile: [
            { required: true, message: '请输入手机号', trigger: 'blur'},
            {
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: "手机号格式不对",
              trigger: "blur",
            }
          ],
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur'}
          ],
          checked:[
            { type: 'array',required: true, message: '请阅读并同意', trigger: 'blur'}
          ],
        }
      };
    },
    mounted(){
       this.ruleForm.type=this.$route.query.type;
       if(this.$route.query.type==1){
        this.ruleForm.teamId=this.$route.query.orgId;
        
       }
       else if(this.$route.query.type==2){
        this.ruleForm.teamId=this.$route.query.teamId;
        this.ruleForm.teamName=this.$route.query.teamName;
       }
       
    },
    created() {
  
    },
    methods: {
      sucessDialogClose(){
         this.sucessDialogVisible=false;
      },
      sucessConfirm(){
         this.sucessDialogVisible=false;
         this.$router.push({path: "/index"});
      },
      register(){
        var that=this;
        if(!this.ruleForm.type){
          that.$Message.error("缺少类型，请检查");
          return;
        }
        that.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            console.log(valid);
            api.userRegister(that.ruleForm).then((response) => {
              var data=response.data;
              if(data.code==0)
              {
                that.sucessDialogVisible=true;
                setToken(data.data.token);
  
              }
              else
              {
                that.$Message.error(data.msg?data.msg:'注册失败');
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      getVeriCode(){
        var that=this;
        if(that.checkCode.disable)return;
  
        that.$refs['ruleForm'].validateField('mobile', mobileError => {
            if (!mobileError) {
              api.getRegisterSMS({mobile:that.ruleForm.mobile}).then((response) => {
                var data=response.data;
                if(data.code==0)
                {
                  that.checkCode.disable=true;
                  var count=that.checkCode.count;
                  var title=that.checkCode.title;
                  that.checkCode.title=(count--)+'秒后重新获取';
                  that.checkCode.class='veriCodeDisableButton';
                  var interval=setInterval(function() {
                     that.checkCode.title=(count--)+'秒后重新获取';
                     if(count<=0)
                     {
                      that.checkCode.title=title;
                      that.checkCode.disable=false;
                      that.checkCode.class='veriCodeButton';
                      window.clearInterval(interval);
                     }
                  }, 1000);
                }
                else
                {
                  that.$Message.error(data.msg);
                }
              });
            } else {
                console.log('手机号验证失败');
                return false;
            }
        });
  
      }
    },
  };
  </script>
  <style lang="less" scoped="scoped">
  .content-wrapper
  {
    display: flex;
    align-items: center;
    flex-direction: column;
    background:#fff;
    .title{
      margin-top:67px;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
    .sub-title{
      margin-top:10px;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
    .mid-content{
      width:302px;
    }
    .form-item-tip
    {
      position: absolute;
      top:0;
      left:100%;
      width: 400px;
      font-size: 11px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 16px;
    }
    .veriCodeButton
    {
      background: #185AD6;
      border-radius: 2px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 10px;
      height: 32px;
      line-height: unset;
      display: flex;
      align-items: center;
    }
    .veriCodeDisableButton
    {
      background: #E0E0E0;
      border-radius: 2px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7E7E7E;
      margin-bottom: 10px;
    }
    .registerButton{
      width: 100%;
      height: 40px;
      background: #185AD6;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
      margin-top: 30px;
    }
    .agree-wrapper
    {
      display: flex;
      justify-content: flex-start;
      margin-top: 14px;
      align-items: center;
      .agree-desc
      {
         margin-left: 15px;
         font-size: 11px;
         font-family: SourceHanSansCN-Normal, SourceHanSansCN;
         font-weight: 400;
         color: rgba(0, 0, 0, 0.85);
      }
      .agree
      {
         color:#264AFF;
      }
    }
  }
  /deep/.el-input__inner
  {
    border: 0;
    padding:0;
    text-align: left;
    background: transparent;
    border: 0px;
    -webkit-appearance: none;
    border-radius: 0px;
    font-size: 12px;
    &:-webkit-autofill {
      transition: background-color 99999s ease-in-out 0s !important;
      -webkit-text-fill-color: rgba(0, 0, 0, 0.85) !important;
    }
    // 自动填充文本样式
    &:-webkit-autofill::first-line {
      font-size: 12px;
    }
  }
  
  /deep/.el-form-item__content {
    border-bottom: 1px #F1F1F2 solid;
    display: flex;
    align-items: center;
    width:100%;
    position:relative;
  }
  /deep/.el-form-item__error
  {
    font-size:10px;
    padding-top:8px;
    height: 50px;
  }
  /deep/.el-form
  {
  margin-top:20px;
  }
  /deep/.el-checkbox__input
  {
  line-height:unset;
  display: flex;
  }
  </style>
  