<template>
  <div class="el-main-tab">
    <el-collapse v-model="activeNames" class="elementary">
      <el-collapse-item
        v-for="(item, index) in basics"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <p class="eleHead">
            <span class="hdate">{{ item.generateTime }}</span>
          </p>
        </template>
        <div class="elementaryCon">
            <div v-for="(tongueInfo, ind) in item.tongueInfoList"
            :key="ind"
            :name="ind" class="card">
              <div class="card-title">
                 {{tongueInfo.title}}
              </div>
              <div v-if="typeof(tongueInfo.value)=='string'" class="card-desc">
                 {{tongueInfo.value}}
              </div>
              <div v-else class="card-list">
                 <div class="card-list-dimension">
                   <div v-for="(item, inditem) in tongueInfo.dimension"
                   :key="inditem"
                   :name="inditem" class="card-list-dimension-item" :style="{'text-align':(inditem==tongueInfo.dimension.length-1?'left':'center')}">
                    {{item}}
                   </div>
                 </div>
                 <div class="card-list-normalValue">
                   <div v-for="(item, inditem) in tongueInfo.normalValue"
                   :key="inditem"
                   :name="inditem" class="card-list-dimension-item" :style="{'text-align':(inditem==tongueInfo.normalValue.length-1?'left':'center')}">
                    {{item}}
                   </div>
                 </div>
                 <div class="card-list-value">
                   <div v-for="(item, inditem) in tongueInfo.value"
                   :key="inditem"
                   :name="inditem" class="card-list-dimension-item" :style="{'text-align':(inditem==tongueInfo.value.length-1?'left':'center')}">
                    {{item}}
                   </div>
                 </div>
              </div>
            </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      activeNames: 0,
      basics: [],
      userId:"",
    };
  },
  created() {
    this.userId = this.$route.query.userId;
    this.compocsition();
  },
  methods: {
    compocsition() {
      var that = this;
      var parameter = {
        userId: that.userId,
      };
      api.jkdatongue(parameter).then((response) => {
      console.log(1222);
        if (response.data.code == 0) {
          if(response.data.data)
          {
            var data=response.data.data;
            for(var i=0;i<data.length;i++){
               var d=data[i];
               if(d.tongueInfoList.length>=2)
               {
                 var sxfx=d.tongueInfoList[2];
                 var dimension=[];
                 var normalValue=[];
                 var value=[];
                 for(var l=0;l<sxfx.value.length;l++){
                    var v=sxfx.value[l];
                    dimension.push(v.dimension);
                    normalValue.push(v.normalValue);
                    value.push(v.value);
                 }
                 sxfx.dimension=dimension;
                 sxfx.normalValue=normalValue;
                 sxfx.value=value;
               }

            }
            that.basics =data ;
          }

        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .eleHead {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
    font-weight: bold;
    .hdate {
      color: #185ad6;
      margin:0;
    }
    .hunfold {
      float: right;
    }
  }
  .elementaryCon{
      .card{
       padding-left: 20px;
       padding-right: 20px;
       padding-bottom: 10px;
      }
      .card-title{
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      }
      .card-desc{
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      }
      .card-list
      {
      display: flex;
          flex-direction: column;
          overflow: visible;
      }
      .card-list-dimension
      {
      display: flex;
      overflow: visible;
      }
      .card-list-normalValue
      {
      display: flex;
      overflow: visible;
      }
      .card-list-value
      {
      display: flex;
      overflow: visible;
      }
      .card-list-dimension-item{
      margin-right: 10px;
          flex: 0 0 auto;
          min-width: 60px;
        text-align: center;
      }
  }
/deep/.el-collapse-item__content
{
 padding-bottom:0px;
}
}
</style>
