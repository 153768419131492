import { render, staticRenderFns } from "./changeBindConfirmDialog.vue?vue&type=template&id=71d08294&scoped=true"
import script from "./changeBindConfirmDialog.vue?vue&type=script&lang=js"
export * from "./changeBindConfirmDialog.vue?vue&type=script&lang=js"
import style0 from "./changeBindConfirmDialog.vue?vue&type=style&index=0&id=71d08294&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d08294",
  null
  
)

export default component.exports