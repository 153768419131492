<template>
  <div id="app">
    <router-view class="bgfff"></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },
  updated() {},
  methods: {},
  mounted() {},
};
</script>
