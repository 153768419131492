<template>
  <el-dialog
    class="xztz"
    title="消息"
    :visible.sync="visible"
    :before-close="close"
    append-to-body
  >
    <div class="dialog-content">
      <div class="message-list">
        <div class="message-item" v-for="item in messageList" :key="item.id">
          <img :src="item.avatar" class="lu-avatar" />
          <div class="right">
            <div class="nick-name">{{ item.userName }}</div>
            <div class="text">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <Input v-model="reply" type="textarea" :rows="4" placeholder="请输入" autofocus />
      <div class="lu-footer">
        <Button class="send-btn" @click="close">关闭</Button>
        <Button class="send-btn" type="primary" @click="submit">发送</Button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "newsDialog",
  props:['visible','receiverId','relatedUserId'],
  data() {
    return {
      reply:'',
      messageList:[]
    };
  },
  created() {
  console.log('ssd');
    var that=this;
    that.$watch('receiverId', function() {
      that.loadData();
      console.log(that.receiverId);
    });
  },
  methods: {
    submit(){
      var that=this;
      const { reply, receiverId, relatedUserId } = that;

      // TODO: 回复
      that.api.clientMessageSave({
        receiverId: receiverId,
        relatedUserId: relatedUserId,
        content: reply,
      })
      .then(function () {
        that.loadData();
        that.reply = null;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    close(){
      this.$emit("close", false);

    },
    loadData(){
     var that=this;
     if(!that.receiverId)return;
     that.api.clientMessageQuery({receiverId:that.receiverId,relatedUserId:that.relatedUserId}).then((response) => {
        if (response.data.code == 0) {
          that.messageList = response.data.message;
        }
      });
    }
  },
};
</script>
<style lang="less">
.dialog-content
{
  
}
.message-list {
  height: 300px;
  overflow-y: scroll;
  .message-item {
    display: flex;
    margin-bottom: 30px;
    height: 64px;
    align-items: center;

    .right {
      display: flex;
      flex-direction: column;
      margin-left: 25px;

      .nick-name {
        font-size: 14px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.65);
        line-height: 32px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 32px;
      }
    }
  }
}
.lu-footer{
    margin-top: 10px;
    .send-btn{
    margin-left: 10px;
    }
}

</style>
