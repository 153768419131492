<template>
  <el-dialog
  title='用户列表'
  fullscreen
  :visible.sync="visible"
  :before-close="handleClose"
  >
    <Layout>
      <Card>
        <div class="search-box">
          <Input
            v-model="input"
            placeholder="按手机号查询"
            search
            enter-button="搜索"
            :style="{ width: '250px' }"
            @on-search="jkdaInquire"
          />
        </div>
        <div class="content">
        <el-table :data="tableData" @row-click="showUserBasicInfo">
          <el-table-column align="center" prop="id" minWidth="100" label="用户编号"></el-table-column>
          <el-table-column align="center" prop="name" minWidth="120" label="姓名"></el-table-column>
          <el-table-column align="center" prop="mobile" wminWidthidth="150" label="手机号"></el-table-column>
          <el-table-column align="center" prop="gender" minWidth="100" label="性别"></el-table-column>
          <el-table-column align="center" prop="birth" minWidth="120" label="出生日期"></el-table-column>
          <el-table-column align="center" prop="memberType" minWidth="120" label="会员等级">
            <template slot-scope="scope">
              {{user.memberTypeMap[scope.row.memberType]}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="nickName" minWidth="120" label="微信昵称"></el-table-column>
          <el-table-column align="center" prop="avatar" minWidth="120" label="微信头像">
            <template slot-scope="scope">
              <img :src="scope.row.avatar" />
            </template>
          </el-table-column>
          <el-table-column align="center" minWidth="120" label="操作">
            <template slot-scope="scope">
              <el-button @click.native.stop="bind(scope.row)">换绑</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="pagenum"
          @current-change="handleCurrentChange"
          :total="total"
        ></el-pagination>
      </div>
      </Card>
      
    </Layout>
    <changeBindConfirmDialog @close="changeBindConfirmClose" @confirm="changeBindConfirmConfirm" :visible="changeBindConfirm.visible"/>
    <userBasicInfo :appendToBody="true" pathPre="/team" :visible="userBasicInfo.visible" @close="handleUserBasicInfoClose" :userId="userBasicInfo.userId"/>
    <sucessDialog content="换绑成功" dialogTitle="用户换绑" appendToBody="true" :dialogVisible="sucessDialogVisible" @confirm="sucessDialogConfirm" @close="sucessDialogClose"/>
  </el-dialog>
</template>
<script>
import changeBindConfirmDialog from "./changeBindConfirmDialog.vue";
import userBasicInfo from "../healthRecords/userBasicInfo.vue";
import sucessDialog from "../sucessDialog.vue";
export default {
  props: ["visible","doctorId"],
  components: {
     changeBindConfirmDialog,
     userBasicInfo,
     sucessDialog
  },
  data() {
    return {
      total: 10,
      pagenum: 1,
      pagesize: 10,
      sucessDialogVisible:false,
      input: "",
      tableData: [],
      changeBindConfirm:{
       visible:false,
       userId:null,
      },
      userBasicInfo:{
        userId:'',
        visible:false
      },
    };
  },
  mounted(){


  },
  created() {
    var that=this;
    that.jkdaInquire();
    that.$watch('doctorId', function() {
      that.jkdaInquire();
    });
  },
  methods: {
    sucessDialogConfirm(){
     var that=this;
     that.sucessDialogVisible=false;
    },
    sucessDialogClose(){
     var that=this;
     that.sucessDialogVisible=false;
    },
    handleClose: function () {
      this.$emit('close');
    },
    bind(row){
     var that=this;
     that.changeBindConfirm.userId=row.id;
     this.changeBindConfirm.visible=true;
    },
    showUserBasicInfo(row) {
      this.userBasicInfo.userId= row.id;
      this.userBasicInfo.visible=true;
    },
    handleUserBasicInfoClose(){
      this.userBasicInfo.userId= null;
      this.userBasicInfo.visible=false;
    },
    changeBindConfirmClose(){
      this.changeBindConfirm.visible=false;
    },
    changeBindConfirmConfirm(doctor){
      var that=this;
      var parameter = {
        userId:that.changeBindConfirm.userId,
        sourceDoctorId:that.doctorId,
        destDoctorId: doctor.id,
      };
      that.api.clientBind(parameter).then((response) => {
        if (response.data.code == 0) {
          that.changeBindConfirm.visible=false;
          that.sucessDialogVisible=true;
          that.changeBindConfirm.userId=null;
          that.jkdaInquire();
        } else {
          that.$message.error(response.data.msg);
        }
      });
    },
    jkdaInquire() {
      //列表
      var that = this;

      if(!that.doctorId)return;
      var parameter = {
        mobile: that.input,
        page: that.pagenum,
        pageSize: that.pagesize,
        doctorId:that.doctorId
      };
      that.api.jkdaInquire(parameter).then((response) => {
        response = response.data;
        if (response.code == 0) {
          var page=response.page?response.page:response.data;
          if(page)
          {
            that.total = page.totalCount;
            that.tableData = page.list;
          }

        }
      });
    },
    handleCurrentChange(cpage) {
      //分页
      this.pagenum = cpage;
      this.jkdaInquire();
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.content img {
  width: 40px;
}
.search-box {
  display: flex;

}

/deep/.el-dialog__body
{
background:#f5f7f9;
}
</style>
