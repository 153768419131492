<template>
  <div class="loginBox">
    <div class="boxL">
      <img src="../assets/img/login_bg.jpg" alt="" />
    </div>
    <div class="boxR">
      <div class="form-wrapper">
        <h1>全民智慧健康管理系统</h1>
        <el-tabs stretch v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="手机验证码登录" name="verify">
          <el-form :model="mobileForm" :rules="rules" ref="mobileForm">
            <el-form-item prop="mobile">
              <i class="el-icon-user">
                <span>手机</span>
              </i>
              <el-input
                v-model="mobileForm.mobile"
                maxlength="20"
                placeholder="请输入手机号"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item prop="checkCode">
              <i class="el-icon-lock">
                <span>验证码</span>
              </i>
              <el-input
                v-model="mobileForm.checkCode"
                maxlength="8"
                placeholder="请输入验证码"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <div @click="getVeriCode" :class="checkCode.class">{{checkCode.title}}</div>
          </el-form>

          </el-tab-pane>
          <el-tab-pane label="用户名密码登录" name="password">
            <el-form :model="accountForm" :rules="rules" ref="accountForm">
              <el-form-item prop="username">
                <i class="el-icon-user">
                  <span>账号</span>
                </i>
                <el-input
                  v-model="accountForm.username"
                  maxlength="20"
                  placeholder="请输入账号"
                  autocomplete="on"
                ></el-input>
              </el-form-item>
              <el-form-item  prop="password">
                <i class="el-icon-lock">
                  <span>密码</span>
                </i>
                <el-input
                  v-model="accountForm.password"
                  type="password"
                  maxlength="16"
                  placeholder="请输入密码"
                  auto-complete="new-password"
                ></el-input>
              </el-form-item>

              <div @click="forgetpassword" class="forgetpasswordButton">忘记密码</div>
            </el-form>
          </el-tab-pane>

        </el-tabs>
        <el-button @click="getLogin" class="loginButton" type="primary">登录</el-button>
        <!-- <div class="buttons-wrapper">
         <el-button @click="regJGS">注册为健康管理师</el-button>
         <el-button @click="regZXS">注册为健康咨询师</el-button>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import {
  isLogin,
  handleLoginSuccess,
  postLogin,
  setToken,
} from "../utils/login";
import { api } from "@/request/api";
export default {
  name: "login-page",
  data() {
    return {
      mobileForm:{
        mobile:"",
        checkCode:""
      },
      accountForm:{
        username: "",
        password: "",
      },
      checkCode:{
        disable:false,
        class:'veriCodeButton',
        count:120,
        title:'获取验证码'
      },

      activeName: '',
      rules: { // 表单验证规则
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur'},
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          }
        ],
        checkCode: [
          { required: true, message: '请输入验证码', trigger: 'blur'}
        ],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur'}
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur'}
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur'}
        ],
      }
    };
  },
  mounted(){
     var that=this;
     setTimeout(function(){
      that.activeName='verify';
     },100);

  },
  created() {
    if (isLogin()) {
      handleLoginSuccess();
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getVeriCode(){
      var that=this;
      if(that.checkCode.disable)return;

      that.$refs['mobileForm'].validateField('mobile', mobileError => {
          if (!mobileError) {
            api.getLoginSMS(that.mobileForm).then((response) => {
              var data=response.data;
              if(data.code==0)
              {
                that.checkCode.disable=true;
                var count=that.checkCode.count;
                var title=that.checkCode.title;
                that.checkCode.title=(count--)+'秒后重新获取';
                that.checkCode.class='veriCodeDisableButton';
                var interval=setInterval(function() {
                   that.checkCode.title=(count--)+'秒后重新获取';
                   if(count<=0)
                   {
                    that.checkCode.title=title;
                    that.checkCode.disable=false;
                    that.checkCode.class='veriCodeButton';
                    window.clearInterval(interval);
                   }
                }, 1000);
              }
              else
              {
                that.$Message.error(data.msg);
              }
            });
          } else {
              console.log('手机号验证失败');
              return false;
          }
      });

    },
    forgetpassword(){
      this.$router.push({ path: "/resetPass" });
    },
    regJGS(){
      this.$router.push({ path: "/register?role=1"});
    },
    regZXS(){
      this.$router.push({ path: "/register?role=2"});
    },
    getLogin() {
      var that=this;
      var form=that.activeName=='verify'?'mobileForm':'accountForm';

      that.$refs[form].validate((valid) => {
        if (valid) {
          postLogin(this[form])
            .then((token) => {
              setToken(token);
              setTimeout(() => {
                handleLoginSuccess();
              }, 0);
            })
            .catch((e) => {
              // 接口错误
              const { message } = e;
              that.$Message.error(message);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
  },
};
</script>
<style lang="less" scoped>
.loginBox {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
  .boxL {
    height: 100%;
    img {
      height: 100%;
    }
  }
  .boxR {
    width: 100%;
    height: 100%;
    display: flex;
    flex:1;
    align-items: center;
    justify-content: center;
    .form-wrapper
    {

      width: 342px;
      height: 367px;
      background: #FFFFFF;
      border-radius: 10px;

      h1 {
        font-size: 22px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #000000;
        line-height: 34px;
        text-align:center;
        margin-bottom: 12px;
      }
      i {
        z-index: 3;
        font-weight: bold;
        span {
          display: inline-block;
          text-indent: 10px;
          font-size: 13px;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .el-input {
        flex:1;

      }
      .Button {
        width: 472px;
        height: 50px;
        margin: 35px auto 0;
        background: #185ad6;
      }
      .veriCodeButton{
        font-size: 13px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        color: #264AFF;
        padding: 0 15px;
        text-align:right;
        height:20px
      }
      .veriCodeDisableButton{
        font-size: 13px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        color: #7E7E7E;
        padding: 0 15px;
        text-align:right;
        height:20px
      }
      .forgetpasswordButton
      {
        font-size: 13px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        color: #264AFF;
        padding: 0 15px;
        text-align:right;

        height:20px
      }
    }
    .loginButton{
       width:100%;
       margin-top:40px;
    }
    .buttons-wrapper
    {
      display: flex;
      justify-content: space-between;
      margin-top: 22px;
    }
  }
}
/deep/.el-tabs__active-bar{
  height: 3px;
  background: #264AFF;
  border-radius: 1px;
}
/deep/.el-tabs__nav-wrap::after{
  background-color:transparent !important;
}
/deep/.el-tabs__item:hover{
  font-size: 13px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
/deep/.el-tabs__item.is-active {
  font-size: 13px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
/deep/.el-tabs__item{
  font-size: 13px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
/deep/.el-input__inner
{
  text-align: right;
  height: 40px;
  background: transparent;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  font-size: 12px;
  &:-webkit-autofill {
    transition: background-color 99999s ease-in-out 0s !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.85) !important;
  }
  // 自动填充文本样式
  &:-webkit-autofill::first-line {
    font-size: 12px;
  }
}
/deep/.el-form-item__content {
  border-bottom: 1px #F1F1F2 solid;
  display: flex;
  align-items: center;
}
/deep/.el-form-item__error
{
  font-size:10px;
  left: unset;
  right: 16px;
}

</style>
