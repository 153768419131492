<template>
  <Layout>
    <Card>
      <p slot="title">社区管理</p>
      <Button class="titleBtn" type="primary" icon="el-icon-plus" @click="handleOpen">新增社区</Button>
      <div class="content">
      <!-- 社区列表 -->
      <Table class="lu-table" :columns="columns" :data="communityList">
        <template slot-scope="{ row }" slot="action">
          <Button @click="handleEdit(row.id)" type="text"> 编辑 </Button>
        </template>
      </Table>
      <div class="lu-footer">
        <Page transfer :current.sync="page" :total="total" @on-change="changePage" />
      </div>
      <!-- 新增|编辑社区弹窗 -->
      <el-dialog :title="title" :visible.sync="visible" width="480px" :before-close="handleClose">
        <el-form ref="form" :model="form" label-width="100px" class="bussiness-form">
          <el-form-item label="须知：">
            请谨慎添加社区，一旦增加成功，将不允许删除
          </el-form-item>
          <el-form-item label="社区名称：">
            <el-input v-model="form.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="位置：">
            <v-distpicker :province="form.province" :city="form.city" :area="form.area" @province="selectProvince" @city="selectCity" @area="selectArea"></v-distpicker>
          </el-form-item>
          <el-form-item label="具体位置：">
            <el-input v-model="form.community" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <Button class="early-btn" @click="handleClose">关闭</Button>
          <Button class="early-btn" type="primary" @click="handleSubmit('formValidate')">提交</Button>
        </div>
      </el-dialog>
    </div>
    </Card>
    
  </Layout>
</template>
<script>
import VDistpicker from "v-distpicker";
import axios from "axios";

const PAGE_SIZE = 10;

const columns = [
  {
    title: "社区名称",
    key: "name",
    minWidth: 231,
    align: "center",
  },
  {
    title: "省",
    key: "province",
    minWidth: 269,
    align: "center",
  },
  {
    title: "市",
    key: "city",
    minWidth: 269,
    align: "center",
  },
  {
    title: "区",
    key: "area",
    minWidth: 269,
    align: "center",
  },
  {
    title: "操作",
    slot: "action",
    minWidth: 120,
    align: "center",
  },
];

import { handleFail } from "../utils/login";

export default {
  name: "community-page",
  components: { VDistpicker },
  data() {
    return {
      page: 1,
      pageSize: PAGE_SIZE,
      total: 1,
      visible: false,
      form: {
        name: "",
        province: "",
        city: "",
        area: "",
        community: "",
      },
      communityList: [],
      columns,
      curId: null,
      editing: false,
    };
  },
  computed: {
    title: function () {
      return this.editing ? "编辑社区" : "新增社区";
    },
  },
  mounted() {
    // 初始化社区列表
    this.fetchData({ page: 1 });
  },
  methods: {
    fetchData({ page }) {
      const vm = this;
      axios
        .post("/api/community/queryList", {
          params: {
            page,
            pageSize: PAGE_SIZE,
          },
        })
        .then((res) => {
          handleFail(res);
          const {
            data: {
              page: { list, totalCount: total },
            },
          } = res;

          vm.communityList = list.map(
            ({ name, province, city, region: area, id }) => ({
              name,
              province,
              city,
              area,
              id,
            })
          );
          vm.total = total;
        });
    },
    refresh() {
      this.fetchData({ page: this.page });
    },
    // 换页
    changePage(page) {
      this.fetchData({ page });
    },
    handleOpen: function () {
      this.visible = true;
      this.editing = false;
      this.form = {};
    },
    handleClose: function () {
      this.visible = false;
    },
    // 点击编辑按钮
    handleEdit(id) {
      this.editing = true;
      this.curId = id;
      this.visible = true;

      const vm = this;

      axios
        .post("/api/community/info", {
          id,
        })
        .then((res) => {
          const {
            data: {
              community: {
                name,
                province,
                city,
                region: area,
                id,
                address: community,
              },
            },
          } = res;
          vm.form = {
            name,
            province,
            city,
            area,
            community,
            id,
          };
        });
    },

    selectProvince(obj) {
      const { value } = obj;
      this.form.province = value;
    },
    selectCity(obj) {
      const { value } = obj;
      this.form.city = value;
    },
    selectArea(obj) {
      const { value } = obj;
      this.form.area = value;
    },
    validate() {
      const {
        form: { name, province, city, area, community },
      } = this;
      if (!name) {
        this.$message.error("社区名称不能为空");
        return false;
      } else if (!province) {
        this.$message.error("社区省份不能为空");
        return false;
      } else if (!city) {
        this.$message.error("社区城市不能为空");
        return false;
      } else if (!area) {
        this.$message.error("社区地区不能为空");
        return false;
      } else if (!community) {
        this.$message.error("社区详细地址不能为空");
        return false;
      }
      return true;
    },
    handleSubmit() {
      const {
        editing,
        curId,
        form: { name, province, city, area: region, community: address },
      } = this;
      const vm = this;

      if (!this.validate()) {
        return;
      }
      axios
        .post(`/api/community/${editing ? "update" : "save"}`, {
          ...(editing && { id: curId }),
          address,
          city,
          name,
          province,
          region,
        })
        .then(() => {
          vm.$Message.success(editing ? "修改成功" : "提交成功");
          vm.handleClose();
          vm.form = {};
          vm.refresh();
        })
        .catch(() => {
          vm.$Message.error("提交失败");
        });
    },
  },
};
</script>
