<template>
  <Layout>
    <Card>
      <p v-if="!hideTitle" slot="title">饮食解读</p>
      <div class="search-area">
        <DatePicker class="search-date" type="daterange" @on-change="changeDate" placeholder="开始时间 - 结束时间"></DatePicker>
        <Input class="search-input" v-model="mobile" placeholder="按手机号查询" />
        <Button type="primary" @click="handleSearch">查询</Button>
      </div>
      <div class="content">
      <Table class="lu-table" :columns="columns" :data="list">
        <template slot-scope="{ row }" slot="avatar">
          <img :src="row.avatar" class="avatar" />
        </template>
        <template slot-scope="{ row }" slot="action">
          <div v-if="!readOnly">
            <div v-if="row.status==1" class="dot"/>
            <Button @click="handleOpenDialog(row)">
              {{ statusMsgMap[row.status] }}
            </Button>
          </div>
          <div v-else>
            <Button @click="handleOpenDialog(row)">
              查看
            </Button>
          </div>
        </template>
      </Table>
      <div class="lu-footer">
        <Page transfer :current="page" :total="total" @on-change="changePage" />
      </div>
    </div>
    </Card>
    
    <!-- 弹窗 -->
    <el-dialog append-to-body :visible.sync="dialogVisible" width="860" title="饮食解读">
      <Form :label-width="120" label-colon>
        <FormItem class="early-warn-text" label="图片">
          <img v-for="img in imgs" :key="img" class="topImg" :src="img" />
        </FormItem>
        <FormItem label="报告时间"> {{ formatTime }}</FormItem>
        <FormItem label="饮食反馈">
          <Input v-if="!readOnly" v-model="suggestion" type="textarea" :rows="4" placeholder="" autofocus :disabled="Boolean(status === 2||status === 3)" />
          <div v-else>
              {{suggestion}}
          </div>
        </FormItem>
        <div v-if="!readOnly" class="lu-footer">
          <Button class="send-btn" @click="close">关闭</Button>
          <Button v-if="status === 1" type="primary" class="send-btn" @click="submit">处理</Button>
        </div>
        <div v-else class="lu-footer">
          <Button class="send-btn" @click="close">关闭</Button>
        </div>
      </Form>
    </el-dialog>
  </Layout>
</template>
<script>
import Axios from "axios";
import dayjs from "dayjs";

const PAGE_SIZE = 10;

const statusMsgMap = {
  1: "处理",
  2: "查看",
  3: "查看",
};

// 表头
export const columns = [
  {
    title: "姓名",
    minWidth:150,
    align: "center",
    key: "name",
  },
  {
    title: "手机号",
    minWidth:150,
    align: "center",
    key: "mobile",
  },
  {
    title: "性别",
    minWidth:120,
    align: "center",
    key: "gender",
  },
  {
    title: "微信昵称",
    minWidth:150,
    align: "center",
    key: "nickname",
  },
  {
    title: "出生日期",
    minWidth:150,
    align: "center",
    key: "birthday",
  },

  {
    title: "微信头像",
    minWidth:120,
    align: "center",
    slot: "avatar",
  },
  {
    title: "提交时间",
    minWidth: 180,
    align: "center",
    key: "updateTime",
  },
  {
    title: "解读任务",
    slot: "action",
    minWidth: 120,
    align: "center",
    fixed: "right",
  },
];
export default {
  name: "diet-page",
  props: ["doctorId","hideTitle","readOnly"],
  data() {
    return {
      mobile: "", // 查询姓名或电话
      start: "",
      end: "",
      page: 1,
      pageSize: PAGE_SIZE,
      total: 1,
      list: [], // 列表
      columns,
      statusMsgMap,
      dialogVisible: false, // 详情弹窗
      detailMessages: [],
      suggestion: "",
      suggestId: "",
      status: 1,
      time: new Date().getTime(),
      imgs: [],
    };
  },
  computed: {
    formatTime: function () {
      return dayjs(this.time).format("YYYY-MM-DD hh:mm:ss");
    },
  },
  mounted() {
    // 初始化列表
    this.fetchData({ page: 1 });
  },
  created(){
    var that=this;
    that.fetchData({ page: 1 });
    that.$watch('doctorId', function() {
      if(that.doctorId)
      {
        that.fetchData({ page: 1 });
      }
      else{
        that.list=[];
        that.total=0;
      }
    });
  },
  methods: {
    // 修改日期
    changeDate(date) {
      if (!(date && date.length == 2)) {
        return;
      }
      this.start = new Date(date[0]).getTime();
      this.end = new Date(date[1]).getTime();
    },
    // 查询
    handleSearch() {
      this.fetchData({ page: 1 });
    },
    fetchData({ page }) {
      const vm = this;
      const { mobile, start, end } = vm;
      Axios.post("/api/dietary/query", {
        ...(start && { start }),
        ...(end && { end }),
        ...(mobile && { mobile }),
        page,
        pageSize: PAGE_SIZE,
        doctorId:vm.doctorId
      })
        .then((response) => {
          const {
            data: {
              page: { list, totalCount: total },
            },
          } = response;

          vm.total = total;
          vm.list = list.map(
            ({
              id,
              name,
              mobile,
              gender,
              avatar,
              birth,
              nickName,
              status,
              createTime: updateTime,
            }) => ({
              id,
              name,
              mobile,
              gender,
              birthday: birth,
              nickname: nickName,
              avatar,
              status,
              updateTime,
            })
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 换页
    changePage(page) {
      this.page = page;
      this.fetchData({ page: page });
    },
    // 点击查看或处理
    handleOpenDialog(row) {
      const vm = this;
      var id=row.id;
      if(!vm.readOnly)
      {
        // 获取详情
        row.status=3;
        Axios.post("/api/dietary/update", { id:id,isSend:row.status });
      }
      Axios.post("/api/dietary/info", { id }).then((res) => {
        const {
          data: {
            diet: { img, status, message: suggestion, createTime: time },
          },
        } = res;
        var imgs=img.split(',');
        vm.imgs = imgs;
        vm.dialogVisible = true;
        vm.suggestion = suggestion;
        vm.suggestId = id;
        vm.status = status;
        vm.time = time;
      });
    },
    submit() {
      const vm = this;
      const { suggestion, suggestId, page } = vm;
      Axios.post("/api/dietary/update1", {
        id: suggestId,
        message: suggestion,
      })
        .then(function () {
          vm.close();
          vm.fetchData({ page });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.topImg {
  width: 130px;
  height: auto;
  object-fit: contain;
  display: block;
  margin-right: 10px;
  margin-top:10px;
}
/deep/.ivu-table-cell-slot
{
  position: relative;
}
/deep/.ivu-table-cell
{
  overflow: visible;
}
/deep/.ivu-form-item-content
{
    display: flex;
    justify-content: flex-start;
        flex-wrap: wrap;
}
.content
{
  .dot{
    background-color: #ee0a24;
    border-radius: 100%;
    height: 8px;
    min-width: 0;
    width: 8px;
    position: absolute;
    right: 8px;
    top: -4px;
  }
}
.send-btn {
  margin: 10px 10px 0 0;
}
</style>
