<template>
  <Layout>
    <Sider class="sider">
      <Header class="header">
        <img class="layout-logo" src="../assets/img/bgicon.png" alt="" />
        <div class="header-title">{{ roleName }}操作平台</div>
      </Header>
      <LuMenu :active-name="$route.name" style="background:rgba(22, 31, 42, 1)" width="auto">
        <LuMenuItem
          v-for="(item, index) in routeslist"
          :key="index"
          :name="item.name"
          :to="item.path"
        >
          <i :class="item.icon"></i>
          {{ item.name }}
        </LuMenuItem>
      </LuMenu>
    </Sider>
    <Layout :style="{ height: '100vh' }">
      <Header class="content-header">
        <div v-if="user.isJGS()||user.isZXS()" class="header-button" @click="showBasicInfo">基本信息</div>
        <div v-if="user.isJGS()||user.isZXS()" class="header-button" @click="showQrCode">我的名片</div>
        <div  class="header-button" v-if="link.doctorLink" @click="showLinkDialog">我的链接</div>
        <div class="header-button" @click="showModifyPass">账号设置</div>
        <div class="header-button" @click="handleLogout">退出</div>
        <div class="info-wrapper">
          <img v-if="basicInfo.avatar" class="avatar" :src="basicInfo.avatar"/>
          <div class="name-wrapper">
           <div class="name">{{basicInfo.userName}}</div>
           <div class="id">ID:{{basicInfo.id}}</div>
          </div>
        </div>
      </Header>
      <Layout>
        <Content  v-if="user.roleType&&basicInfoOK" class="content-body">
          <router-view></router-view>
        </Content>
      </Layout>
    </Layout>
    <basicInfo :visible="basicInfo.visible" :editAble="true" @close="handleBasicInfoClose"/>
    <modifyPass :visible="modify.visible" @close="handleModifyPassClose"/>
    <linkDialog :visible="link.visible" :doctorLink="link.doctorLink" @close="handleLinkDialogClose"/>
    <qrCodeDialog :visible="qrCode.visible" :doctorId="qrCode.doctorId" @close="handleQrCodeClose"/>
  </Layout>
</template>

<script>
import { getUserRole, isLogin, logout } from "../utils/login";
import { getMenuList } from "../utils/menu";
import LuMenu from "./common/lu-menu";
import basicInfo from "../components/userInfo/basicInfo.vue";
import modifyPass from "../components/userInfo/modifyPass.vue";
import qrCodeDialog from "./userInfo/qrCodeDialog.vue";
import linkDialog from "./userInfo/linkDialog.vue";
export default {
  components: {
    LuMenu, // fix 动态menu activeName 不生效 bug
    LuMenuItem: LuMenu.Item,
    basicInfo,
    modifyPass,
    linkDialog,
    qrCodeDialog
  },
  data() {
    return {

      roleName: "",
      routeslist: [],
      basicInfoOK:false,
      basicInfo:{
        userName:'',
        avatar:'',
        visible:false,
      },
      modify:{
        visible:false,
      },
      qrCode:{
        visible:false,
        doctorId:'',
      },
      link:{
        visible:false,
        doctorLink:'',
      }
    };
  },
  async mounted() {
    var that=this;
    console.log(9);
    if (!isLogin()) {
      that.$router.push({ path: "/login" });
    } else {
      // 获取用户角色和角色名称

        const userInfo = await getUserRole();
        console.log(18893,userInfo);
        that.roleName = userInfo.roleName;
         Object.assign(that.user, userInfo);
        that.routeslist = getMenuList(userInfo.roleType,that.user.doctorTypeE);
        console.log(userInfo);
        that.basicInfoOK=true;
        if (that.user.isTDGLY() || that.user.isJGGLY())  {
          that.initDoctorLink();
        }
        if (that.user.isJGS() || that.user.isZXS()||that.user.isTDGLY() || that.user.isJGGLY())  {
          
          that.initBasicInfo();
          
        }
        
        if(that.user.isGLY())
        {
          that.$router.push({ path: "/orderManagement" });
        }
        else if(that.user.isTDGLY()||that.user.isZXSGLY())
        {
          that.$router.push({ path: "/team" });
        }
        else if(that.user.isJGGLY())
        {
          that.$router.push({ path: "/orgTeam" });
        }
        else{
          that.$router.push({ path: "/healthRecords" });
        }


    }
  },
  methods: {
    initBasicInfo(){
      var that=this;
      that.api.userBaseInfo().then((response) => {
             console.log(99933);
            if(response.data.code==0)
            {
              if(!response.data.data)
              {
               that.showBasicInfo();
              }
              else{
               that.user.basicInfo=response.data.data;
               that.basicInfo.userName=that.user.basicInfo.name;
               that.basicInfo.id=that.user.basicInfo.id;
               that.basicInfo.avatar=that.user.basicInfo.avatar;
               that.qrCode.doctorId=that.user.basicInfo.id;
               
              }
            }
            else{
              var msg=response.data.msg?response.data.msg:'基本信息接口错误';
              that.$Message.error(msg);
            }
          });
    },
    initDoctorLink(){
      var that=this;
      that.api.doctorLink().then((response) => {
        console.log(333888);
        if(response.data.code==0){
          that.link.doctorLink=response.data.data;
          
        }
      });
    },
    handleLogout() {
      logout();
    },
    showQrCode(){
      this.qrCode.visible=true;
    },
    handleQrCodeClose(){
      this.qrCode.visible=false;
    },
    showBasicInfo(){
      this.basicInfo.visible=true;
    },
    handleBasicInfoClose(){
      if(this.basicInfo.visible)
      {
        this.initBasicInfo();
        this.basicInfo.visible=false;
      }
    },
    showModifyPass(){
      this.modify.visible=true;
    },
    handleModifyPassClose(){
      this.modify.visible=false;
    },
    showLinkDialog(){
      this.link.visible=true;
    },
    handleLinkDialogClose(){
      this.link.visible=false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped="scoped">
.header {
  display: flex;
  align-items: center;
  position: relative;
  height: 150px;
  display: flex;
  flex-direction: column;
  background:rgba(22, 31, 42, 1);
  padding:0;
  justify-content: center;
}
.header-title {
  font-size: 13px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height:16px;
  margin-top:18px;
}
.header-button{
  font-size: 11px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  border:0;
  margin-right:40px;
}
.header-button:hover{
cursor:pointer
}
.info-wrapper
{
  display: flex;
  align-items: center;
  .avatar{
    width: 32px;
    height: 32px;
    margin-right:20px;
  }
  .name-wrapper
  {
    display:flex;
    align-items: center;
    flex-direction:column;
    justify-content:center;
    .name{
      font-size: 11px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: unset;
    }
    .id{
      font-size: 11px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: unset;
    }
  }

}
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.layout-logo {
  width: 50px;
  height: 50px;
  background: #5b6270;
  border-radius: 50%;
  margin-right: 10px;
}
.layout-nav {
  width: 420px;
  margin: 0 auto;
  margin-right: 20px;
}

.l-menu-item {
  color: #515a6e;
}
.content-header
{
  height: 59px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
  justify-content: flex-end;
  display:flex;
  align-items: center;
}
.content-body{
 padding:16px;
}
.sider{
flex: 0 0 180px !important;
background:rgba(22, 31, 42, 1);
width:180px !important;
min-width:150px !important;
max-width:180px !important;
}
.role-btn {
  margin-right: 20px;
}
/deep/.ivu-layout-header
{
  line-height:unset;
}
/deep/.ivu-layout
{
overflow-y:scroll;
}
/deep/.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu)
{
font-size: 13px;
font-family: SourceHanSansCN-Normal, SourceHanSansCN;
font-weight: 400;
color: #FFFFFF;
background: rgba(24, 90, 214, 0.3);
}
/deep/a.ivu-menu-item
{
font-size: 13px;
font-family: SourceHanSansCN-Normal, SourceHanSansCN;
font-weight: 400;
color: #FFFFFF;
}
/deep/.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after
{
 display:none;
}
/deep/.ivu-menu-vertical.ivu-menu-light:after
{
 display:none;
}
</style>
