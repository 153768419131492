import { render, staticRenderFns } from "./diet.vue?vue&type=template&id=59680967&scoped=true"
import script from "./diet.vue?vue&type=script&lang=js"
export * from "./diet.vue?vue&type=script&lang=js"
import style0 from "./diet.vue?vue&type=style&index=0&id=59680967&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59680967",
  null
  
)

export default component.exports