<template>

    <Layout>
      <Card>
        <p slot="title">精准餐单</p>
        <div class="search-box">
          <Input
            v-model="input"
            placeholder="按手机号查询"
            search
            enter-button="搜索"
            :style="{ width: '250px' }"
            @on-search="loadData"
          />

        </div>
       <div class="content">
        <el-table :data="tableData">
          <el-table-column prop="id" align="center" minWidth="120" :label="team.label+'ID'"></el-table-column>
          <el-table-column prop="name" align="center" minWidth="120" :label="team.label+'姓名'"></el-table-column>
          <el-table-column prop="mobile" align="center" minWidth="150" label="手机号"></el-table-column>
          <el-table-column prop="sex" align="center" minWidth="100" label="性别">
            <template slot-scope="scope">
              {{user.sexMap[scope.row.sex]}}
            </template>
          </el-table-column>
          <el-table-column prop="birth" align="center" minWidth="150" label="出生日期"></el-table-column>
          <el-table-column prop="avatar" align="center" minWidth="100" label="头像">
            <template slot-scope="scope">
              <img class="avatar" :src="scope.row.avatar" />
            </template>
          </el-table-column>

          <el-table-column prop="status" align="center" minWidth="120" label="操作">
            <template slot-scope="{ row }">
            <Button style="margin-left:5px;" @click="showAction(row)">查看</Button>
            </template>
          </el-table-column>

        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="pagenum"
          @current-change="handleCurrentChange"
          :total="total"
        ></el-pagination>
      </div>
      </Card>
      
      <preciseDialog @close="dialogClose" :visible="dialog.visible" :doctorId="dialog.doctorId"/>
    </Layout>
</template>
<script>
import preciseDialog from "./preciseDialog.vue";
export default {

  components: {
   preciseDialog
  },
  data() {
    return {
      total: 10,
      pagenum: 1,
      status,
      pagesize: 10,
      input: "",
      tableData: [],
      actionColumns:[],
      changeDoctor:null,
      team:{
       label:''
      },
      dialog:{
        visible:false,
        doctorId:null,
      }
    };
  },
  mounted(){

    if(this.user.isTDGLY())
    {
      this.team.label=this.user.doctorTypeE.JGS_S;
    }
    else if(this.user.isZXSGLY())
    {
      this.team.label=this.user.doctorTypeE.ZXS_S;
    }
  },
  created() {

    var that=this;
    that.init();
    that.loadData();

  },
  methods: {
    init(){

    },
    showAction(row){
      this.dialog.doctorId=row.id;
      this.dialog.visible=true;
    },
    dialogClose(){
      this.dialog.visible=false;
      this.dialog.doctorId=null;
    },
    loadData() {
      //列表
      var that = this;
      var parameter = {
        mobile: that.input,
        page: that.pagenum,
        pageSize: that.pagesize,
        interfaceType:that.user.interfaceTypeMapE.PRECISE
      };
      that.api.doctorQueryList(parameter).then((response) => {
        response = response.data;
        if (response.code == 0) {
          var page=response.page?response.page:response.data;
          that.total = page.totalCount;
          that.tableData = page.list;
        }
      });
    },
    handleCurrentChange(cpage) {
      //分页
      this.pagenum = cpage;
      this.loadData();
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.content img {
  width: 40px;
}
.search-box {
  display: flex;

}
/deep/.ivu-btn
{
  padding:5px;
}
/deep/.el-table td.el-table__cell div
{
  display: flex;
    align-items: center;
    justify-content:center;
}

/deep/.el-dialog__body
{
background:#f5f7f9;

}
/deep/.ivu-input-search
{
background: #185AD6 !important;
border-radius: 2px;
}

</style>
