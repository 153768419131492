//转化时分秒
Number.prototype.formatHMS = function() {
    var ts = this;
    var hours = parseInt((ts % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = parseInt((ts % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = parseInt((ts % (1000 * 60)) / 1000);
    hours = hours < 10 ? ('0' + hours) : hours;
    minutes = minutes < 10 ? ('0' + minutes) : minutes;
    seconds = seconds < 10 && seconds >= 0 ? ('0' + seconds) : seconds;
    return hours + "时" + minutes + "分" + seconds + "秒";
};
Number.prototype.thousandsDeal = function(precision) {
    return (this + '').thousandsDeal(precision);
};
Number.prototype.parseFloat = function() {
    return (this + '').parseFloat();
};
Number.prototype.formatNum = function() {
    var num = this;
    if (num == null) {
        return '0';
    }
    num = parseFloat(num);
    if (parseInt(num / 1e4) == 0) {
        return num.toFixed(0);
    } else if (parseInt(num / 1e8) == 0) {
        num = num / 1e4;
        return num.toFixed(0) + '万';
    } else if (parseInt(num / 1e12) == 0) {
        num = num / 1e8;
        return num.toFixed(0) + '亿';
    } else if (parseInt(num / 1e16) == 0) {
        num = num / 1e12;
        return num.toFixed(0) + '万亿';
    }
    return num.toFixed(0);
}
Number.prototype.addZeroTotalNum = function(total) {
    var l = (this + '').length;
    var str = this + '';
    for (var i = l; i < total; i++) {
        str = '0' + str;
    }
    return str;
};
Number.prototype.appendString = function(string) {
    var str = this + '';
    str += string;
    return str;
};
