import VueRouter from "vue-router";
import orderManagement from "../components/orderManagement.vue";
import role from "../components/roleManagement.vue";
import index from "../components/index.vue";
import OfflineAgencies from "../components/offlineAgencies";
import platformM from "../components/platformManagement.vue";
import healthRecords from "../components/healthRecords/healthRecordList.vue";
import offlineServiceM from "../components/OfflineServices.vue";
import communityM from "../components/communityM.vue";
import nutritionM from "../components/nutritionM.vue";
import { EarlyContainer } from "../components/earlyWarningM";
import earlyWarningMonitor from "../components/earlyWarningMonitor.vue";
import login from "../components/login.vue";
import register from "../components/userInfo/register.vue";
import resetPass from "../components/userInfo/resetPass.vue";
import infoBot from "../components/healthRecords/infobot.vue";
import yyss from "../components/healthRecords/yyss.vue";
import smzt from "../components/healthRecords/smzt.vue";
import rtcf from "../components/healthRecords/rtcf.vue";
import zyzd from "../components/healthRecords/zyzd.vue";
import shzb from "../components/healthRecords/shzb.vue";
import smjc from "../components/healthRecords/smjc.vue";
import dnzt from "../components/healthRecords/dnzt.vue";
import xlzd from "../components/healthRecords/xlzd.vue";
import zsfa from "../components/healthRecords/zsfa.vue";
import tjbg from "../components/healthRecords/tjbg.vue";
import fzzdbg from "../components/healthRecords/fzzdbg.vue";
import znyxbg from "../components/healthRecords/znyxbg.vue";
import fwjl from "../components/healthRecords/fwjl.vue";
import yysfa from "../components/healthRecords/yysfa.vue";
import hz from "../components/healthRecords/hz.vue";
import nutritionApplyPlan from "../components/nutritionApplyPlan.vue";
import dietInterpret from "../components/dietInterpret.vue";
import recipe from "../components/recipe/index.vue";
import workbench from "../components/workbench/index.vue";
import MessageM from "../components/MessageM.vue";
import team from "../components/team/index.vue";
import orgTeam from "../components/orgTeam/index.vue";
import teamMessage from "../components/team/message.vue";
import teamContact from "../components/team/contact.vue";
import teamDiet from "../components/team/diet.vue";
import teamPrecise from "../components/team/precise.vue";
import teamWarning from "../components/team/warning.vue";
import teamNurtrientApplyPlan from "../components/team/nurtrientApplyPlan.vue";
// 配置路由~
const routes = [
  {
    path: "/",
    name: "首页",
    component: index,
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/resetPass",
    name: "重置密码",
    component: resetPass,
  },
  {
    path: "/register",
    name: "注册",
    component: register,
  },
  {
    path: "/index",
    name: "首页",
    component: index,
    children: [

      {
        path: "/orderManagement",
        name: "订单管理",
        component: orderManagement,
      },
      {
        path: "/nutritionApplyPlan",
        name: "营养素方案申请",
        component: nutritionApplyPlan,
      },
      {
        path: "/dietInterpret",
        name: "饮食解读",
        component: dietInterpret,
      },
      {
        path: "/recipe",
        name: "精准餐单任务",
        component: recipe
      },
      {
        path: "/workbench",
        name: "工作台",
        component: workbench
      },
      {
        path: "/role",
        name: "角色管理",
        component: role,
      },
      {
        path: "/offlineAgencies",
        name: "线下机构管理",
        component: OfflineAgencies,
      },
      {
        path: "/platformManagement",
        name: "平台管理",
        component: platformM,
      },
      {
        path: "/orgTeam",
        name: "机构团队管理",
        component: orgTeam,
        children: [
         
        ],
      },
      {
        path: "/team",
        name: "团队成员管理",
        component: team,
        children: [
          {
            path: "/team/infobot",
            name: "基本信息",
            component: infoBot,
          },
          {
            path: "/team/shzb",
            name: "生化指标",
            component: shzb,
          },
          {
            path: "/team/yyss",
            name: "营养膳食",
            component: yyss,
          },
          {
            path: "/team/smzt",
            name: "睡眠状态",
            component: smzt,
          },
          {
            path: "/team/rtcf",
            name: "人体成分",
            component: rtcf,
          },
          {
            path: "/team/zyzd",
            name: "中医诊断",
            component: zyzd,
          },
          {
            path: "/team/smjc",
            name: "睡眠监测",
            component: smjc,
          },
          {
            path: "/team/dnzt",
            name: "大脑状态",
            component: dnzt,
          },
          {
            path: "/team/xlzd",
            name: "心理",
            component: xlzd,
          },
          {
            path: "/team/zsfa",
            name: "专属方案",
            component: zsfa,
          },
          {
            path: "/team/tjbg",
            name: "体检报告",
            component: tjbg,
          },
          {
            path: "/team/fzzdbg",
            name: "辅助诊断报告",
            component: fzzdbg,
          },
          {
            path: "/team/znyxbg",
            name: "功能医学报告",
            component: znyxbg,
          },
          {
            path: "/team/fwjl",
            name: "服务记录",
            component: fwjl,
          },
          {
            path: "/team/yysfa",
            name: "营养素方案",
            component: yysfa,
          },
          {
            path: "/team/hz",
            name: "会诊",
            component: hz,
          },
        ],
      },
      {
        path: "/teamContact",
        name: "联系管理",
        component: teamContact,
      },
      {
        path: "/teamMessage",
        name: "消息管理",
        component: teamMessage,
      },
      {
        path: "/teamDiet",
        name: "饮食解读",
        component: teamDiet,
      },
      {
        path: "/teamPrecise",
        name: "精准餐单",
        component: teamPrecise,
      },
      {
        path: "/teamWarning",
        name: "预警管理",
        component: teamWarning,
      },
      {
        path: "/teamNurtrientApplyPlan",
        name: "营养素方案申请",
        component: teamNurtrientApplyPlan,
      },
      {
        path: "/healthRecords",
        name: "健康档案",
        component: healthRecords,
        children: [
          {
            path: "/healthRecords/infobot",
            name: "基本信息",
            component: infoBot,
          },
          {
            path: "/healthRecords/shzb",
            name: "生化指标",
            component: shzb,
          },
          {
            path: "/healthRecords/yyss",
            name: "营养膳食",
            component: yyss,
          },
          {
            path: "/healthRecords/smzt",
            name: "睡眠状态",
            component: smzt,
          },
          {
            path: "/healthRecords/rtcf",
            name: "人体成分",
            component: rtcf,
          },
          {
            path: "/healthRecords/zyzd",
            name: "中医诊断",
            component: zyzd,
          },
          {
            path: "/healthRecords/smjc",
            name: "睡眠监测",
            component: smjc,
          },
          {
            path: "/healthRecords/dnzt",
            name: "大脑状态",
            component: dnzt,
          },
          {
            path: "/healthRecords/xlzd",
            name: "心理",
            component: xlzd,
          },
          {
            path: "/healthRecords/zsfa",
            name: "专属方案",
            component: zsfa,
          },
          {
            path: "/healthRecords/tjbg",
            name: "体检报告",
            component: tjbg,
          },
          {
            path: "/healthRecords/fzzdbg",
            name: "辅助诊断报告",
            component: fzzdbg,
          },
          {
            path: "/healthRecords/znyxbg",
            name: "功能医学报告",
            component: znyxbg,
          },
          {
            path: "/healthRecords/fwjl",
            name: "服务记录",
            component: fwjl,
          },
          {
            path: "/healthRecords/yysfa",
            name: "营养素方案",
            component: yysfa,
          },
          {
            path: "/healthRecords/hz",
            name: "会诊",
            component: hz,
          },
        ],
      },

      {
        path: "/offlineServiceManagement",
        name: "线下服务管理",
        component: offlineServiceM,
      },
      {
        path: "/communityManagement",
        name: "社区管理",
        component: communityM,
      },
      {
        path: "/nutritionManagement",
        name: "营养素管理",
        component: nutritionM,
      },
      {
        path: "/earlyWarningManagement",
        name: "预警管理",
        component: EarlyContainer,
      },
      {
        path: "/earlyWarningMonitor",
        name: "预警监控",
        component: earlyWarningMonitor,
      },
      {
        path: "/message",
        name: "消息管理",
        component: MessageM,
      },
    ],
  },
];

// 创建Router实例
const router = new VueRouter({
  routes,
});
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
