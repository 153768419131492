<template>

    <Layout>
      <Card>
        <p slot="title">机构团队管理</p>
        <!-- <div class="search-box">
          <Input
            v-model="input"
            placeholder="按手机号查询"
            search
            enter-button="搜索"
            :style="{ width: '250px' }"
            @on-search="loadData"
          />

        </div> -->
        <div class="content">
        <el-table :data="tableData">
          <el-table-column prop="id" align="center" minWidth="120" label='ID'></el-table-column>
          <el-table-column prop="teamName" align="center" minWidth="120"  label="团队名称"></el-table-column>
          

        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="pagenum"
          @current-change="handleCurrentChange"
          :total="total"
        ></el-pagination>
      </div>
      </Card>
      
      
    </Layout>
</template>
<script>


export default {

  components: {
   

  },
  data() {
    return {
      total: 10,
      pagenum: 1,
      pagesize: 10,
      tableData: [],
    };
  },
  mounted(){

  },
  created() {

    var that=this;
    that.init();
    that.loadData();

  },
  methods: {
    init(){

    },

    handleClose: function () {
      this.$emit('close');
    },
    messageDialogClose(){
      this.message.visible=false;
      this.message.doctorId=null;
    },
    showBasicInfo(row){
      this.basicInfo.visible=true;
      this.basicInfo.userId=row.id;
    },
    basicInfoClose(){
      this.basicInfo.userId=null;
      this.basicInfo.visible=false;
    },
    changeBindShow(row){
      this.changeDoctor=row;
      this.changeBind.visible=true;
    },
    clientListShow(row){
      this.clientList.doctorId=row.id;
      this.clientList.visible=true;
    },
    clientListDialogClose(){
      this.clientList.doctorId=null;
      this.clientList.visible=false;
    },
    changeBindDialogClose(){
      this.changeBind.visible=false;
    },
    doDismiss(row){
      this.dismiss.userId=row.id;
      this.dismiss.visible=true;
    },
    dismissDialogConfirm(){
      this.dismiss.visible=false;
      this.dismissConfirm.visible=true;
    },
    dismissDialogClose(){
      this.dismiss.visible=false;
    },
    dismissDialogConfirmClose(){
      this.dismissConfirm.visible=false;
    },
    dismissDialogConfirmConfirm(){
      this.loadData();
      this.dismissConfirm.visible=false;
    },
    loadData() {
      //列表
      var that = this;
      var parameter = {
        page: that.pagenum,
        pageSize: that.pagesize
      };
      that.api.teamQueryList(parameter).then((response) => {
        response = response.data;
        if (response.code == 0) {
          var page=response.page?response.page:response.data;
          that.total = page.totalCount;
          that.tableData = page.list;
        }
      });
    },
    handleCurrentChange(cpage) {
      //分页
      this.pagenum = cpage;
      this.loadData();
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.content img {
  width: 40px;
}
.search-box {
  display: flex;

}
/deep/.ivu-btn
{
  padding:5px;
}
/deep/.el-table td.el-table__cell div
{
  display: flex;
    align-items: center;
    justify-content:center;
}

/deep/.el-dialog__body
{
background:#f5f7f9;

}
/deep/.ivu-input-search
{
background: #185AD6 !important;
border-radius: 2px;
}

</style>
