<template>
  <div>
    <!-- v-if="roleType == '系统管理员'" -->
    <Button type="primary" v-if="roleType == '系统管理员'" class="mb20" @click="revamp = true"
      >+新增功能医学报告</Button
    >
    <el-collapse v-model="activeNames">
      <template v-for="(item, index) in basics">
        <el-collapse-item
          v-if="item.formal == 1"
          class="elementary"
          :title="'评测时间：' + item.createTime"
          :name="index"
          :key="index"
        >
          <div class="demo-image__preview">
            <el-image
              style="width: 100px; height: 100px"
              :src="item.url"
              :preview-src-list="item.url.split(',')"
            >
            </el-image>
          </div>
        </el-collapse-item>
        <p
          class="elementary eleHead"
          v-if="item.formal == 2"
          @click="open(item.url)"
          :key="index"
        >
          <span class="hdate">评测时间：{{ item.createTime }}</span>
          <span class="bgmc">体检机构：{{ item.orgName }}</span>
          <span class="bgmc cP">{{ item.fileName }}</span>
        </p>
      </template>
    </el-collapse>

    <el-dialog append-to-body title="新增功能医学报告" :visible.sync="revamp" width="480px">
      <el-form style="padding:30px 20px;" :model="form" class="formCss">
        <el-form-item label="名称：" :label-width="formLabelWidth">
          <el-input v-model="form.fileName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="体检机构：" :label-width="formLabelWidth">
          <el-input v-model="form.orgName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-upload
            class="upload-demo"
            action="#"
            auto-upload:false
            :on-remove="handleRemove"
            :on-change="handleChange"
            :http-request="uploadSectionFile"
            :class="{ hide: uploadDisabled }"
          >
            <span style="padding-left: 45px">pdf：</span>
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <Button @click="revamp = false">取 消</Button>
        <Button type="primary" @click="addznyxbg(form)">确 定</Button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from "@/request/api";
export default {
  data() {
    return {
      activeNames: "0",
      basics: "",
      roleType: "",
      revamp: false,
      form: {
        fileName: "",
        orgName: "",
        url: "",
      },
      formLabelWidth: "100px",
      uploadDisabled: false,
      userId:"",
    };
  },
  created() {
    this.userId = this.$route.query.userId;
    this.roleType = this.user.roleType;
    console.log(' this.user', this.user);
    this.compocsition();
  },
  methods: {
    open(itme) {
      // window.location.href = itme;
      window.open(itme);
    },
    handleRemove() {
      setTimeout(() => {
        this.uploadDisabled = false;
      }, 1000);
    },
    handleChange() {
      //上传成功与否都会触发
      this.uploadDisabled = true;
    },
    uploadSectionFile(file) {
      var that = this;
      const parameter = new FormData();
      parameter.append("file", file.file);
      api.uploadFile(parameter).then((response) => {
        if (response.data.code == 0) {
          that.form.url = response.data.data.url;
        }
      });
    },
    //新增营养素
    addznyxbg(item) {
      var that = this;
      var parameter = item;
      parameter.type = 3;
      parameter.formal = 2;
      parameter.userId = that.userId;
      api.jkdauploadmedicine(parameter).then((response) => {
        if (response.data.code == 0) {
          that.compocsition();
          that.revamp = false;
        }
      });
    },
    compocsition() {
      var that = this;
      var parameter = {
        userId: that.userId,
        type: 3,
      };
      api.jkdamedical(parameter).then((response) => {
        if (response.data.code == 0) {
          that.basics = response.data.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.elementary {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 0 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.eleHead {
  height: 56px;
  line-height: 56px;
  font-weight: bold;
  .hdate {
    color: #185ad6;
  }
  .hunfold {
    float: right;
  }
  .jgmc,
  .bgmc {
    margin-left: 40px;
    color: rgba(0, 0, 0, 0.85);
  }
}
img {
  width: 170px;
  height: 240px;
  margin-right: 20px;
}
</style>
