<template>
  <Layout>
    <Card>
      <p v-if="!hideTitle" slot="title">精准餐单</p>
      <div class="search-area">
        <Input class="search-input" v-model="mobile" placeholder="按手机号查询" />
        <Button type="primary" @click="handleSearch">查询</Button>
        <div class="search-tag-wrapper">
          <div class="search-tag-label">今日提交</div>
          <div class="search-tag-value">{{statisticsData.statusSubmit}}</div>
        </div>

        <div class="search-tag-wrapper">
          <div class="search-tag-label">本月提交</div>
          <div class="search-tag-value">{{statisticsData.localMonthCount}}</div>
        </div>
        <div class="search-tag-wrapper">
          <div class="search-tag-label">共计提交</div>
          <div class="search-tag-value">{{statisticsData.count}}</div>
        </div>
      </div>
      <div class="content">
      <Table class="lu-table" :columns="columns" :data="list">
        <template slot-scope="{ row }" slot="memberType">
          {{ user.memberTypeMap[row.memberType] }}
        </template>
        <template slot-scope="{ row }" slot="avatar">
          <img :src="row.avatar" class="avatar" />
        </template>
        <template slot-scope="{ row }" slot="submitTime">

          <div class="submitTime">
            最近提交:{{ row.submitTime }}
          </div>
        </template>
        <template slot-scope="{ row }" slot="action">
          <div v-if="!readOnly" class="action-wrapper">
            <div v-if="row.status==1" class="edit-button" @click="handleEditDialog(row)">
              {{ statusMsgMap[row.status] }}
            </div>
            <div v-else class="unedit">
              {{ statusMsgMap[row.status] }}
            </div>
            <div class="view-button" @click="handleHistoryDialog(row)">
              查看
            </div>
          </div>
          <div v-else class="action-wrapper">
            <div class="view-button" @click="handleHistoryDialog(row)">
              查看
            </div>
          </div>
        </template>
      </Table>
      <div class="lu-footer">
        <Page transfer :current="page" :total="total" @on-change="changePage" />
      </div>
    </div>
    </Card>
    

    <edit  :dialogVisible="editDialogVisible" :userId="editId" @ok="editOk" @close="editClose"/>
    <history :appendToBody="readOnly" :dialogVisible="historyDialogVisible" :userId="editId" @close="historyClose"/>

  </Layout>
</template>
<script>
import Axios from "axios";
import dayjs from "dayjs";
import history from "./history.vue";
import edit from "./edit.vue";
const PAGE_SIZE = 10;

const statusMsgMap = {
  1: "编辑",
  3: "已提交",
};

// 表头
export const columns = [
  {
    title: "编号",
    key: "id",
    align:"center",
    minWidth:80
  },
  {
    title: "姓名",
    key: "name",
    align:"center",
    minWidth:120
  },
  {
    title: "手机号",
    key: "mobile",
    align:"center",
    minWidth:140
  },
  {
    title: "性别",
    key: "gender",
    align:"center",
    minWidth:100
  },
  {
    title: "出生日期",
    key: "birthday",
    align:"center",
    minWidth:120
  },
  {
    title: "会员等级",
    slot: "memberType",
    align:"center",
    minWidth:100
  },
  {
    title: "微信昵称",
    key: "nickname",
    align:"center",
    minWidth:130
  },
  {
    title: "微信头像",
    slot: "avatar",
    align:"center",
    minWidth:100
  },
  {
    title: "操作",
    slot: "action",
    minWidth: 100,
    align: "center",
  },
  {
    title: "最近提交",
    minWidth: 200,
    align:"center",
    slot: "submitTime",
  },

];
export default {
  name: "diet-page",
  props: ["doctorId","hideTitle","readOnly"],
  components: {
    history,
    edit
  },
  data() {
    return {
      mobile: "", // 查询姓名或电话
      page: 1,
      pageSize: PAGE_SIZE,
      total: 1,
      list: [], // 列表
      columns,
      statusMsgMap,
      editDialogVisible:false,
      historyDialogVisible:false,
      editId:'',
      statisticsData:{
        statusSubmit:'--',
        statusClose:'--',
        localMonthCount:'--',
        count:'--'
      }
    };
  },
  computed: {
    formatTime: function () {
      return dayjs(this.time).format("YYYY-MM-DD hh:mm:ss");
    },
  },
  mounted() {

  },
  created(){
    var that=this;
    this.fetchStatisticsData();
        // 初始化列表
        this.fetchData({ page: 1 });
    that.$watch('doctorId', function() {
      if(that.doctorId)
      {
        this.fetchStatisticsData();
        // 初始化列表
        this.fetchData({ page: 1 });
      }
      else{
        that.list=[];
        that.total=0;
      }
    });
  },
  methods: {
    // 查询
    handleSearch() {
      this.fetchData({ page: 1 });
    },
    fetchStatisticsData(){
       var that=this;
       this.api.preciseStatistic({doctorId:that.doctorId}).then((response) => {
           var data=response.data;
           if(data.code==0)
           {
              that.statisticsData=data.data;
           }
           else{
              that.$Message.error(data.msg);
           }
       });
    },
    fetchData({ page }) {
      const vm = this;
      const { mobile } = vm;
      Axios.post("/api/precise/queryList", {
        ...(mobile && { mobile }),
        page,
        pageSize: PAGE_SIZE,
        doctorId:vm.doctorId
      })
        .then((response) => {

          const {
            data: {
              data: { list, totalCount: total },
            },
          } = response;

          vm.total = total;
          vm.list = list.map(
            ({
              id,
              userId,
              name,
              mobile,
              gender,
              avatar,
              birth,
              nickName,
              status,
              memberType,
              submitTime,
            }) => ({
              id,
              userId,
              name,
              mobile,
              gender,
              birthday: birth,
              nickname: nickName,
              avatar,
              status,
              memberType,
              submitTime,
            })
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 换页
    changePage(page) {
      this.page = page;
      this.fetchData({ page: page });
    },
    //点击查看
    handleHistoryDialog(row){
      this.editId=row.userId;
      this.historyDialogVisible=true;

    },
    // 点击处理
    handleEditDialog(row) {
      this.editId=row.userId;
      this.editDialogVisible=true;

    },
    editOk(){
       this.fetchData({page:this.page});
       this.editDialogVisible=false;
    },
    editClose(){
      this.editId=null;
      this.editDialogVisible=false;
    },
    historyClose(){
      this.editId=null;
      this.historyDialogVisible=false;
    },
    submit() {
      const vm = this;
      const { suggestion, suggestId, page } = vm;
      Axios.post("/api/dietary/update1", {
        id: suggestId,
        message: suggestion,
      })
        .then(function () {
          vm.editDialogClose();
          vm.fetchData({ page });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    editDialogClose() {
      this.editDialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.topImg {
  width: 130px;
  height: auto;
  object-fit: contain;
  display: block;
  margin-right: 10px;
  margin-top:10px;
}
.search-tag-wrapper
{
  width: 134px;
  height: 31px;
  background: #D5E4FF;
  border-radius: 3px;
  opacity: 0.58;
  display:flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  .search-tag-label
  {
    font-size: 11px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  .search-tag-value
  {
    font-size: 18px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 500;
    color: #264AFF;
  }
}
.action-wrapper
{
  display:flex;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
  .edit-button{
    width: 56px;
    background: #F2FBFF;
    border-radius: 2px;
    border: 1px solid #96D0EE;
  }
  .view-button{
    font-size: 10px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #264AFF;
  }
  .unedit{
  font-size: 10px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  }
}

/deep/.ivu-table-cell-slot
{
  position: relative;
}
/deep/.ivu-table-cell
{
  overflow: visible;
}
/deep/.ivu-form-item-content
{
    display: flex;
    justify-content: flex-start;
        flex-wrap: wrap;
}
/deep/.ivu-table
{
display: flex;
    flex-direction: column;
    align-items: center;
    overflow:scroll;
}
/deep/.ivu-table-cell{
padding-left:4px;
padding-right:4px;
}
.content
{
  padding-left:0;
  padding-right:0;
}
.send-btn {
  margin: 10px 10px 0 0;
}
</style>
