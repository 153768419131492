<template>
  <el-table :data="data" style="width: 100%">
    <el-table-column prop="name" align="center" minWidth="150" label="姓名"></el-table-column>
    <el-table-column prop="mobile" align="center" minWidth="150" label="手机号" ></el-table-column>
    <el-table-column prop="gender" align="center" minWidth="120" label="性别" ></el-table-column>
    <el-table-column prop="age" align="center" minWidth="120" label="年龄" ></el-table-column>
    <el-table-column
      prop="address"
      align="center"
      label="居住地"
      minWidth="234"
    ></el-table-column>
    <el-table-column
      prop="nickName"
      label="微信昵称"
      align="center"
      minWidth="150"
    ></el-table-column>
    <el-table-column prop="avatar" align="center" minWidth="120" label="微信头像">
      <template slot-scope="scope">
        <img :src="scope.row.avatar" class="avatar" />
      </template>
    </el-table-column>
    <el-table-column align="center" minWidth="120" label="申请信息">
      <template slot-scope="scope">
        <div v-if="!readOnly">
          <div
            class="msg"
            @click="hanldeClick(scope.row,1)"
            v-if="scope.row.status === 1"
          >
            处理
          </div>
          <div class="msg" @click="hanldeClick(scope.row,2)" v-else>查看</div>
        </div>
        <div v-else class="msg" @click="hanldeClick(scope.row,2)">查看</div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
/**
 * 饮食解读表格和营养素方案申请表格
 * @description 表格
 * @property {Array} data 表格数据
 */
export default {
  name: "early-table",
  props: {
    data: {
      type: Array,
    },
    readOnly:{
      type: Boolean,
    }
  },
  data() {
    return {};
  },
  methods: {
    hanldeClick(row,item) {
      row.item = item;
      this.$emit("open", row);
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.avatar {
  width: 40px;
  height: 40px;
  background: #d8d8d8;
  border-radius: 4px;
}

.el-table td.el-table__cell div.msg {
  position: relative;
  cursor: pointer;
  color: #185ad6;
}

.tag {
  display: block;
  width: 6px;
  height: 6px;
  background: #d60000;
  border-radius: 50%;
  position: absolute;
  right: 54px;
  top: 0;
}
</style>
