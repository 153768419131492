<template>
  <div>
    <el-dialog
    fullscreen
    title='用户详情'
    :visible.sync="visible"
    :before-close="handleClose"
    :append-to-body="appendToBody?true:false"
    >
      <div class="container">
        <el-header class="basicHeader">

          <div class="headerCen">
            <div class="profile">
              <img :src="particulars.avatar" />
              <p>{{ particulars.nickName }}</p>
            </div>
            <div class="elemenbasic">
              <h4>姓名：{{ particulars.name }}</h4>
              <ul>
                <li>性别：{{ particulars.gender }}</li>
                <li>年龄：{{ particulars.age }}</li>
                <li>电话：{{ particulars.mobile }}</li>
              </ul>
            </div>
            <div class="recentData">
              <h4>
              最近数据：<span>{{ detail.humanClassifyName }}</span>
              </h4>
              <div class="recentCon">
                <ul>
                  <li>身高： {{ detail.weight.height }}CM</li>
                  <li>体重： {{ detail.weight.weight }}kg</li>
                  <li>BMI指数：{{ detail.weight.bmi }}</li>
                </ul>
                <ul>
                  <li>腰臀比：{{ detail.waistHipRatio.waisthipRatio }}</li>
                  <li>
                    血压：
                    {{
                      detail.bloodPressure.high +
                      "mmHg " +
                      detail.bloodPressure.low +
                      "mmHg"
                    }}
                  </li>
                  <li>血糖浓度： {{ detail.bloodSugar.bloodSugar }}mmol/L</li>
                </ul>
              </div>
            </div>
            <div class="btns">
              <el-button type="primary" v-if="user.isGLY()||user.isJGS()" @click="outerVisible = true"
                >+新增体征</el-button
              >
              <el-button type="primary" @click="outerVisible2 = true"
                >查看关键体征数据</el-button
              >
              <div class="contact-button-wrapper">
               <div v-if="news.contactZXS" class="contact-button" @click="contact(user.doctorTypeE.ZXS)"
                ><i style="margin-right: 2px;" class="el-icon-chat-dot-square"/>联系{{user.doctorTypeE.ZXS_S}}</div>
               <div v-if="news.contactJGS" class="contact-button" @click="contact(user.doctorTypeE.JGS)"
                ><i style="margin-right: 2px;" class="el-icon-chat-dot-square"/>联系{{user.doctorTypeE.JGS_S}}</div>
                <div v-if="news.contactGLY" class="contact-button" @click="contact(user.doctorTypeE.GLY)"
                ><i style="margin-right: 2px;" class="el-icon-chat-dot-square"/>联系{{user.doctorTypeE.GLY_S}}</div>
              </div>
            </div>
          </div>
          <div class="headerBot">
            <el-tabs v-model="activeName" @tab-click="handClick">
              <el-tab-pane
                :label="item.label"
                :name="item.name"
                v-for="(item, index) in paneData"
                :key="index"
              ></el-tab-pane>
            </el-tabs>
          </div>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </div>
    </el-dialog>
    <newDialog
      :visible="outerVisible"
      :userId="userId"
      @reduce="childReduce"
    ></newDialog>
    <examineDialog
      :visible="outerVisible2"
      :userId="userId" 
      @xgreduce="childReduce2"
    ></examineDialog>
    <newsDialog @close="newsClose" :visible="news.newsDialogVisible" :receiverId="news.receiverId" :relatedUserId="news.relatedUserId"/>
  </div>
  <!-- <ExamineDialog/> -->
</template>
<script>
import { api } from "@/request/api";
import newDialog from "./info-dialog/new-dialog.vue";
import examineDialog from "./info-dialog/examine-dialog.vue";
import newsDialog from "../common/dialog/newsDialog.vue";
export default {
  props: ["visible","userId","appendToBody","pathPre"],
  components: {
    newDialog,
    examineDialog,
    newsDialog
  },
  data() {
    return {
      particulars: {},
      detail: {
        weight: {},
        waistHipRatio: {},
        bloodPressure: {},
        bloodSugar: {},
        generateTime:'',
      },
      outerVisible: false,
      outerVisible2: false,
      news:{
        newsDialogVisible:false,
        receiverId:"",
        relatedUserId:"",
        contactZXS:false,
        contactJGS:false,
        contactGLY:false,
      },

      activeName: "",
      paneData: [],
      paneData1: [
        {
          label: "基本信息",
          name: "/infobot",
        },
        {
          label: "生化指标",
          name: "/shzb",
        },
        {
          label: "营养膳食",
          name: "/yyss",
        },
        {
          label: "人体成分",
          name: "/rtcf",
        },
        {
          label: "中医",
          name: "/zyzd",
        },
        {
          label: "睡眠监测",
          name: "/smjc",
        },
        {
          label: "心理",
          name: "/xlzd",
        },
        {
          label: "大脑",
          name: "/dnzt",
        },
        {
          label: "体检报告",
          name: "/tjbg",
        },
        {
          label: "辅助诊断报告",
          name: "/fzzdbg",
        },
        {
          label: "功能医学报告",
          name: "/znyxbg",
        },
        {
          label: "专属方案",
          name: "/zsfa",
        },
        {
          label: "营养素方案",
          name: "/yysfa",
        },
      ],
      paneData2: [
        {
          label: "基本信息",
          name: "/infobot",
        },
        {
          label: "生化指标",
          name: "/shzb",
        },
        {
          label: "营养膳食",
          name: "/yyss",
        },
        {
          label: "人体成分",
          name: "/rtcf",
        },
        {
          label: "中医",
          name: "/zyzd",
        },
        {
          label: "睡眠监测",
          name: "/smjc",
        },
        {
          label: "心理",
          name: "/xlzd",
        },
        {
          label: "大脑",
          name: "/dnzt",
        },
        {
          label: "体检报告",
          name: "/tjbg",
        },
        {
          label: "辅助诊断报告",
          name: "/fzzdbg",
        },
        {
          label: "功能医学报告",
          name: "/znyxbg",
        },
        {
          label: "专属方案",
          name: "/zsfa",
        },
        {
          label: "营养素方案",
          name: "/yysfa",
        },
      ],
      paneData3: [
        {
          label: "基本信息",
          name: "/infobot",
        },
        {
          label: "生化指标",
          name: "/shzb",
        },
        {
          label: "营养膳食",
          name: "/yyss",
        }
      ],
    };
  },
  created() {
    var that=this;

    that.$watch('userId', function() {
      if (that.user.isGLY()) {
        that.paneData = that.paneData1;
      } else if (that.user.isJGS()||that.user.isTDGLY()) {
        that.paneData = that.paneData2;
      } else if (that.user.isZXS()||that.user.isZXSGLY()) {
        that.paneData = that.paneData3;
      }
      that.jkdaparticulars();
      if(!that.activeName)that.activeName=that.paneData[0].name;
      that.$router.push({path:that.pathPre+that.activeName,query:{
      userId:that.userId
      }});
    });

  },
  methods: {
    newsClose(){
      this.news.newsDialogVisible=false;
      this.news.receiverId='';
      this.news.relatedUserId='';
    },
    handleClose: function () {
      this.activeName='';
      this.$emit('close');
    },
    contact(roleType){
       var that=this;

       if(roleType==that.user.doctorTypeE.GLY)
       {
         this.news.receiverId=this.particulars.sysManagerId;
       }
       else if(roleType==that.user.doctorTypeE.ZXS)
       {
         this.news.receiverId=this.particulars.answerDoctorId;
       }
       else if(roleType==that.user.doctorTypeE.JGS)
       {
         this.news.receiverId=this.particulars.manageDoctorId;
       }
       this.news.relatedUserId=this.particulars.id;
       this.news.newsDialogVisible=true;
    },

    handClick(tab) {
      //标签切换

      this.$router.push({path:this.pathPre+tab.name,query:{
      userId:this.userId
      }});
    },
    childReduce(count) {
      //传值
      this.outerVisible = count;
      this.jkdaparticulars();
    },
    childReduce2(count) {
      //传值
      this.outerVisible2 = count;
    },
    jkdaparticulars() {

      var that = this;
      var parameter = {
        userId: that.userId,
      };


        api.jkdaparticulars(parameter).then((response) => {
          response = response.data;
          if (response.code == 0) {
            that.particulars = response.data;

            if(that.user.isZXS())
            {
              if(that.particulars.manageDoctorId)
              {
                that.news.contactJGS=true;
              }
            }
            else if(that.user.isJGS())
            {
              if(that.particulars.answerDoctorId)
              {
                that.news.contactZXS=true;
              }
              that.news.contactGLY=true;
            }
            else if(that.user.isGLY())
            {
              if(that.particulars.manageDoctorId)
              {
                that.news.contactJGS=true;
              }
              if(that.particulars.answerDoctorId)
              {
                that.news.contactZXS=true;
              }
            }
          }
      });
      api.jkdadetail(parameter).then((response) => {
        response = response.data;
        if (response.code == 0) {
          if(response.data.generateTime)
          {
            var date = new Date(response.data.generateTime.iphoneDateFormat());
            response.data.generateTime=date.format("yyyy年MM月dd日");
          }

          that.detail = response.data;

        }
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.el-main {
  margin: 20px 20px;
  padding: 0;
  box-shadow: none;
  border-radius: 4px;
  background: #f3f5f8;
}
.container{
 padding:20px 0px;
 background:#f5f5f6;
}
@185AD6: #185ad6;
.basicHeader {
  font-size: 16px;
  background-color: #fff;
  position: relative;
  .headerCen {
    display: flex;
    padding-bottom: 30px;
    padding-top: 30px;
    align-items: center;
    .profile {
      flex-basis: 10%;
      height: 130px;
      text-align: center;
      border-right: 1px solid #e3e3e3;
      img {
        width: 40px;
        height: 40px;
        margin: 30px 0 20px;
      }

    }
    .elemenbasic {
      flex-basis: 20%;
      padding-left: 28px;
      border-right: 1px solid #e3e3e3;
      li {
        padding-top: 10px;
      }
    }
    .recentData {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      padding-left: 30px;
      border-right: 1px solid #e3e3e3;
      h4 span {

        font-weight: normal;
      }
      .recentCon {
        display: flex;
        ul{
          flex:1;
        }
        li {

          padding-top: 10px;
        }
      }
    }
    .btns {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      .el-button {
        width: 160px;
        text-align: center;
        margin-bottom:10px;
        font-size: 16px;
      }
    }
  }
}
.contact-button-wrapper{
  display: flex;
    justify-content: space-around;

    width: 100%;
}
.contact-button{
  font-size: 11px;
font-family: SourceHanSansCN-Normal, SourceHanSansCN;
font-weight: 400;
color: rgba(0, 0, 0, 0.85);
display: flex;
    align-items: center;
}
.contact-button:hover{
  color:#185AD6;
  cursor:pointer;
}
/deep/ .el-tabs__nav-wrap::after {
  background-color: #fff;
}
/deep/ .el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__active-bar {
  background-color: @185AD6;
}
/deep/ .el-tabs__item.is-active {
  color: @185AD6;
}
/deep/.el-button+.el-button
{
  margin:0;
}
</style>
