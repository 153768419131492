<template>
  <el-dialog
    class="xztz"
    title="新增体征记录"
    :visible.sync="visible"
    width="480px"
    :before-close="reduce"
  >
    <el-form style="padding:30px 20px;" :model="form">
      <el-form-item label="身高（cm）：" :label-width="formLabelWidth">
        <el-input v-model="form.height" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="体重（kg）：" :label-width="formLabelWidth">
        <el-input v-model="form.weight" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="腰围（cm）：" :label-width="formLabelWidth">
        <el-input v-model="form.waist" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="臀围（cm）：" :label-width="formLabelWidth">
        <el-input v-model="form.hipline" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="收缩压（mmHg）：" :label-width="formLabelWidth">
        <el-input v-model="form.high" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="舒张压（mmHg）：" :label-width="formLabelWidth">
        <el-input v-model="form.low" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="血糖浓度（mmol/L）：" :label-width="formLabelWidth">
        <el-input v-model="form.bloodSugar" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="血糖测量时间段：" :label-width="formLabelWidth">
        <el-select v-model="form.generateTime" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="reduce()">取 消</el-button>
      <el-button type="primary" @click="newlykhda()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "new-dialog",
  props: ['visible','userId'],
  data() {
    return {
      form: {
        high: "",
        low: "",
        height: "",
        weight: "",
        waist: "",
        hipline: "",
        generateTime: "",
        bloodSugar: "",
      },
      options: [
        {
          value: "空腹",
        },
        {
          value: "早餐后",
        },
        {
          value: "午餐前",
        },
        {
          value: "午餐后",
        },
        {
          value: "晚餐前",
        },
        {
          value: "晚餐后",
        },
        {
          value: "睡前",
        },
      ],
      formLabelWidth: "180px"
    };
  },
  created() {
     
  },
  methods: {
    reduce() {
      var outerVisible = false;
      this.$emit("reduce", outerVisible);
    },
    newlykhda() {
      var that = this;
      var parameter = that.form;
      parameter.userId = that.userId;
      that.api.jkdaaddsign(parameter).then((response) => {
        if (response.data.code == 0) {
          that.$emit("reduce", false);
        }
      });
    },
  },
};
</script>
<style lang="less">
.xztz {
  p {
    line-height: 42px;
    .span {
      display: inline-block;
      width: 230px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .el-select {
    display: block;
  }
}
</style>
