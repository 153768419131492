// IE 兼容方法
if (typeof Object.assign != 'function') {
  Object.assign = function(target) {
    'use strict';
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source != null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}
Object.ifUndefinedClone = function(destination, source) {
   if(!destination) destination={};
   for (var property in source) {
       if(destination[property]==undefined)
       {
         destination[property] = source[property];
       }
   }
   return destination;
}
Object.extend = function(destination, source) {
   for (var property in source) {
       destination[property] = source[property];
   }
   return destination;
}
Object.deepClone=function(target) {
    'use strict';
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }
    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source != null) {
        for (var key in source) {
            if (source[key]&&typeof source[key] == 'object') {
                target[key] = (source[key].constructor === Array) ? [] : {}
                Object.deepClone(target[key],source[key]);
            } else {
                target[key] = source[key];
            }
        }
      }
    }
    return target;
}
Object.leftDeepClone= function(target) {
  'use strict';
  if (target == null) {
    throw new TypeError('Cannot convert undefined or null to object');
  }
  target = Object(target);
  for (var index = 1; index < arguments.length; index++) {
    var source = arguments[index];
    if (source != null) {
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)&&Object.prototype.hasOwnProperty.call(target, key)) {
          if (source[key]&&typeof source[key] == 'object') {
              target[key] = (source[key].constructor === Array) ? [] : {}
              Object.deepClone(target[key],source[key]);
          } else {
              target[key] = source[key];
          }
        }
      }
    }
  }
  return target;
};
