<template>
  <el-dialog :title="editing ? '编辑机构' : '新增机构'" :visible="visible" width="965px" :beforeClose="handleClose">
    <Form :model="formValidate" :label-width="120">
      <div class="form-left">
        <FormItem label="机构类型:" prop="shopType">
          <Select v-model="formValidate.shopType">
            <Option v-for="item in shopTypeOptions" :key="item.value" :value="item.value">
              {{ item.label }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="机构名称:" prop="shopName">
          <Input v-model="formValidate.shopName"></Input>
        </FormItem>
        <FormItem label="位置:">
          <v-distpicker :province="form.province" :city="form.city" :area="form.region" @province="selectProvince" @city="selectCity" @area="selectArea">
          </v-distpicker>
        </FormItem>
        <FormItem label="具体位置:" placeholder="请输入">
          <Input v-model="formValidate.address"></Input>
        </FormItem>
        <FormItem label="评分:" prop="score">
          <Input v-model="formValidate.rate" placeholder="请输入"></Input>
        </FormItem>
        <FormItem label="起始服务数:" placeholder="请输入" prop="originServer">
          <Input v-model="formValidate.originServer"></Input>
        </FormItem>

        <FormItem label="标签1:">
          <Input v-model="tag1" placeholder="请输入不多于5个字"></Input>
        </FormItem>
        <FormItem label="标签2:">
          <Input v-model="tag2" placeholder="请输入不多于5个字"></Input>
        </FormItem>
        <FormItem label="标签3:">
          <Input v-model="tag3" placeholder="请输入不多于5个字"></Input>
        </FormItem>

        <FormItem label="擅长:">
          <Input v-model="formValidate.speciality"></Input>
        </FormItem>
        <FormItem label="营业时间:">
          <Input v-model="formValidate.openTime"></Input>
        </FormItem>

        <FormItem label="上架状态:">
          <RadioGroup v-model="formValidate.onLine">
            <Radio :label="statusObj['up']">
              <span>上架</span>
            </Radio>
            <Radio :label="statusObj['down']">
              <span>下架</span>
            </Radio>
          </RadioGroup>
        </FormItem>
      </div>

      <div class="form-right">
        <FormItem label="主图：（1张，800*800" prop="mainPic">
          <el-upload :multiple="false" :limit="1" :on-exceed="handleExceed" action="" :file-list="imgList" list-type="picture-card" :http-request="uploadSectionFile" :on-remove="handleImgRemove" key="img">
            <i class="el-icon-plus"></i>
          </el-upload>
        </FormItem>

        <FormItem label="顶图：（1张）" prop="topPic">
          <el-upload :multiple="false" :limit="1" :on-exceed="handleExceed" action="" :file-list="topList" list-type="picture-card" :http-request="uploadTopSectionFile" :on-remove="handleTopImgRemove" key="topImg">
            <i class="el-icon-plus"></i>
          </el-upload>
        </FormItem>

        <FormItem label="支持的线下服务:">
          <el-checkbox-group v-model="services">
            <el-checkbox v-for="item in serviceOptions" :key="item.value" :label="item.value">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </FormItem>
      </div>
    </Form>

    <div slot="footer">
      <Button class="early-btn" @click="handleClose">关闭</Button>
      <Button class="early-btn" type="primary" @click="handleSubmit('formValidate')">提交</Button>
    </div>
  </el-dialog>
</template>

<script>
/**
 * 新增机构弹窗
 * @description 处理弹窗
 * @property {Boolean} visible 是否可见
 * @property {Boolean} editing 是编辑还是新增
 * @property {Object} form 表单项
 * @property {Object} serviceOptions 线下服务数据
 * @property {Function} submit 提交
 * @property {Function} close 关闭
 */
import axios from "axios";
import VDistpicker from "v-distpicker";

const CENTER = "健管中心";
const MEDICINE = "中医馆";

// 机构类型选项
const shopTypeOptions = [
  {
    value: CENTER,
    label: "健康管理中心",
  },
  {
    value: MEDICINE,
    label: "中医馆",
  },
];

export default {
  name: "offline-agencies-modal",
  components: { VDistpicker },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    serviceOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    form: {
      type: Object,
      dafault: {
        shopType: CENTER, // 1 2
        shopName: "", // 机构名称
        province: "",
        city: "",
        region: "", // 省市区
        address: "", // 社区
        rate: "", // 评分 1-5
        originServer: "", // 起始服务数
        openTime: "", // 营业时间
        onLine: 1, // 上架状态 上架1 下架2
        img: "", // 主图
        topImg: "", // 顶图
        tags: "",
        offLineGoods: [], // 支持的服务
        offLineGoodsid: [],
        speciality: "", // 特长
      },
    },
    submit: {
      type: Function,
    },
    close: {
      type: Function,
    },
  },
  data() {
    return {
      shopTypeOptions, // 机构类型选项
      statusObj: {
        up: 1,
        down: 2,
      },
      tag1: "",
      tag2: "",
      tag3: "",
      services: [],
      imgList: [],
      topList: [],
      formValidate: {},
      imgPool: "",
      topPool: "",
    };
  },
  watch: {
    form: function (value) {
      const { tags, offLineGoodsid, img, topImg } = value;
      // 初始化标签
      const tagList = tags ? tags.split(",") : [];
      const tag1 = tagList[0];
      const tag2 = tagList.length >= 2 ? tagList[1] : "";
      const tag3 = tagList.length >= 3 ? tagList[2] : "";
      this.tag1 = tag1;
      this.tag2 = tag2;
      this.tag3 = tag3;

      // 初始化线下服务
      const services = offLineGoodsid
        ? offLineGoodsid.split(",").map(Number)
        : [];
      this.services = services;

      // 初始化头图、顶图
      this.imgList = img ? [{ name: "img", url: img }] : [];
      this.topList = topImg ? [{ name: "top", url: topImg }] : [];

      this.imgPool = img;
      this.topPool = topImg;

      this.formValidate = value;
    },
  },
  methods: {
    validate() {
      const { shopName, rate, originServer } = this.formValidate;
      if (!shopName) {
        this.$message.error("机构名不能为空");
        return false;
      } else if (!rate) {
        this.$message.error("评分不能为空");
        return false;
      } else if (!(Number(rate) >= 1 && Number(rate) <= 5)) {
        this.$message.error("评分 1-5， 支持一位小数");
        return false;
      } else if (!originServer) {
        this.$message.error("起始服务数不能为空");
        return false;
      }
      return true;
    },
    handleExceed() {
      this.$message.warning("当前限制选择 1 个文件");
    },
    async uploadSectionFile(file) {
      const parameter = new FormData();
      parameter.append("file", file.file);
      const res = await axios.post("/api/file/upload", parameter);
      const {
        data: {
          data: { url },
        },
      } = res;
      this.imgPool = url;
    },
    async uploadTopSectionFile(file) {
      const parameter = new FormData();
      parameter.append("file", file.file);
      const res = await axios.post("/api/file/upload", parameter);
      const {
        data: {
          data: { url },
        },
      } = res;
      this.topPool = url;
    },
    handleImgRemove() {
      this.imgPool = "";
    },
    handleTopImgRemove() {
      this.topPool = "";
    },
    selectProvince({ value }) {
      this.formValidate.province = value;
    },
    selectCity({ value }) {
      this.formValidate.city = value;
    },
    selectArea({ value }) {
      this.formValidate.region = value;
    },
    handleSubmit() {
      // 格式化标签
      const {
        tag1,
        tag2,
        tag3,
        formValidate,
        services,
        serviceOptions,
        imgPool,
        topPool,
      } = this;
      const tags = [tag1, tag2, tag3].join(",");
      formValidate.tags = tags;

      // 格式化线下服务
      const offLineGoods = serviceOptions
        .filter((item) => services.includes(item.id))
        .map((item) => item.name)
        .join(",");
      formValidate.offLineGoods = offLineGoods;
      formValidate.offLineGoodsid = services.join(",");

      // 图片
      formValidate.img = imgPool;
      formValidate.topImg = topPool;

      if (this.validate()) {
        this.$message.success("提交成功!");
        this.$emit("submit", formValidate);
      } else {
        // this.$message.error("请您填写正确格式的数据");
      }
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped="scoped">
.bussiness-form {
  display: flex;
}

.form-left {
  width: 450px;
  margin-right: 50px;
}
</style>
