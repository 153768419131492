/*
  登录逻辑
  登录进网站逻辑：未登录用户不论当前页面是什么都自动跳转到登录页。
  点击登录页「登录按钮」后逻辑： 管理员跳转到订单管理，健康管理师和健管师跳转到健康档案列表页
  登出逻辑： 用户关闭页面即清空登录数据，不保留用户登录。
*/

import axios from "axios";
import router from "../router";

export const TOKEN = "token";


export const getToken = () => {
  try {
    const token = sessionStorage.getItem(TOKEN);
    return token;
  } catch {
    return "";
  }
};

/**
 * 全局 axios 设置
 * header 添加 token
 */
export const refreshHeaderToken = (production) => {

  var token=getToken();
  if (production==undefined) {
    production=false;
  }
  //if(!production)token='a39f2668f7de6bf777a3eb3cf4b3211a';
  axios.defaults.headers.common["token"] = token;
};

export const setToken = (value) => {
  try {
    const token = sessionStorage.setItem(TOKEN, value);
    refreshHeaderToken();
    return token;
  } catch {
    return "";
  }
};

export const isLogin = () => {
  const token = getToken();
  return Boolean(token);
};

export const getUserRole = async () => {
  return axios
    .post("/api/account/userInfo")
    .then((res) => {
      
      return res.data.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

// 发起登录请求
export const postLogin = (data) => {
  return axios.post("/api/account/login", data).then((res) => {
    const {
      data: { code, msg, token },
    } = res;
    if (code === 0) {
      return token;
    } else {
      throw new Error(msg);
    }
  });
};

// 登录后跳转
export const handleLoginSuccess = async () => {
  try {
    router.push({ path: "/index" });
  } catch (e) {
    console.log(111, e);
  }
};

// 退出登录
export const logout = async () => {
  sessionStorage.removeItem(TOKEN);
  router.push({ path: "/login" });
};

export const handleFail = async (res) => {
  const {
    data: { code },
  } = res;
  if (code === 203) {
    router.push({ path: "/login" });
    return;
  }
};
